@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body {
  font-family: 'Poppins' !important;
}

.btn-save,
.btn-save-lock {
  background: #ff5722;
  color: #fff;
  margin: 30px 0;
}

.btn-back {
  background: blueviolet;
  color: #fff;
  margin: 30px 0;
}

.btn-save:hover,
.btn-save-lock:hover {
  color: #fff;
  box-shadow: 0 0 0 3px #d9d9d9;
}

.btn-save:after {
  content: "\e161";
  font-family: 'icons-sm';
  margin-left: 9px;
  position: relative;
  top: 0px;
  font-size: 15px;
}

.btn-save-lock:after {
  content: "\e897";
  font-family: 'icons-sm';
  margin-left: 9px;
  position: relative;
  top: 0px;
  font-size: 15px;
}

.btn-back:after {
  content: "\ecc5";
  font-family: 'icons-sm';
  margin-left: 9px;
  position: relative;
  top: 0px;
  font-size: 15px;
}

.btn-save.btn-submit {
  background: #8bc34a;
  padding: 12px 39px;
}

.btn-save {
  font-size: 16px;
}

.btn-save-lock {
  font-size: 14px;
}


.btn-save-credentials {
  background: #17a2b8;
  color: #fff;
  min-width: 120px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
}

.btn-save-credentials i {
  margin-left: 21px;
}


.error {
  color: #bf1650 !important;
}

.error {
  display: inline;
}

.dropdown-logout {
  padding-left: 16px;
  font-size: 13px;
}

.applicant-date div.ant-picker-input {
  margin-top: -8px;
}

.applicant-date {
  height: 44px;
}

td.action-icons .button-intent-edit {
  background: #323a51;
  border: 1px solid #323a51;
  color: #fff;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-flex;
  border-radius: 3px;
}

td.action-icons .button-intent-delete {
  background: orangered;
  border: 1px solid orangered;
  color: #fff;
  padding: 5px;
  margin-bottom: 5px;
  display: inline-flex;
  border-radius: 3px;
}

.anticon svg {
  margin-top: -5px;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-pagination .ant-pagination-next .ant-pagination-item-link {
  margin-top: -2px;
}

.table td,
.table th,
.table span,
.table span {
  vertical-align: middle;
}

div.ant-steps-item-title {
  font-size: 13px !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #da4453;
  border-color: #da4453;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #da4453;
  height: 1.6px;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #da4453;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #da4453;
  border-color: #da4453;
}

span.anticon.anticon-check.ant-steps-finish-icon {
  color: #fff;
  font-size: 15px;
  font-weight: 100;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.css-1fdsijx-ValueContainer {
  height: 45px;
}

.css-qbdosj-Input {
  height: 0px;
}

.css-1dimb5e-singleValue {
  font-size: 12px !important;
  /* grid-area:1/1/2/0!important; */
  grid-row-start: 15 !important;
  grid-column-start: 1 !important;
  grid-row-end: 0 !important;
  grid-column-end: 3 !important;
}

.css-1nmdiq5-menu {
  font-size: 14px !important;
}

.css-qbdosj-Input {
  grid-area: auto !important;
  margin: 0px !important;
}

.css-gcwvw8-MuiAutocomplete-root {
  width: 100% !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  height: 45px;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  margin-top: -15px;
}


.form-error {
  box-shadow: 0 0 0 3px #ff5722;
  border-radius: 5px;
}

.css-1dimb5e-singleValue {
  padding-top: 2px;
  padding-left: 5px;
}

.css-1jqq78o-placeholder {
  padding-top: 9px;
  font-size: 14px;
  padding-left: 5px;
}

.css-t3ipsp-control {
  box-shadow: none !important;
  border-color: #dbdbdb !important;
}

/* .profile-img{
  background-image:url(../images/profile-avatar.jpg)
} */

:where(.css-dev-only-do-not-override-sk7ap8).ant-message-notice .ant-message-notice-content {
  padding: 12px 30px;
  font-size: medium;
}

.css-hlgwow {
  height: 45px;
  display: inline !important;
  flex: auto !important;
  padding: 4px 8px !important;
}

.react-tel-input .form-control {
  width: 100%;
}

.navbar.master {
  position: absolute;
  top: 125px;
  height: calc(100% - 125px);
  overflow: visible !important;
  display: flex;
  align-items: flex-start;
}

.navbar.master::-webkit-scrollbar {
  width: 10px;
}

.navbar.master::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.navbar.master::-webkit-scrollbar-thumb {
  background-color: #ffc75e;
  border-radius: 5px;

}

:where(.css-sk7ap8).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #da4453 !important;
  border-color: #da4453 !important;
}

:where(.css-sk7ap8).ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #da4453 !important;
  border-color: #da4453 !important;
}

:where(.css-sk7ap8).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #da4453 !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

input#actual-btn2.actual-btn.file-upload-applicant {
  opacity: 1;
  position: absolute;
  top: 90px;
  width: 130px;
  height: 85px;
  background: red;
  opacity: 0;
}

.css-1dimb5e-singleValue {
  position: absolute;
  top: 22px;
  width: 100%;
}



#btnTrigger {
  display: none;
}

/* .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-sizeMedium.MuiPaginationItem-outlined.MuiPaginationItem-rounded.Mui-selected.MuiPaginationItem-page.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root{
  background: #fff !important;
  color: #000 !important;
  border-color: #da4453 !important;
}

.css-2gftvx.Mui-selected{
  background: #fff !important;
  color: #000 !important;
  border-color: #da4453 !important;
} */

/* .nextui-c-cgxxHA.nextui-c-cgxxHA-eYceDz-fullScreen-false.nextui-c-cgxxHA-gjMawU-scroll-true.nextui-modal.nextui-modal--open.nextui-modal-wrapper-enter.nextui-modal-wrapper-enter-active{
  background-color: transparent;
} */

.modal-body .form-group .label {
  font-size: 13px;
  font-weight: 400 !important;
}

.modal-body .form-group input,
.modal-body .form-group select,
.modal-body .form-group textarea,
.modal-body .form-group label {
  font-size: 13px;
  font-weight: 400 !important;
}

#users,
#office,
#roles,
#visa,
#status,
#clients {
  width: 100%;
}

#users table,
#office table,
#roles table,
#status table,
#visa table,
#clients table {
  border: 1px solid rgb(0 0 0 / 9%);
  margin-top: 20px;
  margin-bottom: 0;
}

.ant-select-selector {
  min-height: 44px;
}

:where(.css-sk7ap8).ant-select-single .ant-select-selector .ant-select-selection-item,
:where(.css-sk7ap8).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-select-single .ant-select-selector .ant-select-selection-item,
:where(.css-dev-only-do-not-override-sk7ap8).ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-select .ant-select-arrow {
  margin-top: 0px !important;
}

.input.checkbox-whatsapp.copy {
  position: absolute;
  margin-top: 2px;
  padding-left: 10px;
  font-weight: 400 !important;
}


.upload-btn-wrapper-import {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-fileupload {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper-import input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.sendBtn {
  margin-top: 8px;
  z-index: 1;
  position: absolute;
  cursor: pointer;
  opacity: 0;
}

.sendBtn:hover {
  cursor: pointer;
}

.hoverStyle:hover {
  cursor: pointer;
}

.justify-content-right {
  justify-content: right !important;
  margin-bottom: 0px !important;
}

button {
  outline: none !important;
}

.modal-admission div div label {
  font-size: 14px;
  font-weight: 400 !important;
}

.modal-admission div div input,
.modal-admission div div select {
  height: 45px !important;
}

.fee-coordinator thead tr th,
.fee-coordinator tbody tr td,
.fee-coordinator tfoot tr td {
  font-size: 15px !important;
}

.form-control.form-control-sm.english {
  width: 100% !important;
}

.table-english {
  font-size: 15px !important;
}

#appointments_wrpr_employee {
  overflow: auto;
}

div.ant-drawer-body {
  padding-top: 0px !important;
}

div.ant-drawer-content {
  border-radius: 20px 0 0px 20px !important;
}

div.ant-drawer-content-wrapper {
  border-radius: 20px 0 0px 20px !important;
}

div.ant-tabs-tab div {
  font-family: 'Poppins';
}

.nextui-c-gulvcB-eEgXHE-isFocusVisible-true {
  box-shadow: none !important;
}

label.label.modal-label {
  font-weight: 400 !important;
  font-size: 15px;
}

.checkboxgroup.registered {
  width: 60px;
  max-width: 100px;
}

.checkboxgroup.registered label {
  display: block;
  font-weight: 400 !important;
  width: 30px;
  text-align: center;
  margin-top: -5px;
}

.checkboxgroup.registered input {
  min-height: 0;
  height: 30px;
  width: 30px;
  margin-top: 5px;
}

.checkboxgroup.visa {
  width: 60px;
  max-width: 100px;
}

.checkboxgroup.visa label {
  display: block;
  font-weight: 400 !important;
  width: 30px;
  text-align: center;
  margin-top: -5px;
}

.checkboxgroup.visa input {
  min-height: 0;
  height: 25px;
  width: 25px;
}

.filter-report div div select,
.filter-report div div input {
  min-height: 20px !important;
}

.rbc-toolbar {
  background-color: #f0f0f0;
}

.notification-item {
  border-radius: 10px;
  padding: 10px;
}

.notification-item-content-title {
  font-size: 14px;
  font-family: Poppins;
  font-weight: 500;
}

.notification-item-content-description {
  font-size: 11px;
  font-family: 'Poppins';
}

.notification-item-content-time {
  font-size: 12px;
  font-family: 'Poppins';
  padding-top: 5px;
  font-weight: 700;
  color: #28a745;
}

.notification-item-close i {
  font-size: 15px;
  color: #28a745;
  position: absolute;
  right: 0px;
  top: -15px;
  background: #F22C3B;
  padding: 2px;
  color: #fff;
  border-radius: 20px;
}

.label-visa {
  font-size: 14px;
  font-weight: 400 !important;
}

.bg-calendar {
  background-color: #fff;
}

.label-bulkassign {
  font-size: 14px;
  font-weight: 400 !important;
}

div.auto-follow div input {
  margin-top: -3px !important;
}


* {
  scrollbar-width: thin;
  scrollbar-color: #e9e9e9;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background-color: #e9e9e9;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 14px;
}

.table-report-ceo::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 5px;
}

.table-report-ceo::-webkit-scrollbar-thumb {
  background-color: #e9e9e9;
  border-radius: 14px;
}

.collageprogram tbody tr td,
.collageprogram thead tr th,
.collageprogram tbody tr th {
  font-size: 10px;
}

.email-mobile {
  display: flex;
  flex-direction: column;
}

div.followup-tab {
  display: flex;
  font-size: 12px;
  margin-bottom: 20px;
}

div.followup-tab div {
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
}

.btn-follow-accordion {
  background-color: #323a51;
  color: #fff;
  border: none;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
}

.comment-box.add-comment {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.ui-sortable-handle.follow-comment th,
.ui-sortable-handle.follow-comment td {
  font-size: 12px !important;
}

span.profile-view {
  text-transform: lowercase;
}

span.profile-view:first-letter {
  text-transform: capitalize;
}

div.view-page.button-section {
  display: flex;
  gap: 15px;
}

div.view-page.button-section .export-btn,
div.view-page.button-section .send-btn {
  min-width: 130px;
  border: none;
  background-color: #3330E4;
  font-size: 12px;
  color: #fff;
  padding: 10px;
  font-weight: 600;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

div.view-page.button-section .export-btn i,
div.view-page.button-section .send-btn i {
  font-size: 16px;
}

div.pdf-container {
  display: flex;
  justify-content: center;
}

div.pdf-container .pdf-form {
  width: 40%;
}

div.pdf-container .pdf-form .pdf-content {
  background: #fff;
  /* width: 100%; */
  padding: 40px;
  margin: 10px;
  height: 7800px;
}

.pdf-head.image {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.pdf-head.image img {
  width: 22%;
}

.pdf-header.title-header {
  background: #000;
}

.pdf-header.title-header div {
  font-size: 12px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
}

.pdf-fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pdf-applicant.imgage-client img {
  width: 90px;
  height: 100px;
}

.pdf-common-fields {
  display: flex;
  gap: 10px;
}

.pdf-label div,
.pdf-value div {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
}

.pdf-value div {
  font-weight: bolder;
}

.pdf-instruction {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 8px;
}

.pdf-list-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  color: #1677ff;
}

.anticon.anticon-delete {
  color: #ff4d4f;
  font-size: 18px;
}

.anticon.anticon-eye {
  color: #0d0d0d;
  font-size: 18px;
}

.ribbon-badge span {
  margin-bottom: 0px !important;
}

.css-bvz20t {
  width: 100%!important;
}

.profile-chip {
  display: flex;
  gap: 10px;
}

.chip-container {
  display: flex;
  height: 44px;
  align-items: center;
}

.chip-status {
  font-size: 10px;
  background: #ff4d4f;
  padding: 3px;
  display: flex;
  border-radius: 35px;
  color: white;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
}

.chip-status.active {
  background-color: #28a745;
}

.chip-status.disable {
  opacity: 0.6;
  cursor: not-allowed;
}

.hot-chip {
  width: 60px;
  padding-left: 12px;
}

.warm-chip {
  width: 73px;
  padding-left: 13px;
}

.cold-chip {
  width: 70px;
  padding-left: 14px;
}

.long-chip {
  width: 98px;
  padding-left: 13px;
}

.hold-chip {
  width: 85px;
  padding-left: 13px;
}

.chip-status div i {
  font-size: 20px;
}

.chip-status div {
  display: flex;
  cursor: pointer;
}

.form-control:focus {
  box-shadow: none !important;
}

.form-group.error input,
.form-group.error select,
.form-group.error textarea {
  box-shadow: 0 0 0 3px #ff5722 !important;
}

.collapse {
  visibility: visible;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.btn-add-new-client2 {
  background: #323a51;
}

.btn-nextrow {
  background: #323a51;
}

.btn-primary {
  color: #fff;
  background-color: #007bff !important;
  border-color: #007bff;
}

.login-buttons .btn-primary {
  background: #323a51 !important;
  border-color: #323a51;
}

.ant-btn-primary {
  background-color: #1677ff;
}

.btn-removerow {
  background-color: #dc3545 !important;
  min-width: 60px !important;
  border-radius: 5px !important;
}

.btn-nextrow {
  background-color: #323a51 !important;
  min-width: 60px !important;
  border-radius: 5px !important;
}

.table-wrpr {
  overflow: auto;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

span.ant-select-arrow span.anticon.anticon-down.ant-select-suffix {
  height: 100%;
  display: flex;
  align-items: end;
}

span.ant-select-arrow span.anticon.anticon-search.ant-select-suffix {
  height: 100%;
  display: flex;
  align-items: end;
}

.ant-select-selection-item {
  font-size: 14px;
}

h5 {
  font-size: 16px;
}

.custom-auto-complete .ant-select-selector {
  min-height: 44px;
}

.custom-auto-complete .ant-select-selector .ant-select-selection-search input {
  min-height: 43px;
  font-size: 12px;
  font-family: 'Poppins';
}

.custom-auto-complete .ant-select-selector .ant-select-selection-placeholder {
  font-size: 12px;
  font-family: "Poppins";
}

.custom-auto-complete .ant-select-selector:focus-within {
  border: none !important;
  outline: #3b82f6 solid 2px !important;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #3b82f6;
}

.custom-auto-complete .ant-select-selector:hover {
  border: none !important;
  outline: #3b82f6 solid 2px !important;
  outline-offset: 0;
  box-shadow: 0 0 0 2px #3b82f6;
}

button,
input,
optgroup,
select,
textarea {
  font-family: 'Poppins';
}

.intented-program select {
  min-height: 40px;
}

table {
  margin-bottom: 0px !important;
}



.countrywise{
  table-layout:fixed;
 
}

.table.countrywise th
{
  font-size:10px !important;
}
.table.countrywise td
{
  font-size:10px !important;
}

table.table.report.collageprogram.countrywise td {
  border: 1px solid rgb(0 0 0 / 22%);
  padding: 4px;
}


table.table.report.collageprogram.countrywise th {
  border: 1px solid #454d62;
  padding: 4px;
}