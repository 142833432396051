
.icon-tag:before {
  content: "\eb2b";
}
.icon-tag1:before {
  content: "\eb2c";
}
.icon-link2:before {
  content: "\eb2d";
}
.icon-link3:before {
  content: "\eb2e";
}
.icon-cabinet:before {
  content: "\eb2f";
}
.icon-cabinet1:before {
  content: "\eb30";
}
.icon-calendar1:before {
  content: "\eb31";
}
.icon-calendar2:before {
  content: "\eb32";
}
.icon-calendar3:before {
  content: "\eb33";
}
.icon-file:before {
  content: "\eb34";
}
.icon-file1:before {
  content: "\eb35";
}
.icon-file2:before {
  content: "\eb36";
}
.icon-files:before {
  content: "\eb37";
}
.icon-phone2:before {
  content: "\eb38";
}
.icon-tablet2:before {
  content: "\eb39";
}
.icon-window:before {
  content: "\eb3a";
}
.icon-monitor:before {
  content: "\eb4d";
}
.icon-ipod:before {
  content: "\eb4e";
}
.icon-tv2:before {
  content: "\eb4f";
}
.icon-camera2:before {
  content: "\eb50";
}
.icon-camera3:before {
  content: "\eb51";
}
.icon-camera4:before {
  content: "\eb52";
}
.icon-drink2:before {
  content: "\eb5b";
}
.icon-drink3:before {
  content: "\eb5c";
}
.icon-coffee:before {
  content: "\eb5d";
}
.icon-mug1:before {
  content: "\eb5e";
}
.icon-icecream:before {
  content: "\eb5f";
}
.icon-cake1:before {
  content: "\eb60";
}
.icon-inbox1:before {
  content: "\eb61";
}
.icon-download1:before {
  content: "\eb62";
}
.icon-upload1:before {
  content: "\eb63";
}
.icon-inbox2:before {
  content: "\eb64";
}
.icon-checkmark1:before {
  content: "\eb65";
}
.icon-checkmark3:before {
  content: "\eb66";
}
.icon-cancel1:before {
  content: "\eb67";
}
.icon-cancel2:before {
  content: "\eb68";
}
.icon-plus1:before {
  content: "\eb69";
}
.icon-plus2:before {
  content: "\eb6a";
}
.icon-minus1:before {
  content: "\eb6b";
}
.icon-minus2:before {
  content: "\eb6c";
}
.icon-notice:before {
  content: "\eb6d";
}
.icon-notice1:before {
  content: "\eb6e";
}
.icon-cog1:before {
  content: "\eb6f";
}
.icon-cogs1:before {
  content: "\eb70";
}
.icon-cog2:before {
  content: "\eb71";
}
.icon-warning2:before {
  content: "\eb72";
}
.icon-health:before {
  content: "\eb73";
}
.icon-suitcase:before {
  content: "\eb74";
}
.icon-suitcase1:before {
  content: "\eb75";
}
.icon-suitcase2:before {
  content: "\eb76";
}
.icon-picture:before {
  content: "\eb77";
}
.icon-pictures:before {
  content: "\eb78";
}
.icon-pictures1:before {
  content: "\eb79";
}
.icon-android2:before {
  content: "\eb7a";
}
.icon-pacman1:before {
  content: "\eb7c";
}
.icon-watch1:before {
  content: "\eb7e";
}
.icon-chronometer:before {
  content: "\eb7f";
}
.icon-watch2:before {
  content: "\eb80";
}
.icon-alarmclock:before {
  content: "\eb81";
}
.icon-time1:before {
  content: "\eb83";
}
.icon-headphones1:before {
  content: "\eb84";
}
.icon-wallet:before {
  content: "\eb85";
}
.icon-checkmark4:before {
  content: "\eb86";
}
.icon-cancel3:before {
  content: "\eb87";
}
.icon-position:before {
  content: "\eb89";
}
.icon-sitemap:before {
  content: "\eb8a";
}
.icon-sitemap1:before {
  content: "\eb8b";
}
.icon-cloud1:before {
  content: "\eb8c";
}
.icon-upload4:before {
  content: "\eb8d";
}
.icon-chart1:before {
  content: "\eb8f";
}
.icon-chart2:before {
  content: "\eb90";
}
.icon-chart3:before {
  content: "\eb91";
}
.icon-chart4:before {
  content: "\eb92";
}
.icon-chart5:before {
  content: "\eb93";
}
.icon-location1:before {
  content: "\eb94";
}
.icon-download4:before {
  content: "\eb95";
}
.icon-basket:before {
  content: "\eb96";
}
.icon-folder2:before {
  content: "\eb97";
}
.icon-alarm2:before {
  content: "\eb99";
}
.icon-alarm-cancel:before {
  content: "\eb9a";
}
.icon-phone3:before {
  content: "\eb9b";
}
.icon-phone4:before {
  content: "\eb9c";
}
.icon-image1:before {
  content: "\eb9d";
}
.icon-open:before {
  content: "\eb9e";
}
.icon-sale:before {
  content: "\eb9f";
}
.icon-direction:before {
  content: "\eba0";
}
.icon-map3:before {
  content: "\eba1";
}
.icon-trashcan:before {
  content: "\eba2";
}
.icon-graduate:before {
  content: "\eba4";
}
.icon-tie:before {
  content: "\eba6";
}
.icon-eightball:before {
  content: "\eba8";
}
.icon-baseball:before {
  content: "\ebab";
}
.icon-soccer:before {
  content: "\ebac";
}
.icon-3dglasses:before {
  content: "\ebad";
}
.icon-mouse1:before {
  content: "\ebb2";
}
.icon-smiley:before {
  content: "\ebb3";
}
.icon-sad1:before {
  content: "\ebb4";
}
.icon-mute:before {
  content: "\ebb5";
}
.icon-medal:before {
  content: "\ebb9";
}
.icon-medal1:before {
  content: "\ebba";
}
.icon-key1:before {
  content: "\ebbc";
}
.icon-cord:before {
  content: "\ebbd";
}
.icon-locked:before {
  content: "\ebbe";
}
.icon-unlocked1:before {
  content: "\ebbf";
}
.icon-locked1:before {
  content: "\ebc0";
}
.icon-unlocked2:before {
  content: "\ebc1";
}
.icon-magnifier:before {
  content: "\ebc2";
}
.icon-zoomin:before {
  content: "\ebc3";
}
.icon-zoomout:before {
  content: "\ebc4";
}
.icon-stack1:before {
  content: "\ebc5";
}
.icon-stack2:before {
  content: "\ebc6";
}
.icon-stack3:before {
  content: "\ebc7";
}
.icon-davidstar:before {
  content: "\ebc8";
}
.icon-cross1:before {
  content: "\ebc9";
}
.icon-moonandstar:before {
  content: "\ebca";
}
.icon-lamp:before {
  content: "\ebcf";
}
.icon-lamp1:before {
  content: "\ebd0";
}
.icon-umbrella:before {
  content: "\ebd1";
}
.icon-bomb:before {
  content: "\ebd3";
}
.icon-archive1:before {
  content: "\ebd4";
}
.icon-pil:before {
  content: "\ebdd";
}
.icon-injection:before {
  content: "\ebde";
}
.icon-thermometer:before {
  content: "\ebdf";
}
.icon-lamp2:before {
  content: "\ebe0";
}
.icon-lamp4:before {
  content: "\ebe2";
}
.icon-cube:before {
  content: "\ebe3";
}
.icon-box:before {
  content: "\ebe4";
}
.icon-box1:before {
  content: "\ebe5";
}
.icon-diamond:before {
  content: "\ebe6";
}
.icon-moneybag:before {
  content: "\ebe8";
}
.icon-grid:before {
  content: "\ebe9";
}
.icon-grid1:before {
  content: "\ebea";
}
.icon-list3:before {
  content: "\ebeb";
}
.icon-list4:before {
  content: "\ebec";
}
.icon-ruler:before {
  content: "\ebed";
}
.icon-ruler1:before {
  content: "\ebee";
}
.icon-layout:before {
  content: "\ebef";
}
.icon-layout1:before {
  content: "\ebf0";
}
.icon-layout2:before {
  content: "\ebf1";
}
.icon-layout3:before {
  content: "\ebf2";
}
.icon-layout4:before {
  content: "\ebf3";
}
.icon-layout5:before {
  content: "\ebf4";
}
.icon-layout6:before {
  content: "\ebf5";
}
.icon-layout7:before {
  content: "\ebf6";
}
.icon-layout8:before {
  content: "\ebf7";
}
.icon-layout9:before {
  content: "\ebf8";
}
.icon-layout10:before {
  content: "\ebf9";
}
.icon-layout11:before {
  content: "\ebfa";
}
.icon-layout12:before {
  content: "\ebfb";
}
.icon-layout13:before {
  content: "\ebfc";
}
.icon-tools:before {
  content: "\ebfd";
}
.icon-screwdriver:before {
  content: "\ebfe";
}
.icon-paint:before {
  content: "\ebff";
}
.icon-brush1:before {
  content: "\ec01";
}
.icon-pen1:before {
  content: "\ec02";
}
.icon-chat1:before {
  content: "\ec03";
}
.icon-comments:before {
  content: "\ec04";
}
.icon-chat2:before {
  content: "\ec05";
}
.icon-chat3:before {
  content: "\ec06";
}
.icon-volume:before {
  content: "\ec07";
}
.icon-volume1:before {
  content: "\ec08";
}
.icon-volume2:before {
  content: "\ec09";
}
.icon-equalizer3:before {
  content: "\ec0a";
}
.icon-resize:before {
  content: "\ec0b";
}
.icon-resize1:before {
  content: "\ec0c";
}
.icon-narrow:before {
  content: "\ec0e";
}
.icon-resize2:before {
  content: "\ec0f";
}
.icon-download5:before {
  content: "\ec10";
}
.icon-calculator1:before {
  content: "\ec11";
}
.icon-library1:before {
  content: "\ec12";
}
.icon-auction:before {
  content: "\ec13";
}
.icon-justice:before {
  content: "\ec14";
}
.icon-stats:before {
  content: "\ec15";
}
.icon-attachment2:before {
  content: "\ec17";
}
.icon-hourglass:before {
  content: "\ec18";
}
.icon-abacus:before {
  content: "\ec19";
}
.icon-pencil1:before {
  content: "\ec1a";
}
.icon-pen2:before {
  content: "\ec1b";
}
.icon-pin:before {
  content: "\ec1c";
}
.icon-pin1:before {
  content: "\ec1d";
}
.icon-edit:before {
  content: "\ec1f";
}
.icon-scissors1:before {
  content: "\ec20";
}
.icon-profile1:before {
  content: "\ec21";
}
.icon-profile3:before {
  content: "\ec23";
}
.icon-rotate:before {
  content: "\ec24";
}
.icon-rotate1:before {
  content: "\ec25";
}
.icon-reply2:before {
  content: "\ec26";
}
.icon-forward4:before {
  content: "\ec27";
}
.icon-retweet:before {
  content: "\ec28";
}
.icon-shuffle2:before {
  content: "\ec29";
}
.icon-loop1:before {
  content: "\ec2a";
}
.icon-crop2:before {
  content: "\ec2b";
}
.icon-square:before {
  content: "\ec2c";
}
.icon-square1:before {
  content: "\ec2d";
}
.icon-circle:before {
  content: "\ec2e";
}
.icon-dollar1:before {
  content: "\ec30";
}
.icon-pig:before {
  content: "\ec32";
}
.icon-bookmark1:before {
  content: "\ec33";
}
.icon-envelope:before {
  content: "\ec38";
}
.icon-envelope1:before {
  content: "\ec39";
}
.icon-radioactive:before {
  content: "\ec3a";
}
.icon-music1:before {
  content: "\ec3b";
}
.icon-presentation:before {
  content: "\ec3c";
}
.icon-male:before {
  content: "\ec3d";
}
.icon-female:before {
  content: "\ec3e";
}
.icon-aids:before {
  content: "\ec3f";
}
.icon-heart1:before {
  content: "\ec40";
}
.icon-info2:before {
  content: "\ec41";
}
.icon-info3:before {
  content: "\ec42";
}
.icon-piano:before {
  content: "\ec43";
}
.icon-rain:before {
  content: "\ec44";
}
.icon-cloudy1:before {
  content: "\ec4a";
}
.icon-car:before {
  content: "\ec4b";
}
.icon-bike:before {
  content: "\ec4c";
}
.icon-truck1:before {
  content: "\ec4d";
}
.icon-bus:before {
  content: "\ec4e";
}
.icon-bike1:before {
  content: "\ec4f";
}
.icon-plane:before {
  content: "\ec50";
}
.icon-paperplane:before {
  content: "\ec51";
}
.icon-rocket1:before {
  content: "\ec52";
}
.icon-book1:before {
  content: "\ec53";
}
.icon-book2:before {
  content: "\ec54";
}
.icon-barcode1:before {
  content: "\ec55";
}
.icon-barcode2:before {
  content: "\ec56";
}
.icon-expand:before {
  content: "\ec57";
}
.icon-collapse:before {
  content: "\ec58";
}
.icon-popout:before {
  content: "\ec59";
}
.icon-popin:before {
  content: "\ec5a";
}
.icon-target1:before {
  content: "\ec5b";
}
.icon-ticket1:before {
  content: "\ec5e";
}
.icon-ticket2:before {
  content: "\ec5f";
}
.icon-ticket3:before {
  content: "\ec60";
}
.icon-microphone3:before {
  content: "\ec61";
}
.icon-cone:before {
  content: "\ec62";
}
.icon-blocked1:before {
  content: "\ec63";
}
.icon-stop3:before {
  content: "\ec64";
}
.icon-keyboard3:before {
  content: "\ec66";
}
.icon-radio2:before {
  content: "\ec67";
}
.icon-printer1:before {
  content: "\ec68";
}
.icon-checked:before {
  content: "\ec69";
}
.icon-error1:before {
  content: "\ec6a";
}
.icon-add1:before {
  content: "\ec6b";
}
.icon-minus3:before {
  content: "\ec6c";
}
.icon-pictures2:before {
  content: "\ec6e";
}
.icon-atom:before {
  content: "\ec6f";
}
.icon-eyedropper1:before {
  content: "\ec70";
}
.icon-globe:before {
  content: "\ec71";
}
.icon-globe1:before {
  content: "\ec72";
}
.icon-shipping:before {
  content: "\ec73";
}
.icon-yingyang:before {
  content: "\ec74";
}
.icon-compass1:before {
  content: "\ec75";
}
.icon-zip:before {
  content: "\ec76";
}
.icon-zip1:before {
  content: "\ec77";
}
.icon-anchor:before {
  content: "\ec78";
}
.icon-tags:before {
  content: "\ec7c";
}
.icon-heart2:before {
  content: "\ec7d";
}
.icon-heart3:before {
  content: "\ec7e";
}
.icon-usb1:before {
  content: "\ec7f";
}
.icon-clipboard1:before {
  content: "\ec80";
}
.icon-clipboard2:before {
  content: "\ec81";
}
.icon-clipboard3:before {
  content: "\ec82";
}
.icon-switch2:before {
  content: "\ec83";
}
.icon-ruler2:before {
  content: "\ec84";
}
.icon-heart4:before {
  content: "\ec85";
}
.icon-cloud2:before {
  content: "\ec86";
}
.icon-star1:before {
  content: "\ec87";
}
.icon-tv3:before {
  content: "\ec88";
}
.icon-sound:before {
  content: "\ec89";
}
.icon-video:before {
  content: "\ec8a";
}
.icon-trash:before {
  content: "\ec8b";
}
.icon-user1:before {
  content: "\ec8c";
}
.icon-key3:before {
  content: "\ec8d";
}
.icon-search2:before {
  content: "\ec8e";
}
.icon-settings1:before {
  content: "\ec8f";
}
.icon-camera5:before {
  content: "\ec90";
}
.icon-tag2:before {
  content: "\ec91";
}
.icon-lock2:before {
  content: "\ec92";
}
.icon-bulb:before {
  content: "\ec93";
}
.icon-pen3:before {
  content: "\ec94";
}
.icon-diamond1:before {
  content: "\ec95";
}
.icon-display1:before {
  content: "\ec96";
}
.icon-location3:before {
  content: "\ec97";
}
.icon-eye2:before {
  content: "\ec98";
}
.icon-bubble1:before {
  content: "\ec99";
}
.icon-stack4:before {
  content: "\ec9a";
}
.icon-cup:before {
  content: "\ec9b";
}
.icon-phone5:before {
  content: "\ec9c";
}
.icon-news:before {
  content: "\ec9d";
}
.icon-mail1:before {
  content: "\ec9e";
}
.icon-like:before {
  content: "\ec9f";
}
.icon-photo1:before {
  content: "\eca0";
}
.icon-note1:before {
  content: "\eca1";
}
.icon-clock1:before {
  content: "\eca2";
}
.icon-paperplane1:before {
  content: "\eca3";
}
.icon-params:before {
  content: "\eca4";
}
.icon-banknote:before {
  content: "\eca5";
}
.icon-data:before {
  content: "\eca6";
}
.icon-music2:before {
  content: "\eca7";
}
.icon-megaphone2:before {
  content: "\eca8";
}
.icon-study:before {
  content: "\eca9";
}
.icon-lab2:before {
  content: "\ecaa";
}
.icon-food:before {
  content: "\ecab";
}
.icon-t-shirt:before {
  content: "\ecac";
}
.icon-fire1:before {
  content: "\ecad";
}
.icon-clip:before {
  content: "\ecae";
}
.icon-shop1:before {
  content: "\ecaf";
}
.icon-calendar4:before {
  content: "\ecb0";
}
.icon-wallet1:before {
  content: "\ecb1";
}
.icon-vynil:before {
  content: "\ecb2";
}
.icon-truck2:before {
  content: "\ecb3";
}
.icon-world:before {
  content: "\ecb4";
}
.icon-times:before {
  content: "\ecb5";
}
.icon-tick:before {
  content: "\ecb6";
}
.icon-plus3:before {
  content: "\ecb7";
}
.icon-minus4:before {
  content: "\ecb8";
}
.icon-equals:before {
  content: "\ecb9";
}
.icon-divide:before {
  content: "\ecba";
}
.icon-chevron-right:before {
  content: "\ecbb";
}
.icon-chevron-left:before {
  content: "\ecbc";
}
.icon-arrow-right-thick:before {
  content: "\ecbd";
}
.icon-arrow-left-thick:before {
  content: "\ecbe";
}
.icon-th-small:before {
  content: "\ecbf";
}
.icon-th-menu:before {
  content: "\ecc0";
}
.icon-th-list:before {
  content: "\ecc1";
}
.icon-th-large:before {
  content: "\ecc2";
}
.icon-home4:before {
  content: "\ecc3";
}
.icon-arrow-forward:before {
  content: "\ecc4";
}
.icon-arrow-back:before {
  content: "\ecc5";
}
.icon-link4:before {
  content: "\ecc8";
}
.icon-image2:before {
  content: "\ecc9";
}
.icon-arrow-up-thick:before {
  content: "\ecca";
}
.icon-arrow-down-thick:before {
  content: "\eccb";
}
.icon-starburst:before {
  content: "\eccc";
}
.icon-starburst-outline:before {
  content: "\eccd";
}
.icon-star2:before {
  content: "\ecce";
}
.icon-flow-children:before {
  content: "\eccf";
}
.icon-export:before {
  content: "\ecd0";
}
.icon-delete1:before {
  content: "\ecd1";
}
.icon-delete-outline:before {
  content: "\ecd2";
}
.icon-cloud-storage:before {
  content: "\ecd3";
}
.icon-wi-fi:before {
  content: "\ecd4";
}
.icon-heart5:before {
  content: "\ecd5";
}
.icon-attachment3:before {
  content: "\ecd9";
}
.icon-warning3:before {
  content: "\ecdb";
}
.icon-user2:before {
  content: "\ecdc";
}
.icon-lock-open:before {
  content: "\ecde";
}
.icon-lock-closed:before {
  content: "\ecdf";
}
.icon-location-arrow:before {
  content: "\ece0";
}
.icon-info4:before {
  content: "\ece1";
}
.icon-user-delete:before {
  content: "\ece2";
}
.icon-user-add:before {
  content: "\ece3";
}
.icon-media-pause:before {
  content: "\ece4";
}
.icon-group:before {
  content: "\ece5";
}
.icon-chart-bar:before {
  content: "\ece8";
}
.icon-video1:before {
  content: "\ecea";
}
.icon-globe2:before {
  content: "\eced";
}
.icon-eye3:before {
  content: "\ecee";
}
.icon-cog3:before {
  content: "\ecef";
}
.icon-upload5:before {
  content: "\ecf1";
}
.icon-refresh1:before {
  content: "\ecf3";
}
.icon-pin2:before {
  content: "\ecf4";
}
.icon-zoom:before {
  content: "\ecf9";
}
.icon-zoom-in1:before {
  content: "\ecfb";
}
.icon-sort-numerically:before {
  content: "\ecfc";
}
.icon-sort-alphabetically:before {
  content: "\ecfd";
}
.icon-input-checked:before {
  content: "\ecfe";
}
.icon-code1:before {
  content: "\ed02";
}
.icon-arrow-sync:before {
  content: "\ed03";
}
.icon-arrow-shuffle:before {
  content: "\ed04";
}
.icon-spanner:before {
  content: "\ed0a";
}
.icon-plane1:before {
  content: "\ed0d";
}
.icon-phone6:before {
  content: "\ed0f";
}
.icon-microphone4:before {
  content: "\ed10";
}
.icon-media-rewind:before {
  content: "\ed11";
}
.icon-flag2:before {
  content: "\ed12";
}
.icon-adjust-brightness:before {
  content: "\ed13";
}
.icon-waves:before {
  content: "\ed14";
}
.icon-social-twitter:before {
  content: "\ed15";
}
.icon-social-facebook:before {
  content: "\ed16";
}
.icon-social-dribbble:before {
  content: "\ed17";
}
.icon-media-stop:before {
  content: "\ed18";
}
.icon-media-record:before {
  content: "\ed19";
}
.icon-media-play:before {
  content: "\ed1a";
}
.icon-media-fast-forward:before {
  content: "\ed1b";
}
.icon-media-eject:before {
  content: "\ed1c";
}
.icon-social-vimeo:before {
  content: "\ed1d";
}
.icon-social-tumbler:before {
  content: "\ed1e";
}
.icon-social-skype:before {
  content: "\ed1f";
}
.icon-social-pinterest:before {
  content: "\ed20";
}
.icon-social-linkedin:before {
  content: "\ed21";
}
.icon-social-flickr:before {
  content: "\ed24";
}
.icon-at:before {
  content: "\ed25";
}
.icon-times-outline:before {
  content: "\ed26";
}
.icon-plus-outline:before {
  content: "\ed27";
}
.icon-minus-outline:before {
  content: "\ed28";
}
.icon-tick-outline:before {
  content: "\ed29";
}
.icon-th-large-outline:before {
  content: "\ed2a";
}
.icon-equals-outline:before {
  content: "\ed2b";
}
.icon-divide-outline:before {
  content: "\ed2c";
}
.icon-chevron-right-outline:before {
  content: "\ed2d";
}
.icon-chevron-left-outline:before {
  content: "\ed2e";
}
.icon-arrow-right-outline:before {
  content: "\ed2f";
}
.icon-arrow-left-outline:before {
  content: "\ed30";
}
.icon-home-outline:before {
  content: "\ed35";
}
.icon-arrow-up-outline:before {
  content: "\ed36";
}
.icon-arrow-forward-outline:before {
  content: "\ed37";
}
.icon-arrow-down-outline:before {
  content: "\ed38";
}
.icon-arrow-back-outline:before {
  content: "\ed39";
}
.icon-trash1:before {
  content: "\ed3a";
}
.icon-rss-outline:before {
  content: "\ed3b";
}
.icon-message1:before {
  content: "\ed3c";
}
.icon-location-outline:before {
  content: "\ed3d";
}
.icon-link-outline:before {
  content: "\ed3e";
}
.icon-image-outline:before {
  content: "\ed3f";
}
.icon-export-outline:before {
  content: "\ed40";
}
.icon-cross2:before {
  content: "\ed41";
}
.icon-wi-fi-outline:before {
  content: "\ed42";
}
.icon-star-outline:before {
  content: "\ed43";
}
.icon-media-pause-outline:before {
  content: "\ed44";
}
.icon-mail5:before {
  content: "\ed45";
}
.icon-heart-outline:before {
  content: "\ed46";
}
.icon-flash-outline:before {
  content: "\ed47";
}
.icon-cancel-outline:before {
  content: "\ed48";
}
.icon-beaker:before {
  content: "\ed49";
}
.icon-arrow-move-outline:before {
  content: "\ed4a";
}
.icon-watch3:before {
  content: "\ed4b";
}
.icon-warning-outline:before {
  content: "\ed4c";
}
.icon-time2:before {
  content: "\ed4d";
}
.icon-radar-outline:before {
  content: "\ed4e";
}
.icon-lock-open-outline:before {
  content: "\ed4f";
}
.icon-location-arrow-outline:before {
  content: "\ed50";
}
.icon-info-outline:before {
  content: "\ed51";
}
.icon-backspace-outline:before {
  content: "\ed52";
}
.icon-attachment-outline:before {
  content: "\ed53";
}
.icon-user-outline:before {
  content: "\ed54";
}
.icon-user-delete-outline:before {
  content: "\ed55";
}
.icon-user-add-outline:before {
  content: "\ed56";
}
.icon-lock-closed-outline:before {
  content: "\ed57";
}
.icon-group-outline:before {
  content: "\ed58";
}
.icon-chart-pie-outline:before {
  content: "\ed59";
}
.icon-chart-line-outline:before {
  content: "\ed5a";
}
.icon-chart-bar-outline:before {
  content: "\ed5b";
}
.icon-video-outline:before {
  content: "\ed5d";
}
.icon-point-of-interest-outline:before {
  content: "\ed5e";
}
.icon-map4:before {
  content: "\ed5f";
}
.icon-key-outline:before {
  content: "\ed60";
}
.icon-globe-outline:before {
  content: "\ed62";
}
.icon-eye-outline:before {
  content: "\ed63";
}
.icon-cog-outline:before {
  content: "\ed64";
}
.icon-camera-outline:before {
  content: "\ed65";
}
.icon-battery-mid:before {
  content: "\ed6d";
}
.icon-battery-low:before {
  content: "\ed6e";
}
.icon-battery-high:before {
  content: "\ed6f";
}
.icon-zoom-outline:before {
  content: "\ed70";
}
.icon-tag3:before {
  content: "\ed73";
}
.icon-tabs-outline:before {
  content: "\ed74";
}
.icon-pin-outline:before {
  content: "\ed75";
}
.icon-battery-full:before {
  content: "\ed78";
}
.icon-battery-charge:before {
  content: "\ed79";
}
.icon-pipette:before {
  content: "\ed7a";
}
.icon-pencil3:before {
  content: "\ed7b";
}
.icon-folder3:before {
  content: "\ed7c";
}
.icon-folder-delete:before {
  content: "\ed7d";
}
.icon-folder-add:before {
  content: "\ed7e";
}
.icon-edit1:before {
  content: "\ed7f";
}
.icon-document:before {
  content: "\ed80";
}
.icon-document-delete:before {
  content: "\ed81";
}
.icon-document-add:before {
  content: "\ed82";
}
.icon-brush2:before {
  content: "\ed83";
}
.icon-thumbs-up:before {
  content: "\ed84";
}
.icon-thumbs-down:before {
  content: "\ed85";
}
.icon-pen4:before {
  content: "\ed86";
}
.icon-sort-numerically-outline:before {
  content: "\ed87";
}
.icon-sort-alphabetically-outline:before {
  content: "\ed88";
}
.icon-bookmark3:before {
  content: "\ed8c";
}
.icon-input-checked-outline:before {
  content: "\ed8d";
}
.icon-code-outline:before {
  content: "\ed8e";
}
.icon-arrow-up1:before {
  content: "\ed91";
}
.icon-arrow-right1:before {
  content: "\ed93";
}
.icon-arrow-loop-outline:before {
  content: "\ed95";
}
.icon-arrow-left1:before {
  content: "\ed96";
}
.icon-flow-switch:before {
  content: "\ed97";
}
.icon-flow-parallel:before {
  content: "\ed98";
}
.icon-flow-merge:before {
  content: "\ed99";
}
.icon-document-text:before {
  content: "\ed9a";
}
.icon-clipboard4:before {
  content: "\ed9b";
}
.icon-calculator2:before {
  content: "\ed9c";
}
.icon-arrow-down1:before {
  content: "\ed9f";
}
.icon-gift1:before {
  content: "\eda0";
}
.icon-database1:before {
  content: "\eda2";
}
.icon-bell1:before {
  content: "\eda3";
}
.icon-flag-outline:before {
  content: "\edb5";
}
.icon-phone-outline:before {
  content: "\edbe";
}
.icon-microphone-outline:before {
  content: "\edbf";
}
.icon-weather-sunny:before {
  content: "\edc2";
}
.icon-wine:before {
  content: "\edcb";
}
.icon-tags1:before {
  content: "\edce";
}
.icon-plug:before {
  content: "\edcf";
}
.icon-coffee1:before {
  content: "\edd2";
}
.icon-type:before {
  content: "\ede9";
}
.icon-box2:before {
  content: "\edea";
}
.icon-archive3:before {
  content: "\edeb";
}
.icon-envelope2:before {
  content: "\edec";
}
.icon-email:before {
  content: "\eded";
}
.icon-files1:before {
  content: "\edee";
}
.icon-uniEDEF:before {
  content: "\edef";
}
.icon-file-settings:before {
  content: "\edf0";
}
.icon-file-add:before {
  content: "\edf1";
}
.icon-file3:before {
  content: "\edf2";
}
.icon-align-left:before {
  content: "\edf3";
}
.icon-align-right:before {
  content: "\edf4";
}
.icon-align-center:before {
  content: "\edf5";
}
.icon-align-justify:before {
  content: "\edf6";
}
.icon-file-broken:before {
  content: "\edf7";
}
.icon-browser:before {
  content: "\edf8";
}
.icon-windows1:before {
  content: "\edf9";
}
.icon-window1:before {
  content: "\edfa";
}
.icon-folder4:before {
  content: "\edfb";
}
.icon-folder-add1:before {
  content: "\edfc";
}
.icon-folder-settings:before {
  content: "\edfd";
}
.icon-folder-check:before {
  content: "\edfe";
}
.icon-wifi-low:before {
  content: "\edff";
}
.icon-wifi-mid:before {
  content: "\ee00";
}
.icon-wifi-full:before {
  content: "\ee01";
}
.icon-connection-empty:before {
  content: "\ee02";
}
.icon-connection-25:before {
  content: "\ee03";
}
.icon-connection-50:before {
  content: "\ee04";
}
.icon-connection-75:before {
  content: "\ee05";
}
.icon-connection-full:before {
  content: "\ee06";
}
.icon-list5:before {
  content: "\ee07";
}
.icon-grid2:before {
  content: "\ee08";
}
.icon-uniEE09:before {
  content: "\ee09";
}
.icon-battery-charging:before {
  content: "\ee0a";
}
.icon-battery-empty:before {
  content: "\ee0b";
}
.icon-battery-25:before {
  content: "\ee0c";
}
.icon-battery-50:before {
  content: "\ee0d";
}
.icon-battery-75:before {
  content: "\ee0e";
}
.icon-battery-full1:before {
  content: "\ee0f";
}
.icon-settings2:before {
  content: "\ee10";
}
.icon-arrow-left3:before {
  content: "\ee11";
}
.icon-arrow-up3:before {
  content: "\ee12";
}
.icon-arrow-down3:before {
  content: "\ee13";
}
.icon-arrow-right3:before {
  content: "\ee14";
}
.icon-reload:before {
  content: "\ee15";
}
.icon-refresh2:before {
  content: "\ee16";
}
.icon-volume4:before {
  content: "\ee17";
}
.icon-volume-increase1:before {
  content: "\ee18";
}
.icon-volume-decrease1:before {
  content: "\ee19";
}
.icon-mute1:before {
  content: "\ee1a";
}
.icon-microphone5:before {
  content: "\ee1b";
}
.icon-microphone-off:before {
  content: "\ee1c";
}
.icon-book4:before {
  content: "\ee1d";
}
.icon-checkmark5:before {
  content: "\ee1e";
}
.icon-checkbox-checked1:before {
  content: "\ee1f";
}
.icon-checkbox:before {
  content: "\ee20";
}
.icon-paperclip:before {
  content: "\ee21";
}
.icon-download7:before {
  content: "\ee22";
}
.icon-tag4:before {
  content: "\ee23";
}
.icon-trashcan1:before {
  content: "\ee24";
}
.icon-search3:before {
  content: "\ee25";
}
.icon-zoomin1:before {
  content: "\ee26";
}
.icon-zoomout1:before {
  content: "\ee27";
}
.icon-chat4:before {
  content: "\ee28";
}
.icon-chat-1:before {
  content: "\ee29";
}
.icon-chat-2:before {
  content: "\ee2a";
}
.icon-chat-3:before {
  content: "\ee2b";
}
.icon-comment1:before {
  content: "\ee2c";
}
.icon-calendar5:before {
  content: "\ee2d";
}
.icon-bookmark4:before {
  content: "\ee2e";
}
.icon-email1:before {
  content: "\ee2f";
}
.icon-heart6:before {
  content: "\ee30";
}
.icon-enter1:before {
  content: "\ee31";
}
.icon-cloud3:before {
  content: "\ee32";
}
.icon-book5:before {
  content: "\ee33";
}
.icon-star3:before {
  content: "\ee34";
}
.icon-clock3:before {
  content: "\ee35";
}
.icon-printer3:before {
  content: "\ee36";
}
.icon-home5:before {
  content: "\ee37";
}
.icon-flag3:before {
  content: "\ee38";
}
.icon-meter1:before {
  content: "\ee39";
}
.icon-switch3:before {
  content: "\ee3a";
}
.icon-forbidden:before {
  content: "\ee3b";
}
.icon-lock3:before {
  content: "\ee3c";
}
.icon-unlocked3:before {
  content: "\ee3d";
}
.icon-unlocked4:before {
  content: "\ee3e";
}
.icon-users1:before {
  content: "\ee3f";
}
.icon-user3:before {
  content: "\ee40";
}
.icon-users2:before {
  content: "\ee41";
}
.icon-user4:before {
  content: "\ee42";
}
.icon-bullhorn1:before {
  content: "\ee43";
}
.icon-share3:before {
  content: "\ee44";
}
.icon-screen:before {
  content: "\ee45";
}
.icon-phone7:before {
  content: "\ee46";
}
.icon-phone-portrait:before {
  content: "\ee47";
}
.icon-phone-landscape:before {
  content: "\ee48";
}
.icon-tablet3:before {
  content: "\ee49";
}
.icon-tablet-landscape:before {
  content: "\ee4a";
}
.icon-laptop2:before {
  content: "\ee4b";
}
.icon-camera7:before {
  content: "\ee4c";
}
.icon-microwaveoven:before {
  content: "\ee4d";
}
.icon-creditcards:before {
  content: "\ee4e";
}
.icon-calculator3:before {
  content: "\ee4f";
}
.icon-bag1:before {
  content: "\ee50";
}
.icon-diamond2:before {
  content: "\ee51";
}
.icon-drink4:before {
  content: "\ee52";
}
.icon-shorts:before {
  content: "\ee53";
}
.icon-vcard:before {
  content: "\ee54";
}
.icon-sun2:before {
  content: "\ee55";
}
.icon-bill:before {
  content: "\ee56";
}
.icon-coffee2:before {
  content: "\ee57";
}
.icon-uniEE58:before {
  content: "\ee58";
}
.icon-newspaper1:before {
  content: "\ee59";
}
.icon-stack5:before {
  content: "\ee5a";
}
.icon-mapmarker:before {
  content: "\ee5b";
}
.icon-map5:before {
  content: "\ee5c";
}
.icon-support1:before {
  content: "\ee5d";
}
.icon-uniEE5E:before {
  content: "\ee5e";
}
.icon-barbell:before {
  content: "\ee5f";
}
.icon-stopwatch2:before {
  content: "\ee60";
}
.icon-atom1:before {
  content: "\ee61";
}
.icon-syringe:before {
  content: "\ee62";
}
.icon-health1:before {
  content: "\ee63";
}
.icon-bolt:before {
  content: "\ee64";
}
.icon-pill:before {
  content: "\ee65";
}
.icon-bones:before {
  content: "\ee66";
}
.icon-lab3:before {
  content: "\ee67";
}
.icon-clipboard5:before {
  content: "\ee68";
}
.icon-mug2:before {
  content: "\ee69";
}
.icon-bucket:before {
  content: "\ee6a";
}
.icon-select:before {
  content: "\ee6b";
}
.icon-graph:before {
  content: "\ee6c";
}
.icon-crop3:before {
  content: "\ee6d";
}
.icon-image3:before {
  content: "\ee6e";
}
.icon-cube1:before {
  content: "\ee6f";
}
.icon-bars:before {
  content: "\ee70";
}
.icon-chart6:before {
  content: "\ee71";
}
.icon-pencil4:before {
  content: "\ee72";
}
.icon-measure:before {
  content: "\ee73";
}
.icon-eyedropper2:before {
  content: "\ee74";
}
.icon-3d_rotation:before {
  content: "\e84d";
}
.icon-ac_unit:before {
  content: "\eb3b";
}
.icon-alarm1:before {
  content: "\e855";
}
.icon-access_alarms:before {
  content: "\e191";
}
.icon-schedule:before {
  content: "\e8b5";
}
.icon-accessibility1:before {
  content: "\e84e";
}
.icon-accessible:before {
  content: "\e914";
}
.icon-account_balance:before {
  content: "\e84f";
}
.icon-account_balance_wallet:before {
  content: "\e850";
}
.icon-account_box:before {
  content: "\e851";
}
.icon-account_circle:before {
  content: "\e853";
}
.icon-adb:before {
  content: "\e60e";
}
.icon-add:before {
  content: "\e145";
}
.icon-add_a_photo:before {
  content: "\e439";
}
.icon-alarm_add:before {
  content: "\e856";
}
.icon-add_alert:before {
  content: "\e003";
}
.icon-add_box:before {
  content: "\e146";
}
.icon-add_circle:before {
  content: "\e147";
}
.icon-control_point:before {
  content: "\e3ba";
}
.icon-add_location:before {
  content: "\e567";
}
.icon-add_shopping_cart:before {
  content: "\e854";
}
.icon-queue:before {
  content: "\e03c";
}
.icon-add_to_queue:before {
  content: "\e05c";
}
.icon-adjust:before {
  content: "\e39e";
}
.icon-airline_seat_flat:before {
  content: "\e630";
}
.icon-airline_seat_flat_angled:before {
  content: "\e631";
}
.icon-airline_seat_individual_suite:before {
  content: "\e632";
}
.icon-airline_seat_legroom_extra:before {
  content: "\e633";
}
.icon-airline_seat_legroom_normal:before {
  content: "\e634";
}
.icon-airline_seat_legroom_reduced:before {
  content: "\e635";
}
.icon-airline_seat_recline_extra:before {
  content: "\e636";
}
.icon-airline_seat_recline_normal:before {
  content: "\e637";
}
.icon-flight:before {
  content: "\e539";
}
.icon-airplanemode_inactive:before {
  content: "\e194";
}
.icon-airplay:before {
  content: "\e055";
}
.icon-airport_shuttle:before {
  content: "\eb3c";
}
.icon-alarm_off:before {
  content: "\e857";
}
.icon-alarm_on:before {
  content: "\e858";
}
.icon-album:before {
  content: "\e019";
}
.icon-all_inclusive:before {
  content: "\eb3d";
}
.icon-all_out:before {
  content: "\e90b";
}
.icon-android1:before {
  content: "\e859";
}
.icon-announcement:before {
  content: "\e85a";
}
.icon-apps:before {
  content: "\e5c3";
}
.icon-archive:before {
  content: "\e149";
}
.icon-arrow_back:before {
  content: "\e5c4";
}
.icon-arrow_downward:before {
  content: "\e5db";
}
.icon-arrow_drop_down:before {
  content: "\e5c5";
}
.icon-arrow_drop_down_circle:before {
  content: "\e5c6";
}
.icon-arrow_drop_up:before {
  content: "\e5c7";
}
.icon-arrow_forward:before {
  content: "\e5c8";
}
.icon-arrow_upward:before {
  content: "\e5d8";
}
.icon-art_track:before {
  content: "\e060";
}
.icon-aspect_ratio:before {
  content: "\e85b";
}
.icon-poll:before {
  content: "\e801";
}
.icon-assignment:before {
  content: "\e85d";
}
.icon-assignment_ind:before {
  content: "\e85e";
}
.icon-assignment_late:before {
  content: "\e85f";
}
.icon-assignment_return:before {
  content: "\e860";
}
.icon-assignment_returned:before {
  content: "\e861";
}
.icon-assignment_turned_in:before {
  content: "\e862";
}
.icon-assistant:before {
  content: "\e39f";
}
.icon-flag1:before {
  content: "\e153";
}
.icon-attach_file:before {
  content: "\e226";
}
.icon-attach_money:before {
  content: "\e227";
}
.icon-attachment1:before {
  content: "\e2bc";
}
.icon-audiotrack:before {
  content: "\e3a1";
}
.icon-autorenew:before {
  content: "\e863";
}
.icon-av_timer:before {
  content: "\e01b";
}
.icon-backspace:before {
  content: "\e14a";
}
.icon-cloud_upload:before {
  content: "\e2c3";
}
.icon-battery_alert:before {
  content: "\e19c";
}
.icon-battery_charging_full:before {
  content: "\e1a3";
}
.icon-battery_std:before {
  content: "\e1a5";
}
.icon-battery_unknown:before {
  content: "\e1a6";
}
.icon-beach_access:before {
  content: "\eb3e";
}
.icon-beenhere:before {
  content: "\e52d";
}
.icon-block:before {
  content: "\e14b";
}
.icon-bluetooth:before {
  content: "\e1a7";
}
.icon-bluetooth_searching:before {
  content: "\e1aa";
}
.icon-bluetooth_connected:before {
  content: "\e1a8";
}
.icon-bluetooth_disabled:before {
  content: "\e1a9";
}
.icon-blur_circular:before {
  content: "\e3a2";
}
.icon-blur_linear:before {
  content: "\e3a3";
}
.icon-blur_off:before {
  content: "\e3a4";
}
.icon-blur_on:before {
  content: "\e3a5";
}
.icon-class:before {
  content: "\e86e";
}
.icon-turned_in:before {
  content: "\e8e6";
}
.icon-turned_in_not:before {
  content: "\e8e7";
}
.icon-border_all:before {
  content: "\e228";
}
.icon-border_bottom:before {
  content: "\e229";
}
.icon-border_clear:before {
  content: "\e22a";
}
.icon-border_color:before {
  content: "\e22b";
}
.icon-border_horizontal:before {
  content: "\e22c";
}
.icon-border_inner:before {
  content: "\e22d";
}
.icon-border_left:before {
  content: "\e22e";
}
.icon-border_outer:before {
  content: "\e22f";
}
.icon-border_right:before {
  content: "\e230";
}
.icon-border_style:before {
  content: "\e231";
}
.icon-border_top:before {
  content: "\e232";
}
.icon-border_vertical:before {
  content: "\e233";
}
.icon-branding_watermark:before {
  content: "\e06b";
}
.icon-brightness_1:before {
  content: "\e3a6";
}
.icon-brightness_2:before {
  content: "\e3a7";
}
.icon-brightness_3:before {
  content: "\e3a8";
}
.icon-brightness_4:before {
  content: "\e3a9";
}
.icon-brightness_low:before {
  content: "\e1ad";
}
.icon-brightness_medium:before {
  content: "\e1ae";
}
.icon-brightness_high:before {
  content: "\e1ac";
}
.icon-brightness_auto:before {
  content: "\e1ab";
}
.icon-broken_image:before {
  content: "\e3ad";
}
.icon-brush:before {
  content: "\e3ae";
}
.icon-bubble_chart:before {
  content: "\e6dd";
}
.icon-bug_report:before {
  content: "\e868";
}
.icon-build:before {
  content: "\e869";
}
.icon-burst_mode:before {
  content: "\e43c";
}
.icon-domain:before {
  content: "\e7ee";
}
.icon-business_center:before {
  content: "\eb3f";
}
.icon-cached:before {
  content: "\e86a";
}
.icon-cake:before {
  content: "\e7e9";
}
.icon-phone1:before {
  content: "\e0cd";
}
.icon-call_end:before {
  content: "\e0b1";
}
.icon-call_made:before {
  content: "\e0b2";
}
.icon-merge_type:before {
  content: "\e252";
}
.icon-call_missed:before {
  content: "\e0b4";
}
.icon-call_missed_outgoing:before {
  content: "\e0e4";
}
.icon-call_received:before {
  content: "\e0b5";
}
.icon-call_split:before {
  content: "\e0b6";
}
.icon-call_to_action:before {
  content: "\e06c";
}
.icon-camera1:before {
  content: "\e3af";
}
.icon-photo_camera:before {
  content: "\e412";
}
.icon-camera_enhance:before {
  content: "\e8fc";
}
.icon-camera_front:before {
  content: "\e3b1";
}
.icon-camera_rear:before {
  content: "\e3b2";
}
.icon-camera_roll:before {
  content: "\e3b3";
}
.icon-cancel:before {
  content: "\e5c9";
}
.icon-redeem:before {
  content: "\e8b1";
}
.icon-card_membership:before {
  content: "\e8f7";
}
.icon-card_travel:before {
  content: "\e8f8";
}
.icon-casino:before {
  content: "\eb40";
}
.icon-cast:before {
  content: "\e307";
}
.icon-cast_connected:before {
  content: "\e308";
}
.icon-center_focus_strong:before {
  content: "\e3b4";
}
.icon-center_focus_weak:before {
  content: "\e3b5";
}
.icon-change_history:before {
  content: "\e86b";
}
.icon-chat:before {
  content: "\e0b7";
}
.icon-chat_bubble:before {
  content: "\e0ca";
}
.icon-chat_bubble_outline:before {
  content: "\e0cb";
}
.icon-check:before {
  content: "\e5ca";
}
.icon-check_box:before {
  content: "\e834";
}
.icon-check_box_outline_blank:before {
  content: "\e835";
}
.icon-check_circle:before {
  content: "\e86c";
}
.icon-navigate_before:before {
  content: "\e408";
}
.icon-navigate_next:before {
  content: "\e409";
}
.icon-child_care:before {
  content: "\eb41";
}
.icon-child_friendly:before {
  content: "\eb42";
}
.icon-chrome_reader_mode:before {
  content: "\e86d";
}
.icon-close:before {
  content: "\e5cd";
}
.icon-clear_all:before {
  content: "\e0b8";
}
.icon-closed_caption:before {
  content: "\e01c";
}
.icon-wb_cloudy:before {
  content: "\e42d";
}
.icon-cloud_circle:before {
  content: "\e2be";
}
.icon-cloud_done:before {
  content: "\e2bf";
}
.icon-cloud_download:before {
  content: "\e2c0";
}
.icon-cloud_off:before {
  content: "\e2c1";
}
.icon-cloud_queue:before {
  content: "\e2c2";
}
.icon-code:before {
  content: "\e86f";
}
.icon-photo_library:before {
  content: "\e413";
}
.icon-collections_bookmark:before {
  content: "\e431";
}
.icon-palette:before {
  content: "\e40a";
}
.icon-colorize:before {
  content: "\e3b8";
}
.icon-comment:before {
  content: "\e0b9";
}
.icon-compare:before {
  content: "\e3b9";
}
.icon-compare_arrows:before {
  content: "\e915";
}
.icon-laptop1:before {
  content: "\e31e";
}
.icon-confirmation_number:before {
  content: "\e638";
}
.icon-contact_mail:before {
  content: "\e0d0";
}
.icon-contact_phone:before {
  content: "\e0cf";
}
.icon-contacts:before {
  content: "\e0ba";
}
.icon-content_copy:before {
  content: "\e14d";
}
.icon-content_cut:before {
  content: "\e14e";
}
.icon-content_paste:before {
  content: "\e14f";
}
.icon-control_point_duplicate:before {
  content: "\e3bb";
}
.icon-copyright:before {
  content: "\e90c";
}
.icon-mode_edit:before {
  content: "\e254";
}
.icon-create_new_folder:before {
  content: "\e2cc";
}
.icon-payment:before {
  content: "\e8a1";
}
.icon-crop1:before {
  content: "\e3be";
}
.icon-crop_16_9:before {
  content: "\e3bc";
}
.icon-crop_3_2:before {
  content: "\e3bd";
}
.icon-crop_landscape:before {
  content: "\e3c3";
}
.icon-crop_7_5:before {
  content: "\e3c0";
}
.icon-crop_din:before {
  content: "\e3c1";
}
.icon-crop_free:before {
  content: "\e3c2";
}
.icon-crop_original:before {
  content: "\e3c4";
}
.icon-crop_portrait:before {
  content: "\e3c5";
}
.icon-crop_rotate:before {
  content: "\e437";
}
.icon-crop_square:before {
  content: "\e3c6";
}
.icon-dashboard:before {
  content: "\e871";
}
.icon-data_usage:before {
  content: "\e1af";
}
.icon-date_range:before {
  content: "\e916";
}
.icon-dehaze:before {
  content: "\e3c7";
}
.icon-delete:before {
  content: "\e872";
}
.icon-delete_forever:before {
  content: "\e92b";
}
.icon-delete_sweep:before {
  content: "\e16c";
}
.icon-description:before {
  content: "\e873";
}
.icon-desktop_mac:before {
  content: "\e30b";
}
.icon-desktop_windows:before {
  content: "\e30c";
}
.icon-details:before {
  content: "\e3c8";
}
.icon-developer_board:before {
  content: "\e30d";
}
.icon-developer_mode:before {
  content: "\e1b0";
}
.icon-device_hub:before {
  content: "\e335";
}
.icon-phonelink:before {
  content: "\e326";
}
.icon-devices_other:before {
  content: "\e337";
}
.icon-dialer_sip:before {
  content: "\e0bb";
}
.icon-dialpad:before {
  content: "\e0bc";
}
.icon-directions:before {
  content: "\e52e";
}
.icon-directions_bike:before {
  content: "\e52f";
}
.icon-directions_boat:before {
  content: "\e532";
}
.icon-directions_bus:before {
  content: "\e530";
}
.icon-directions_car:before {
  content: "\e531";
}
.icon-directions_railway:before {
  content: "\e534";
}
.icon-directions_run:before {
  content: "\e566";
}
.icon-directions_transit:before {
  content: "\e535";
}
.icon-directions_walk:before {
  content: "\e536";
}
.icon-disc_full:before {
  content: "\e610";
}
.icon-dns:before {
  content: "\e875";
}
.icon-not_interested:before {
  content: "\e033";
}
.icon-do_not_disturb_alt:before {
  content: "\e611";
}
.icon-do_not_disturb_off:before {
  content: "\e643";
}
.icon-remove_circle:before {
  content: "\e15c";
}
.icon-dock:before {
  content: "\e30e";
}
.icon-done:before {
  content: "\e876";
}
.icon-done_all:before {
  content: "\e877";
}
.icon-donut_large:before {
  content: "\e917";
}
.icon-donut_small:before {
  content: "\e918";
}
.icon-drafts:before {
  content: "\e151";
}
.icon-drag_handle:before {
  content: "\e25d";
}
.icon-time_to_leave:before {
  content: "\e62c";
}
.icon-dvr:before {
  content: "\e1b2";
}
.icon-edit_location:before {
  content: "\e568";
}
.icon-eject1:before {
  content: "\e8fb";
}
.icon-markunread:before {
  content: "\e159";
}
.icon-enhanced_encryption:before {
  content: "\e63f";
}
.icon-equalizer1:before {
  content: "\e01d";
}
.icon-error:before {
  content: "\e000";
}
.icon-error_outline:before {
  content: "\e001";
}
.icon-euro_symbol:before {
  content: "\e926";
}
.icon-ev_station:before {
  content: "\e56d";
}
.icon-insert_invitation:before {
  content: "\e24f";
}
.icon-event_available:before {
  content: "\e614";
}
.icon-event_busy:before {
  content: "\e615";
}
.icon-event_note:before {
  content: "\e616";
}
.icon-event_seat:before {
  content: "\e903";
}
.icon-exit_to_app:before {
  content: "\e879";
}
.icon-expand_less:before {
  content: "\e5ce";
}
.icon-expand_more:before {
  content: "\e5cf";
}
.icon-explicit:before {
  content: "\e01e";
}
.icon-explore:before {
  content: "\e87a";
}
.icon-exposure:before {
  content: "\e3ca";
}
.icon-exposure_neg_1:before {
  content: "\e3cb";
}
.icon-exposure_neg_2:before {
  content: "\e3cc";
}
.icon-exposure_plus_1:before {
  content: "\e3cd";
}
.icon-exposure_plus_2:before {
  content: "\e3ce";
}
.icon-exposure_zero:before {
  content: "\e3cf";
}
.icon-extension:before {
  content: "\e87b";
}
.icon-face:before {
  content: "\e87c";
}
.icon-fast_forward:before {
  content: "\e01f";
}
.icon-fast_rewind:before {
  content: "\e020";
}
.icon-favorite:before {
  content: "\e87d";
}
.icon-favorite_border:before {
  content: "\e87e";
}
.icon-featured_play_list:before {
  content: "\e06d";
}
.icon-featured_video:before {
  content: "\e06e";
}
.icon-sms_failed:before {
  content: "\e626";
}
.icon-fiber_dvr:before {
  content: "\e05d";
}
.icon-fiber_manual_record:before {
  content: "\e061";
}
.icon-fiber_new:before {
  content: "\e05e";
}
.icon-fiber_pin:before {
  content: "\e06a";
}
.icon-fiber_smart_record:before {
  content: "\e062";
}
.icon-get_app:before {
  content: "\e884";
}
.icon-file_upload:before {
  content: "\e2c6";
}
.icon-filter1:before {
  content: "\e3d3";
}
.icon-filter_1:before {
  content: "\e3d0";
}
.icon-filter_2:before {
  content: "\e3d1";
}
.icon-filter_3:before {
  content: "\e3d2";
}
.icon-filter_4:before {
  content: "\e3d4";
}
.icon-filter_5:before {
  content: "\e3d5";
}
.icon-filter_6:before {
  content: "\e3d6";
}
.icon-filter_7:before {
  content: "\e3d7";
}
.icon-filter_8:before {
  content: "\e3d8";
}
.icon-filter_9:before {
  content: "\e3d9";
}
.icon-filter_9_plus:before {
  content: "\e3da";
}
.icon-filter_b_and_w:before {
  content: "\e3db";
}
.icon-filter_center_focus:before {
  content: "\e3dc";
}
.icon-filter_drama:before {
  content: "\e3dd";
}
.icon-filter_frames:before {
  content: "\e3de";
}
.icon-terrain:before {
  content: "\e564";
}
.icon-filter_list:before {
  content: "\e152";
}
.icon-filter_none:before {
  content: "\e3e0";
}
.icon-filter_tilt_shift:before {
  content: "\e3e2";
}
.icon-filter_vintage:before {
  content: "\e3e3";
}
.icon-find_in_page:before {
  content: "\e880";
}
.icon-find_replace:before {
  content: "\e881";
}
.icon-fingerprint:before {
  content: "\e90d";
}
.icon-first_page:before {
  content: "\e5dc";
}
.icon-fitness_center:before {
  content: "\eb43";
}
.icon-flare:before {
  content: "\e3e4";
}
.icon-flash_auto:before {
  content: "\e3e5";
}
.icon-flash_off:before {
  content: "\e3e6";
}
.icon-flash_on:before {
  content: "\e3e7";
}
.icon-flight_land:before {
  content: "\e904";
}
.icon-flight_takeoff:before {
  content: "\e905";
}
.icon-flip:before {
  content: "\e3e8";
}
.icon-flip_to_back:before {
  content: "\e882";
}
.icon-flip_to_front:before {
  content: "\e883";
}
.icon-folder1:before {
  content: "\e2c7";
}
.icon-folder_open:before {
  content: "\e2c8";
}
.icon-folder_shared:before {
  content: "\e2c9";
}
.icon-folder_special:before {
  content: "\e617";
}
.icon-font_download:before {
  content: "\e167";
}
.icon-format_align_center:before {
  content: "\e234";
}
.icon-format_align_justify:before {
  content: "\e235";
}
.icon-format_align_left:before {
  content: "\e236";
}
.icon-format_align_right:before {
  content: "\e237";
}
.icon-format_bold:before {
  content: "\e238";
}
.icon-format_clear:before {
  content: "\e239";
}
.icon-format_color_fill:before {
  content: "\e23a";
}
.icon-format_color_reset:before {
  content: "\e23b";
}
.icon-format_color_text:before {
  content: "\e23c";
}
.icon-format_indent_decrease:before {
  content: "\e23d";
}
.icon-format_indent_increase:before {
  content: "\e23e";
}
.icon-format_italic:before {
  content: "\e23f";
}
.icon-format_line_spacing:before {
  content: "\e240";
}
.icon-format_list_bulleted:before {
  content: "\e241";
}
.icon-format_list_numbered:before {
  content: "\e242";
}
.icon-format_paint:before {
  content: "\e243";
}
.icon-format_quote:before {
  content: "\e244";
}
.icon-format_shapes:before {
  content: "\e25e";
}
.icon-format_size:before {
  content: "\e245";
}
.icon-format_strikethrough:before {
  content: "\e246";
}
.icon-format_textdirection_l_to_r:before {
  content: "\e247";
}
.icon-format_textdirection_r_to_l:before {
  content: "\e248";
}
.icon-format_underlined:before {
  content: "\e249";
}
.icon-question_answer:before {
  content: "\e8af";
}
.icon-forward1:before {
  content: "\e154";
}
.icon-forward_10:before {
  content: "\e056";
}
.icon-forward_30:before {
  content: "\e057";
}
.icon-forward_5:before {
  content: "\e058";
}
.icon-free_breakfast:before {
  content: "\eb44";
}
.icon-fullscreen:before {
  content: "\e5d0";
}
.icon-fullscreen_exit:before {
  content: "\e5d1";
}
.icon-functions:before {
  content: "\e24a";
}
.icon-g_translate:before {
  content: "\e927";
}
.icon-games:before {
  content: "\e021";
}
.icon-gavel:before {
  content: "\e90e";
}
.icon-gesture:before {
  content: "\e155";
}
.icon-gif:before {
  content: "\e908";
}
.icon-goat:before {
  content: "\e901";
}
.icon-golf_course:before {
  content: "\eb45";
}
.icon-my_location:before {
  content: "\e55c";
}
.icon-location_searching:before {
  content: "\e1b7";
}
.icon-location_disabled:before {
  content: "\e1b6";
}
.icon-star:before {
  content: "\e838";
}
.icon-gradient:before {
  content: "\e3e9";
}
.icon-grain:before {
  content: "\e3ea";
}
.icon-graphic_eq:before {
  content: "\e1b8";
}
.icon-grid_off:before {
  content: "\e3eb";
}
.icon-grid_on:before {
  content: "\e3ec";
}
.icon-people:before {
  content: "\e7fb";
}
.icon-group_add:before {
  content: "\e7f0";
}
.icon-group_work:before {
  content: "\e886";
}
.icon-hd:before {
  content: "\e052";
}
.icon-hdr_off:before {
  content: "\e3ed";
}
.icon-hdr_on:before {
  content: "\e3ee";
}
.icon-hdr_strong:before {
  content: "\e3f1";
}
.icon-hdr_weak:before {
  content: "\e3f2";
}
.icon-headset:before {
  content: "\e310";
}
.icon-headset_mic:before {
  content: "\e311";
}
.icon-healing:before {
  content: "\e3f3";
}
.icon-hearing:before {
  content: "\e023";
}
.icon-help:before {
  content: "\e887";
}
.icon-help_outline:before {
  content: "\e8fd";
}
.icon-high_quality:before {
  content: "\e024";
}
.icon-highlight:before {
  content: "\e25f";
}
.icon-highlight_off:before {
  content: "\e888";
}
.icon-restore:before {
  content: "\e8b3";
}
.icon-home1:before {
  content: "\e88a";
}
.icon-hot_tub:before {
  content: "\eb46";
}
.icon-local_hotel:before {
  content: "\e549";
}
.icon-hourglass_empty:before {
  content: "\e88b";
}
.icon-hourglass_full:before {
  content: "\e88c";
}
.icon-http:before {
  content: "\e902";
}
.icon-lock1:before {
  content: "\e897";
}
.icon-photo:before {
  content: "\e410";
}
.icon-image_aspect_ratio:before {
  content: "\e3f5";
}
.icon-import_contacts:before {
  content: "\e0e0";
}
.icon-import_export:before {
  content: "\e0c3";
}
.icon-important_devices:before {
  content: "\e912";
}
.icon-inbox:before {
  content: "\e156";
}
.icon-indeterminate_check_box:before {
  content: "\e909";
}
.icon-info1:before {
  content: "\e88e";
}
.icon-info_outline:before {
  content: "\e88f";
}
.icon-input:before {
  content: "\e890";
}
.icon-insert_comment:before {
  content: "\e24c";
}
.icon-insert_drive_file:before {
  content: "\e24d";
}
.icon-tag_faces:before {
  content: "\e420";
}
.icon-link1:before {
  content: "\e157";
}
.icon-invert_colors:before {
  content: "\e891";
}
.icon-invert_colors_off:before {
  content: "\e0c4";
}
.icon-iso:before {
  content: "\e3f6";
}
.icon-keyboard1:before {
  content: "\e312";
}
.icon-keyboard_arrow_down:before {
  content: "\e313";
}
.icon-keyboard_arrow_left:before {
  content: "\e314";
}
.icon-keyboard_arrow_right:before {
  content: "\e315";
}
.icon-keyboard_arrow_up:before {
  content: "\e316";
}
.icon-keyboard_backspace:before {
  content: "\e317";
}
.icon-keyboard_capslock:before {
  content: "\e318";
}
.icon-keyboard_hide:before {
  content: "\e31a";
}
.icon-keyboard_return:before {
  content: "\e31b";
}
.icon-keyboard_tab:before {
  content: "\e31c";
}
.icon-keyboard_voice:before {
  content: "\e31d";
}
.icon-kitchen:before {
  content: "\eb47";
}
.icon-label:before {
  content: "\e892";
}
.icon-label_outline:before {
  content: "\e893";
}
.icon-language:before {
  content: "\e894";
}
.icon-laptop_chromebook:before {
  content: "\e31f";
}
.icon-laptop_mac:before {
  content: "\e320";
}
.icon-laptop_windows:before {
  content: "\e321";
}
.icon-last_page:before {
  content: "\e5dd";
}
.icon-open_in_new:before {
  content: "\e89e";
}
.icon-layers:before {
  content: "\e53b";
}
.icon-layers_clear:before {
  content: "\e53c";
}
.icon-leak_add:before {
  content: "\e3f8";
}
.icon-leak_remove:before {
  content: "\e3f9";
}
.icon-lens:before {
  content: "\e3fa";
}
.icon-library_books:before {
  content: "\e02f";
}
.icon-library_music:before {
  content: "\e030";
}
.icon-lightbulb_outline:before {
  content: "\e90f";
}
.icon-line_style:before {
  content: "\e919";
}
.icon-line_weight:before {
  content: "\e91a";
}
.icon-linear_scale:before {
  content: "\e260";
}
.icon-linked_camera:before {
  content: "\e438";
}
.icon-list1:before {
  content: "\e896";
}
.icon-live_help:before {
  content: "\e0c6";
}
.icon-live_tv:before {
  content: "\e639";
}
.icon-local_play:before {
  content: "\e553";
}
.icon-local_airport:before {
  content: "\e53d";
}
.icon-local_atm:before {
  content: "\e53e";
}
.icon-local_bar:before {
  content: "\e540";
}
.icon-local_cafe:before {
  content: "\e541";
}
.icon-local_car_wash:before {
  content: "\e542";
}
.icon-local_convenience_store:before {
  content: "\e543";
}
.icon-restaurant_menu:before {
  content: "\e561";
}
.icon-local_drink:before {
  content: "\e544";
}
.icon-local_florist:before {
  content: "\e545";
}
.icon-local_gas_station:before {
  content: "\e546";
}
.icon-shopping_cart:before {
  content: "\e8cc";
}
.icon-local_hospital:before {
  content: "\e548";
}
.icon-local_laundry_service:before {
  content: "\e54a";
}
.icon-local_library:before {
  content: "\e54b";
}
.icon-local_mall:before {
  content: "\e54c";
}
.icon-theaters:before {
  content: "\e8da";
}
.icon-local_offer:before {
  content: "\e54e";
}
.icon-local_parking:before {
  content: "\e54f";
}
.icon-local_pharmacy:before {
  content: "\e550";
}
.icon-local_pizza:before {
  content: "\e552";
}
.icon-print:before {
  content: "\e8ad";
}
.icon-local_shipping:before {
  content: "\e558";
}
.icon-local_taxi:before {
  content: "\e559";
}
.icon-location_city:before {
  content: "\e7f1";
}
.icon-location_off:before {
  content: "\e0c7";
}
.icon-room:before {
  content: "\e8b4";
}
.icon-lock_open:before {
  content: "\e898";
}
.icon-lock_outline:before {
  content: "\e899";
}
.icon-looks:before {
  content: "\e3fc";
}
.icon-looks_3:before {
  content: "\e3fb";
}
.icon-looks_4:before {
  content: "\e3fd";
}
.icon-looks_5:before {
  content: "\e3fe";
}
.icon-looks_6:before {
  content: "\e3ff";
}
.icon-looks_one:before {
  content: "\e400";
}
.icon-looks_two:before {
  content: "\e401";
}
.icon-sync:before {
  content: "\e627";
}
.icon-loupe:before {
  content: "\e402";
}
.icon-low_priority:before {
  content: "\e16d";
}
.icon-loyalty:before {
  content: "\e89a";
}
.icon-mail_outline:before {
  content: "\e0e1";
}
.icon-map1:before {
  content: "\e55b";
}
.icon-markunread_mailbox:before {
  content: "\e89b";
}
.icon-memory:before {
  content: "\e322";
}
.icon-menu1:before {
  content: "\e5d2";
}
.icon-message:before {
  content: "\e0c9";
}
.icon-mic1:before {
  content: "\e029";
}
.icon-mic_none:before {
  content: "\e02a";
}
.icon-mic_off:before {
  content: "\e02b";
}
.icon-mms:before {
  content: "\e618";
}
.icon-mode_comment:before {
  content: "\e253";
}
.icon-monetization_on:before {
  content: "\e263";
}
.icon-money_off:before {
  content: "\e25c";
}
.icon-monochrome_photos:before {
  content: "\e403";
}
.icon-mood_bad:before {
  content: "\e7f3";
}
.icon-more:before {
  content: "\e619";
}
.icon-more_horiz:before {
  content: "\e5d3";
}
.icon-more_vert:before {
  content: "\e5d4";
}
.icon-motorcycle:before {
  content: "\e91b";
}
.icon-mouse:before {
  content: "\e323";
}
.icon-move_to_inbox:before {
  content: "\e168";
}
.icon-movie_creation:before {
  content: "\e404";
}
.icon-movie_filter:before {
  content: "\e43a";
}
.icon-multiline_chart:before {
  content: "\e6df";
}
.icon-music_note:before {
  content: "\e405";
}
.icon-music_video:before {
  content: "\e063";
}
.icon-nature:before {
  content: "\e406";
}
.icon-nature_people:before {
  content: "\e407";
}
.icon-navigation:before {
  content: "\e55d";
}
.icon-near_me:before {
  content: "\e569";
}
.icon-network_cell:before {
  content: "\e1b9";
}
.icon-network_check:before {
  content: "\e640";
}
.icon-network_locked:before {
  content: "\e61a";
}
.icon-network_wifi:before {
  content: "\e1ba";
}
.icon-new_releases:before {
  content: "\e031";
}
.icon-next_week:before {
  content: "\e16a";
}
.icon-nfc:before {
  content: "\e1bb";
}
.icon-no_encryption:before {
  content: "\e641";
}
.icon-signal_cellular_no_sim:before {
  content: "\e1ce";
}
.icon-note:before {
  content: "\e06f";
}
.icon-note_add:before {
  content: "\e89c";
}
.icon-notifications:before {
  content: "\e7f4";
}
.icon-notifications_active:before {
  content: "\e7f7";
}
.icon-notifications_none:before {
  content: "\e7f5";
}
.icon-notifications_off:before {
  content: "\e7f6";
}
.icon-notifications_paused:before {
  content: "\e7f8";
}
.icon-offline_pin:before {
  content: "\e90a";
}
.icon-ondemand_video:before {
  content: "\e63a";
}
.icon-opacity:before {
  content: "\e91c";
}
.icon-open_in_browser:before {
  content: "\e89d";
}
.icon-open_with:before {
  content: "\e89f";
}
.icon-pages:before {
  content: "\e7f9";
}
.icon-pageview:before {
  content: "\e8a0";
}
.icon-pan_tool:before {
  content: "\e925";
}
.icon-panorama:before {
  content: "\e40b";
}
.icon-radio_button_unchecked:before {
  content: "\e836";
}
.icon-panorama_horizontal:before {
  content: "\e40d";
}
.icon-panorama_vertical:before {
  content: "\e40e";
}
.icon-panorama_wide_angle:before {
  content: "\e40f";
}
.icon-party_mode:before {
  content: "\e7fa";
}
.icon-pause1:before {
  content: "\e034";
}
.icon-pause_circle_filled:before {
  content: "\e035";
}
.icon-pause_circle_outline:before {
  content: "\e036";
}
.icon-people_outline:before {
  content: "\e7fc";
}
.icon-perm_camera_mic:before {
  content: "\e8a2";
}
.icon-perm_contact_calendar:before {
  content: "\e8a3";
}
.icon-perm_data_setting:before {
  content: "\e8a4";
}
.icon-perm_device_information:before {
  content: "\e8a5";
}
.icon-person_outline:before {
  content: "\e7ff";
}
.icon-perm_media:before {
  content: "\e8a7";
}
.icon-perm_phone_msg:before {
  content: "\e8a8";
}
.icon-perm_scan_wifi:before {
  content: "\e8a9";
}
.icon-person:before {
  content: "\e7fd";
}
.icon-person_add:before {
  content: "\e7fe";
}
.icon-person_pin:before {
  content: "\e55a";
}
.icon-person_pin_circle:before {
  content: "\e56a";
}
.icon-personal_video:before {
  content: "\e63b";
}
.icon-pets:before {
  content: "\e91d";
}
.icon-phone_android:before {
  content: "\e324";
}
.icon-phone_bluetooth_speaker:before {
  content: "\e61b";
}
.icon-phone_forwarded:before {
  content: "\e61c";
}
.icon-phone_in_talk:before {
  content: "\e61d";
}
.icon-phone_iphone:before {
  content: "\e325";
}
.icon-phone_locked:before {
  content: "\e61e";
}
.icon-phone_missed:before {
  content: "\e61f";
}
.icon-phone_paused:before {
  content: "\e620";
}
.icon-phonelink_erase:before {
  content: "\e0db";
}
.icon-phonelink_lock:before {
  content: "\e0dc";
}
.icon-phonelink_off:before {
  content: "\e327";
}
.icon-phonelink_ring:before {
  content: "\e0dd";
}
.icon-phonelink_setup:before {
  content: "\e0de";
}
.icon-photo_album:before {
  content: "\e411";
}
.icon-photo_filter:before {
  content: "\e43b";
}
.icon-photo_size_select_actual:before {
  content: "\e432";
}
.icon-photo_size_select_large:before {
  content: "\e433";
}
.icon-photo_size_select_small:before {
  content: "\e434";
}
.icon-picture_as_pdf:before {
  content: "\e415";
}
.icon-picture_in_picture:before {
  content: "\e8aa";
}
.icon-picture_in_picture_alt:before {
  content: "\e911";
}
.icon-pie_chart:before {
  content: "\e6c4";
}
.icon-pie_chart_outlined:before {
  content: "\e6c5";
}
.icon-pin_drop:before {
  content: "\e55e";
}
.icon-play_arrow:before {
  content: "\e037";
}
.icon-play_circle_filled:before {
  content: "\e038";
}
.icon-play_circle_outline:before {
  content: "\e039";
}
.icon-play_for_work:before {
  content: "\e906";
}
.icon-playlist_add:before {
  content: "\e03b";
}
.icon-playlist_add_check:before {
  content: "\e065";
}
.icon-playlist_play:before {
  content: "\e05f";
}
.icon-plus_one:before {
  content: "\e800";
}
.icon-polymer:before {
  content: "\e8ab";
}
.icon-pool:before {
  content: "\eb48";
}
.icon-portable_wifi_off:before {
  content: "\e0ce";
}
.icon-portrait:before {
  content: "\e416";
}
.icon-power1:before {
  content: "\e63c";
}
.icon-power_input:before {
  content: "\e336";
}
.icon-power_settings_new:before {
  content: "\e8ac";
}
.icon-pregnant_woman:before {
  content: "\e91e";
}
.icon-present_to_all:before {
  content: "\e0df";
}
.icon-priority_high:before {
  content: "\e645";
}
.icon-public:before {
  content: "\e80b";
}
.icon-publish:before {
  content: "\e255";
}
.icon-queue_music:before {
  content: "\e03d";
}
.icon-queue_play_next:before {
  content: "\e066";
}
.icon-radio:before {
  content: "\e03e";
}
.icon-radio_button_checked:before {
  content: "\e837";
}
.icon-rate_review:before {
  content: "\e560";
}
.icon-receipt:before {
  content: "\e8b0";
}
.icon-recent_actors:before {
  content: "\e03f";
}
.icon-record_voice_over:before {
  content: "\e91f";
}
.icon-redo1:before {
  content: "\e15a";
}
.icon-refresh:before {
  content: "\e5d5";
}
.icon-remove:before {
  content: "\e15b";
}
.icon-remove_circle_outline:before {
  content: "\e15d";
}
.icon-remove_from_queue:before {
  content: "\e067";
}
.icon-visibility:before {
  content: "\e8f4";
}
.icon-remove_shopping_cart:before {
  content: "\e928";
}
.icon-reorder:before {
  content: "\e8fe";
}
.icon-repeat:before {
  content: "\e040";
}
.icon-repeat_one:before {
  content: "\e041";
}
.icon-replay:before {
  content: "\e042";
}
.icon-replay_10:before {
  content: "\e059";
}
.icon-replay_30:before {
  content: "\e05a";
}
.icon-replay_5:before {
  content: "\e05b";
}
.icon-reply1:before {
  content: "\e15e";
}
.icon-reply_all:before {
  content: "\e15f";
}
.icon-report:before {
  content: "\e160";
}
.icon-warning1:before {
  content: "\e002";
}
.icon-restaurant:before {
  content: "\e56c";
}
.icon-restore_page:before {
  content: "\e929";
}
.icon-ring_volume:before {
  content: "\e0d1";
}
.icon-room_service:before {
  content: "\eb49";
}
.icon-rotate_90_degrees_ccw:before {
  content: "\e418";
}
.icon-rotate_left:before {
  content: "\e419";
}
.icon-rotate_right:before {
  content: "\e41a";
}
.icon-rounded_corner:before {
  content: "\e920";
}
.icon-router:before {
  content: "\e328";
}
.icon-rowing:before {
  content: "\e921";
}
.icon-rss_feed:before {
  content: "\e0e5";
}
.icon-rv_hookup:before {
  content: "\e642";
}
.icon-satellite:before {
  content: "\e562";
}
.icon-save:before {
  content: "\e161";
}
.icon-scanner:before {
  content: "\e329";
}
.icon-school:before {
  content: "\e80c";
}
.icon-screen_lock_landscape:before {
  content: "\e1be";
}
.icon-screen_lock_portrait:before {
  content: "\e1bf";
}
.icon-screen_lock_rotation:before {
  content: "\e1c0";
}
.icon-screen_rotation:before {
  content: "\e1c1";
}
.icon-screen_share:before {
  content: "\e0e2";
}
.icon-sd_storage:before {
  content: "\e1c2";
}
.icon-search1:before {
  content: "\e8b6";
}
.icon-security:before {
  content: "\e32a";
}
.icon-select_all:before {
  content: "\e162";
}
.icon-send:before {
  content: "\e163";
}
.icon-sentiment_dissatisfied:before {
  content: "\e811";
}
.icon-sentiment_neutral:before {
  content: "\e812";
}
.icon-sentiment_satisfied:before {
  content: "\e813";
}
.icon-sentiment_very_dissatisfied:before {
  content: "\e814";
}
.icon-sentiment_very_satisfied:before {
  content: "\e815";
}
.icon-settings:before {
  content: "\e8b8";
}
.icon-settings_applications:before {
  content: "\e8b9";
}
.icon-settings_backup_restore:before {
  content: "\e8ba";
}
.icon-settings_bluetooth:before {
  content: "\e8bb";
}
.icon-settings_brightness:before {
  content: "\e8bd";
}
.icon-settings_cell:before {
  content: "\e8bc";
}
.icon-settings_ethernet:before {
  content: "\e8be";
}
.icon-settings_input_antenna:before {
  content: "\e8bf";
}
.icon-settings_input_composite:before {
  content: "\e8c1";
}
.icon-settings_input_hdmi:before {
  content: "\e8c2";
}
.icon-settings_input_svideo:before {
  content: "\e8c3";
}
.icon-settings_overscan:before {
  content: "\e8c4";
}
.icon-settings_phone:before {
  content: "\e8c5";
}
.icon-settings_power:before {
  content: "\e8c6";
}
.icon-settings_remote:before {
  content: "\e8c7";
}
.icon-settings_system_daydream:before {
  content: "\e1c3";
}
.icon-settings_voice:before {
  content: "\e8c8";
}
.icon-share1:before {
  content: "\e80d";
}
.icon-shop:before {
  content: "\e8c9";
}
.icon-shop_two:before {
  content: "\e8ca";
}
.icon-shopping_basket:before {
  content: "\e8cb";
}
.icon-short_text:before {
  content: "\e261";
}
.icon-show_chart:before {
  content: "\e6e1";
}
.icon-shuffle1:before {
  content: "\e043";
}
.icon-signal_cellular_4_bar:before {
  content: "\e1c8";
}
.icon-signal_cellular_connected_no_internet_4_bar:before {
  content: "\e1cd";
}
.icon-signal_cellular_null:before {
  content: "\e1cf";
}
.icon-signal_cellular_off:before {
  content: "\e1d0";
}
.icon-signal_wifi_4_bar:before {
  content: "\e1d8";
}
.icon-signal_wifi_4_bar_lock:before {
  content: "\e1d9";
}
.icon-signal_wifi_off:before {
  content: "\e1da";
}
.icon-sim_card:before {
  content: "\e32b";
}
.icon-sim_card_alert:before {
  content: "\e624";
}
.icon-skip_next:before {
  content: "\e044";
}
.icon-skip_previous:before {
  content: "\e045";
}
.icon-slideshow:before {
  content: "\e41b";
}
.icon-slow_motion_video:before {
  content: "\e068";
}
.icon-stay_primary_portrait:before {
  content: "\e0d6";
}
.icon-smoke_free:before {
  content: "\eb4a";
}
.icon-smoking_rooms:before {
  content: "\eb4b";
}
.icon-textsms:before {
  content: "\e0d8";
}
.icon-snooze:before {
  content: "\e046";
}
.icon-sort:before {
  content: "\e164";
}
.icon-sort_by_alpha:before {
  content: "\e053";
}
.icon-spa:before {
  content: "\eb4c";
}
.icon-space_bar:before {
  content: "\e256";
}
.icon-speaker:before {
  content: "\e32d";
}
.icon-speaker_group:before {
  content: "\e32e";
}
.icon-speaker_notes:before {
  content: "\e8cd";
}
.icon-speaker_notes_off:before {
  content: "\e92a";
}
.icon-speaker_phone:before {
  content: "\e0d2";
}
.icon-spellcheck:before {
  content: "\e8ce";
}
.icon-star_border:before {
  content: "\e83a";
}
.icon-star_half:before {
  content: "\e839";
}
.icon-stars:before {
  content: "\e8d0";
}
.icon-stay_primary_landscape:before {
  content: "\e0d5";
}
.icon-stop1:before {
  content: "\e047";
}
.icon-stop_screen_share:before {
  content: "\e0e3";
}
.icon-storage:before {
  content: "\e1db";
}
.icon-store_mall_directory:before {
  content: "\e563";
}
.icon-straighten:before {
  content: "\e41c";
}
.icon-streetview:before {
  content: "\e56e";
}
.icon-strikethrough_s:before {
  content: "\e257";
}
.icon-style:before {
  content: "\e41d";
}
.icon-subdirectory_arrow_left:before {
  content: "\e5d9";
}
.icon-subdirectory_arrow_right:before {
  content: "\e5da";
}
.icon-subject:before {
  content: "\e8d2";
}
.icon-subscriptions:before {
  content: "\e064";
}
.icon-subtitles:before {
  content: "\e048";
}
.icon-subway:before {
  content: "\e56f";
}
.icon-supervisor_account:before {
  content: "\e8d3";
}
.icon-surround_sound:before {
  content: "\e049";
}
.icon-swap_calls:before {
  content: "\e0d7";
}
.icon-swap_horiz:before {
  content: "\e8d4";
}
.icon-swap_vert:before {
  content: "\e8d5";
}
.icon-swap_vertical_circle:before {
  content: "\e8d6";
}
.icon-switch_camera:before {
  content: "\e41e";
}
.icon-switch_video:before {
  content: "\e41f";
}
.icon-sync_disabled:before {
  content: "\e628";
}
.icon-sync_problem:before {
  content: "\e629";
}
.icon-system_update:before {
  content: "\e62a";
}
.icon-system_update_alt:before {
  content: "\e8d7";
}
.icon-tab1:before {
  content: "\e8d8";
}
.icon-tab_unselected:before {
  content: "\e8d9";
}
.icon-tablet1:before {
  content: "\e32f";
}
.icon-tablet_android:before {
  content: "\e330";
}
.icon-tablet_mac:before {
  content: "\e331";
}
.icon-tap_and_play:before {
  content: "\e62b";
}
.icon-text_fields:before {
  content: "\e262";
}
.icon-text_format:before {
  content: "\e165";
}
.icon-texture:before {
  content: "\e421";
}
.icon-thumb_down:before {
  content: "\e8db";
}
.icon-thumb_up:before {
  content: "\e8dc";
}
.icon-thumbs_up_down:before {
  content: "\e8dd";
}
.icon-timelapse:before {
  content: "\e422";
}
.icon-timeline:before {
  content: "\e922";
}
.icon-timer:before {
  content: "\e425";
}
.icon-timer_10:before {
  content: "\e423";
}
.icon-timer_3:before {
  content: "\e424";
}
.icon-timer_off:before {
  content: "\e426";
}
.icon-title:before {
  content: "\e264";
}
.icon-toc:before {
  content: "\e8de";
}
.icon-today:before {
  content: "\e8df";
}
.icon-toll:before {
  content: "\e8e0";
}
.icon-tonality:before {
  content: "\e427";
}
.icon-touch_app:before {
  content: "\e913";
}
.icon-toys:before {
  content: "\e332";
}
.icon-track_changes:before {
  content: "\e8e1";
}
.icon-traffic:before {
  content: "\e565";
}
.icon-train:before {
  content: "\e570";
}
.icon-tram:before {
  content: "\e571";
}
.icon-transfer_within_a_station:before {
  content: "\e572";
}
.icon-transform:before {
  content: "\e428";
}
.icon-translate:before {
  content: "\e8e2";
}
.icon-trending_down:before {
  content: "\e8e3";
}
.icon-trending_flat:before {
  content: "\e8e4";
}
.icon-trending_up:before {
  content: "\e8e5";
}
.icon-tune:before {
  content: "\e429";
}
.icon-tv1:before {
  content: "\e333";
}
.icon-unarchive:before {
  content: "\e169";
}
.icon-undo1:before {
  content: "\e166";
}
.icon-unfold_less:before {
  content: "\e5d6";
}
.icon-unfold_more:before {
  content: "\e5d7";
}
.icon-update:before {
  content: "\e923";
}
.icon-usb:before {
  content: "\e1e0";
}
.icon-verified_user:before {
  content: "\e8e8";
}
.icon-vertical_align_bottom:before {
  content: "\e258";
}
.icon-vertical_align_center:before {
  content: "\e259";
}
.icon-vertical_align_top:before {
  content: "\e25a";
}
.icon-vibration:before {
  content: "\e62d";
}
.icon-video_call:before {
  content: "\e070";
}
.icon-video_label:before {
  content: "\e071";
}
.icon-video_library:before {
  content: "\e04a";
}
.icon-videocam:before {
  content: "\e04b";
}
.icon-videocam_off:before {
  content: "\e04c";
}
.icon-videogame_asset:before {
  content: "\e338";
}
.icon-view_agenda:before {
  content: "\e8e9";
}
.icon-view_array:before {
  content: "\e8ea";
}
.icon-view_carousel:before {
  content: "\e8eb";
}
.icon-view_column:before {
  content: "\e8ec";
}
.icon-view_comfy:before {
  content: "\e42a";
}
.icon-view_compact:before {
  content: "\e42b";
}
.icon-view_day:before {
  content: "\e8ed";
}
.icon-view_headline:before {
  content: "\e8ee";
}
.icon-view_list:before {
  content: "\e8ef";
}
.icon-view_module:before {
  content: "\e8f0";
}
.icon-view_quilt:before {
  content: "\e8f1";
}
.icon-view_stream:before {
  content: "\e8f2";
}
.icon-view_week:before {
  content: "\e8f3";
}
.icon-vignette:before {
  content: "\e435";
}
.icon-visibility_off:before {
  content: "\e8f5";
}
.icon-voice_chat:before {
  content: "\e62e";
}
.icon-voicemail:before {
  content: "\e0d9";
}
.icon-volume_down:before {
  content: "\e04d";
}
.icon-volume_mute:before {
  content: "\e04e";
}
.icon-volume_off:before {
  content: "\e04f";
}
.icon-volume_up:before {
  content: "\e050";
}
.icon-vpn_key:before {
  content: "\e0da";
}
.icon-vpn_lock:before {
  content: "\e62f";
}
.icon-wallpaper:before {
  content: "\e1bc";
}
.icon-watch:before {
  content: "\e334";
}
.icon-watch_later:before {
  content: "\e924";
}
.icon-wb_auto:before {
  content: "\e42c";
}
.icon-wb_incandescent:before {
  content: "\e42e";
}
.icon-wb_iridescent:before {
  content: "\e436";
}
.icon-wb_sunny:before {
  content: "\e430";
}
.icon-wc:before {
  content: "\e63d";
}
.icon-web:before {
  content: "\e051";
}
.icon-web_asset:before {
  content: "\e069";
}
.icon-weekend:before {
  content: "\e16b";
}
.icon-whatshot:before {
  content: "\e80e";
}
.icon-widgets:before {
  content: "\e1bd";
}
.icon-wifi:before {
  content: "\e63e";
}
.icon-wifi_lock:before {
  content: "\e1e1";
}
.icon-wifi_tethering:before {
  content: "\e1e2";
}
.icon-work:before {
  content: "\e8f9";
}
.icon-zoom_in:before {
  content: "\e8ff";
}
.icon-zoom_out:before {
  content: "\e907";
}
.icon-zoom_out_map:before {
  content: "\e56b";
}
.icon-store:before {
  content: "\eea4";
}
.icon-out:before {
  content: "\eea5";
}
.icon-in:before {
  content: "\eea6";
}
.icon-in-alt:before {
  content: "\eea7";
}
.icon-home6:before {
  content: "\eea8";
}
.icon-lightbulb1:before {
  content: "\eea9";
}
.icon-anchor2:before {
  content: "\eeaa";
}
.icon-feather1:before {
  content: "\eeab";
}
.icon-expand1:before {
  content: "\eeac";
}
.icon-maximize:before {
  content: "\eead";
}
.icon-search4:before {
  content: "\eeae";
}
.icon-zoomin2:before {
  content: "\eeaf";
}
.icon-zoomout2:before {
  content: "\eeb0";
}
.icon-add2:before {
  content: "\eeb1";
}
.icon-subtract:before {
  content: "\eeb2";
}
.icon-exclamation:before {
  content: "\eeb3";
}
.icon-question1:before {
  content: "\eeb4";
}
.icon-close1:before {
  content: "\eeb5";
}
.icon-cmd:before {
  content: "\eeb6";
}
.icon-forbid:before {
  content: "\eeb7";
}
.icon-book6:before {
  content: "\eeb8";
}
.icon-spinner1:before {
  content: "\eeb9";
}
.icon-play1:before {
  content: "\eeba";
}
.icon-stop4:before {
  content: "\eebb";
}
.icon-pause3:before {
  content: "\eebc";
}
.icon-forward5:before {
  content: "\eebd";
}
.icon-rewind:before {
  content: "\eebe";
}
.icon-sound1:before {
  content: "\eebf";
}
.icon-sound-alt:before {
  content: "\eec0";
}
.icon-soundoff:before {
  content: "\eec1";
}
.icon-task:before {
  content: "\eec2";
}
.icon-inbox3:before {
  content: "\eec3";
}
.icon-inbox-alt:before {
  content: "\eec4";
}
.icon-envelope3:before {
  content: "\eec5";
}
.icon-compose:before {
  content: "\eec6";
}
.icon-newspaper2:before {
  content: "\eec7";
}
.icon-newspaper-alt:before {
  content: "\eec8";
}
.icon-clipboard6:before {
  content: "\eec9";
}
.icon-calendar6:before {
  content: "\eeca";
}
.icon-hyperlink:before {
  content: "\eecb";
}
.icon-trash2:before {
  content: "\eecc";
}
.icon-trash-alt:before {
  content: "\eecd";
}
.icon-grid3:before {
  content: "\eece";
}
.icon-grid-alt:before {
  content: "\eecf";
}
.icon-menu5:before {
  content: "\eed0";
}
.icon-list6:before {
  content: "\eed1";
}
.icon-gallery:before {
  content: "\eed2";
}
.icon-calculator4:before {
  content: "\eed3";
}
.icon-windows2:before {
  content: "\eed4";
}
.icon-browser1:before {
  content: "\eed5";
}
.icon-alarm3:before {
  content: "\eed6";
}
.icon-clock4:before {
  content: "\eed7";
}
.icon-attachment4:before {
  content: "\eed8";
}
.icon-settings3:before {
  content: "\eed9";
}
.icon-portfolio:before {
  content: "\eeda";
}
.icon-user5:before {
  content: "\eedb";
}
.icon-users3:before {
  content: "\eedc";
}
.icon-heart7:before {
  content: "\eedd";
}
.icon-chat5:before {
  content: "\eede";
}
.icon-comments1:before {
  content: "\eedf";
}
.icon-screen1:before {
  content: "\eee0";
}
.icon-iphone:before {
  content: "\eee1";
}
.icon-ipad:before {
  content: "\eee2";
}
.icon-forkandspoon:before {
  content: "\eee3";
}
.icon-forkandknife:before {
  content: "\eee4";
}
.icon-instagram1:before {
  content: "\eee5";
}
.icon-facebook1:before {
  content: "\eee6";
}
.icon-delicious1:before {
  content: "\eee7";
}
.icon-googleplus:before {
  content: "\eee8";
}
.icon-dribbble1:before {
  content: "\eee9";
}
.icon-pin3:before {
  content: "\eeea";
}
.icon-pin-alt:before {
  content: "\eeeb";
}
.icon-camera8:before {
  content: "\eeec";
}
.icon-brightness:before {
  content: "\eeed";
}
.icon-brightness-half:before {
  content: "\eeee";
}
.icon-moon3:before {
  content: "\eeef";
}
.icon-cloud9:before {
  content: "\eef0";
}
.icon-circle-full:before {
  content: "\eef1";
}
.icon-circle-half:before {
  content: "\eef2";
}
.icon-globe3:before {
  content: "\eef3";
}
.icon-home:before {
  content: "\e900";
}
.icon-home2:before {
  content: "\e910";
}
.icon-home3:before {
  content: "\e92c";
}
.icon-office:before {
  content: "\e92d";
}
.icon-newspaper:before {
  content: "\e92e";
}
.icon-pencil:before {
  content: "\e92f";
}
.icon-pencil2:before {
  content: "\e930";
}
.icon-quill:before {
  content: "\e931";
}
.icon-pen:before {
  content: "\e932";
}
.icon-blog:before {
  content: "\e933";
}
.icon-eyedropper:before {
  content: "\e934";
}
.icon-droplet:before {
  content: "\e935";
}
.icon-paint-format:before {
  content: "\e936";
}
.icon-image:before {
  content: "\e937";
}
.icon-images:before {
  content: "\e938";
}
.icon-camera:before {
  content: "\e939";
}
.icon-headphones:before {
  content: "\e93a";
}
.icon-music:before {
  content: "\e93b";
}
.icon-play:before {
  content: "\e93c";
}
.icon-film:before {
  content: "\e93d";
}
.icon-video-camera:before {
  content: "\e93e";
}
.icon-dice:before {
  content: "\e93f";
}
.icon-pacman:before {
  content: "\e940";
}
.icon-spades:before {
  content: "\e941";
}
.icon-clubs:before {
  content: "\e942";
}
.icon-diamonds:before {
  content: "\e943";
}
.icon-bullhorn:before {
  content: "\e944";
}
.icon-connection:before {
  content: "\e945";
}
.icon-podcast:before {
  content: "\e946";
}
.icon-feed:before {
  content: "\e947";
}
.icon-mic:before {
  content: "\e948";
}
.icon-book:before {
  content: "\e949";
}
.icon-books:before {
  content: "\e94a";
}
.icon-library:before {
  content: "\e94b";
}
.icon-file-text:before {
  content: "\e94c";
}
.icon-profile:before {
  content: "\e94d";
}
.icon-file-empty:before {
  content: "\e94e";
}
.icon-files-empty:before {
  content: "\e94f";
}
.icon-file-text2:before {
  content: "\e950";
}
.icon-file-picture:before {
  content: "\e951";
}
.icon-file-music:before {
  content: "\e952";
}
.icon-file-play:before {
  content: "\e953";
}
.icon-file-video:before {
  content: "\e954";
}
.icon-file-zip:before {
  content: "\e955";
}
.icon-copy:before {
  content: "\e956";
}
.icon-paste:before {
  content: "\e957";
}
.icon-stack:before {
  content: "\e958";
}
.icon-folder:before {
  content: "\e959";
}
.icon-folder-open:before {
  content: "\e95a";
}
.icon-folder-plus:before {
  content: "\e95b";
}
.icon-folder-minus:before {
  content: "\e95c";
}
.icon-folder-download:before {
  content: "\e95d";
}
.icon-folder-upload:before {
  content: "\e95e";
}
.icon-price-tag:before {
  content: "\e95f";
}
.icon-price-tags:before {
  content: "\e960";
}
.icon-barcode:before {
  content: "\e961";
}
.icon-qrcode:before {
  content: "\e962";
}
.icon-ticket:before {
  content: "\e963";
}
.icon-cart:before {
  content: "\e964";
}
.icon-coin-dollar:before {
  content: "\e965";
}
.icon-coin-euro:before {
  content: "\e966";
}
.icon-coin-pound:before {
  content: "\e967";
}
.icon-coin-yen:before {
  content: "\e968";
}
.icon-credit-card:before {
  content: "\e969";
}
.icon-calculator:before {
  content: "\e96a";
}
.icon-lifebuoy:before {
  content: "\e96b";
}
.icon-phone:before {
  content: "\e96c";
}
.icon-phone-hang-up:before {
  content: "\e96d";
}
.icon-address-book:before {
  content: "\e96e";
}
.icon-envelop:before {
  content: "\e96f";
}
.icon-pushpin:before {
  content: "\e970";
}
.icon-location:before {
  content: "\e971";
}
.icon-location2:before {
  content: "\e972";
}
.icon-compass:before {
  content: "\e973";
}
.icon-compass2:before {
  content: "\e974";
}
.icon-map:before {
  content: "\e975";
}
.icon-map2:before {
  content: "\e976";
}
.icon-history:before {
  content: "\e977";
}
.icon-clock:before {
  content: "\e978";
}
.icon-clock2:before {
  content: "\e979";
}
.icon-alarm:before {
  content: "\e97a";
}
.icon-bell:before {
  content: "\e97b";
}
.icon-stopwatch:before {
  content: "\e97c";
}
.icon-calendar:before {
  content: "\e97d";
}
.icon-printer:before {
  content: "\e97e";
}
.icon-keyboard:before {
  content: "\e97f";
}
.icon-display:before {
  content: "\e980";
}
.icon-laptop:before {
  content: "\e981";
}
.icon-mobile:before {
  content: "\e982";
}
.icon-mobile2:before {
  content: "\e983";
}
.icon-tablet:before {
  content: "\e984";
}
.icon-tv:before {
  content: "\e985";
}
.icon-drawer:before {
  content: "\e986";
}
.icon-drawer2:before {
  content: "\e987";
}
.icon-box-add:before {
  content: "\e988";
}
.icon-box-remove:before {
  content: "\e989";
}
.icon-download:before {
  content: "\e98a";
}
.icon-upload:before {
  content: "\e98b";
}
.icon-floppy-disk:before {
  content: "\e98c";
}
.icon-drive:before {
  content: "\e98d";
}
.icon-database:before {
  content: "\e98e";
}
.icon-undo:before {
  content: "\e98f";
}
.icon-redo:before {
  content: "\e990";
}
.icon-undo2:before {
  content: "\e991";
}
.icon-redo2:before {
  content: "\e992";
}
.icon-forward:before {
  content: "\e993";
}
.icon-reply:before {
  content: "\e994";
}
.icon-bubble:before {
  content: "\e995";
}
.icon-bubbles:before {
  content: "\e996";
}
.icon-bubbles2:before {
  content: "\e997";
}
.icon-bubble2:before {
  content: "\e998";
}
.icon-bubbles3:before {
  content: "\e999";
}
.icon-bubbles4:before {
  content: "\e99a";
}
.icon-user:before {
  content: "\e99b";
}
.icon-users:before {
  content: "\e99c";
}
.icon-user-plus:before {
  content: "\e99d";
}
.icon-user-minus:before {
  content: "\e99e";
}
.icon-user-check:before {
  content: "\e99f";
}
.icon-user-tie:before {
  content: "\e9a0";
}
.icon-quotes-left:before {
  content: "\e9a1";
}
.icon-quotes-right:before {
  content: "\e9a2";
}
.icon-hour-glass:before {
  content: "\e9a3";
}
.icon-spinner:before {
  content: "\e9a4";
}
.icon-spinner2:before {
  content: "\e9a5";
}
.icon-spinner3:before {
  content: "\e9a6";
}
.icon-spinner4:before {
  content: "\e9a7";
}
.icon-spinner5:before {
  content: "\e9a8";
}
.icon-spinner6:before {
  content: "\e9a9";
}
.icon-spinner7:before {
  content: "\e9aa";
}
.icon-spinner8:before {
  content: "\e9ab";
}
.icon-spinner9:before {
  content: "\e9ac";
}
.icon-spinner10:before {
  content: "\e9ad";
}
.icon-spinner11:before {
  content: "\e9ae";
}
.icon-binoculars:before {
  content: "\e9af";
}
.icon-search:before {
  content: "\e9b0";
}
.icon-zoom-in:before {
  content: "\e9b1";
}
.icon-zoom-out:before {
  content: "\e9b2";
}
.icon-enlarge:before {
  content: "\e9b3";
}
.icon-shrink:before {
  content: "\e9b4";
}
.icon-enlarge2:before {
  content: "\e9b5";
}
.icon-shrink2:before {
  content: "\e9b6";
}
.icon-key:before {
  content: "\e9b7";
}
.icon-key2:before {
  content: "\e9b8";
}
.icon-lock:before {
  content: "\e9b9";
}
.icon-unlocked:before {
  content: "\e9ba";
}
.icon-wrench:before {
  content: "\e9bb";
}
.icon-equalizer:before {
  content: "\e9bc";
}
.icon-equalizer2:before {
  content: "\e9bd";
}
.icon-cog:before {
  content: "\e9be";
}
.icon-cogs:before {
  content: "\e9bf";
}
.icon-hammer:before {
  content: "\e9c0";
}
.icon-magic-wand:before {
  content: "\e9c1";
}
.icon-aid-kit:before {
  content: "\e9c2";
}
.icon-bug:before {
  content: "\e9c3";
}
.icon-pie-chart:before {
  content: "\e9c4";
}
.icon-stats-dots:before {
  content: "\e9c5";
}
.icon-stats-bars:before {
  content: "\e9c6";
}
.icon-stats-bars2:before {
  content: "\e9c7";
}
.icon-trophy:before {
  content: "\e9c8";
}
.icon-gift:before {
  content: "\e9c9";
}
.icon-glass:before {
  content: "\e9ca";
}
.icon-glass2:before {
  content: "\e9cb";
}
.icon-mug:before {
  content: "\e9cc";
}
.icon-spoon-knife:before {
  content: "\e9cd";
}
.icon-leaf:before {
  content: "\e9ce";
}
.icon-rocket:before {
  content: "\e9cf";
}
.icon-meter:before {
  content: "\e9d0";
}
.icon-meter2:before {
  content: "\e9d1";
}
.icon-hammer2:before {
  content: "\e9d2";
}
.icon-fire:before {
  content: "\e9d3";
}
.icon-lab:before {
  content: "\e9d4";
}
.icon-magnet:before {
  content: "\e9d5";
}
.icon-bin:before {
  content: "\e9d6";
}
.icon-bin2:before {
  content: "\e9d7";
}
.icon-briefcase:before {
  content: "\e9d8";
}
.icon-airplane:before {
  content: "\e9d9";
}
.icon-truck:before {
  content: "\e9da";
}
.icon-road:before {
  content: "\e9db";
}
.icon-accessibility:before {
  content: "\e9dc";
}
.icon-target:before {
  content: "\e9dd";
}
.icon-shield:before {
  content: "\e9de";
}
.icon-power:before {
  content: "\e9df";
}
.icon-switch:before {
  content: "\e9e0";
}
.icon-power-cord:before {
  content: "\e9e1";
}
.icon-clipboard:before {
  content: "\e9e2";
}
.icon-list-numbered:before {
  content: "\e9e3";
}
.icon-list:before {
  content: "\e9e4";
}
.icon-list2:before {
  content: "\e9e5";
}
.icon-tree:before {
  content: "\e9e6";
}
.icon-menu:before {
  content: "\e9e7";
}
.icon-menu2:before {
  content: "\e9e8";
}
.icon-menu3:before {
  content: "\e9e9";
}
.icon-menu4:before {
  content: "\e9ea";
}
.icon-cloud:before {
  content: "\e9eb";
}
.icon-cloud-download:before {
  content: "\e9ec";
}
.icon-cloud-upload:before {
  content: "\e9ed";
}
.icon-cloud-check:before {
  content: "\e9ee";
}
.icon-download2:before {
  content: "\e9ef";
}
.icon-upload2:before {
  content: "\e9f0";
}
.icon-download3:before {
  content: "\e9f1";
}
.icon-upload3:before {
  content: "\e9f2";
}
.icon-sphere:before {
  content: "\e9f3";
}
.icon-earth:before {
  content: "\e9f4";
}
.icon-link:before {
  content: "\e9f5";
}
.icon-flag:before {
  content: "\e9f6";
}
.icon-attachment:before {
  content: "\e9f7";
}
.icon-eye:before {
  content: "\e9f8";
}
.icon-eye-plus:before {
  content: "\e9f9";
}
.icon-eye-minus:before {
  content: "\e9fa";
}
.icon-eye-blocked:before {
  content: "\e9fb";
}
.icon-bookmark:before {
  content: "\e9fc";
}
.icon-bookmarks:before {
  content: "\e9fd";
}
.icon-sun:before {
  content: "\e9fe";
}
.icon-contrast:before {
  content: "\e9ff";
}
.icon-brightness-contrast:before {
  content: "\ea00";
}
.icon-star-empty:before {
  content: "\ea01";
}
.icon-star-half:before {
  content: "\ea02";
}
.icon-star-full:before {
  content: "\ea03";
}
.icon-heart:before {
  content: "\ea04";
}
.icon-heart-broken:before {
  content: "\ea05";
}
.icon-man:before {
  content: "\ea06";
}
.icon-woman:before {
  content: "\ea07";
}
.icon-man-woman:before {
  content: "\ea08";
}
.icon-happy:before {
  content: "\ea09";
}
.icon-happy2:before {
  content: "\ea0a";
}
.icon-smile:before {
  content: "\ea0b";
}
.icon-smile2:before {
  content: "\ea0c";
}
.icon-tongue:before {
  content: "\ea0d";
}
.icon-tongue2:before {
  content: "\ea0e";
}
.icon-sad:before {
  content: "\ea0f";
}
.icon-sad2:before {
  content: "\ea10";
}
.icon-wink:before {
  content: "\ea11";
}
.icon-wink2:before {
  content: "\ea12";
}
.icon-grin:before {
  content: "\ea13";
}
.icon-grin2:before {
  content: "\ea14";
}
.icon-cool:before {
  content: "\ea15";
}
.icon-cool2:before {
  content: "\ea16";
}
.icon-angry:before {
  content: "\ea17";
}
.icon-angry2:before {
  content: "\ea18";
}
.icon-evil:before {
  content: "\ea19";
}
.icon-evil2:before {
  content: "\ea1a";
}
.icon-shocked:before {
  content: "\ea1b";
}
.icon-shocked2:before {
  content: "\ea1c";
}
.icon-baffled:before {
  content: "\ea1d";
}
.icon-baffled2:before {
  content: "\ea1e";
}
.icon-confused:before {
  content: "\ea1f";
}
.icon-confused2:before {
  content: "\ea20";
}
.icon-neutral:before {
  content: "\ea21";
}
.icon-neutral2:before {
  content: "\ea22";
}
.icon-hipster:before {
  content: "\ea23";
}
.icon-hipster2:before {
  content: "\ea24";
}
.icon-wondering:before {
  content: "\ea25";
}
.icon-wondering2:before {
  content: "\ea26";
}
.icon-sleepy:before {
  content: "\ea27";
}
.icon-sleepy2:before {
  content: "\ea28";
}
.icon-frustrated:before {
  content: "\ea29";
}
.icon-frustrated2:before {
  content: "\ea2a";
}
.icon-crying:before {
  content: "\ea2b";
}
.icon-crying2:before {
  content: "\ea2c";
}
.icon-point-up:before {
  content: "\ea2d";
}
.icon-point-right:before {
  content: "\ea2e";
}
.icon-point-down:before {
  content: "\ea2f";
}
.icon-point-left:before {
  content: "\ea30";
}
.icon-warning:before {
  content: "\ea31";
}
.icon-notification:before {
  content: "\ea32";
}
.icon-question:before {
  content: "\ea33";
}
.icon-plus:before {
  content: "\ea34";
}
.icon-minus:before {
  content: "\ea35";
}
.icon-info:before {
  content: "\ea36";
}
.icon-cancel-circle:before {
  content: "\ea37";
}
.icon-blocked:before {
  content: "\ea38";
}
.icon-cross:before {
  content: "\ea39";
}
.icon-checkmark:before {
  content: "\ea3a";
}
.icon-checkmark2:before {
  content: "\ea3b";
}
.icon-spell-check:before {
  content: "\ea3c";
}
.icon-enter:before {
  content: "\ea3d";
}
.icon-exit:before {
  content: "\ea3e";
}
.icon-play2:before {
  content: "\ea3f";
}
.icon-pause:before {
  content: "\ea40";
}
.icon-stop:before {
  content: "\ea41";
}
.icon-previous:before {
  content: "\ea42";
}
.icon-next:before {
  content: "\ea43";
}
.icon-backward:before {
  content: "\ea44";
}
.icon-forward2:before {
  content: "\ea45";
}
.icon-play3:before {
  content: "\ea46";
}
.icon-pause2:before {
  content: "\ea47";
}
.icon-stop2:before {
  content: "\ea48";
}
.icon-backward2:before {
  content: "\ea49";
}
.icon-forward3:before {
  content: "\ea4a";
}
.icon-first:before {
  content: "\ea4b";
}
.icon-last:before {
  content: "\ea4c";
}
.icon-previous2:before {
  content: "\ea4d";
}
.icon-next2:before {
  content: "\ea4e";
}
.icon-eject:before {
  content: "\ea4f";
}
.icon-volume-high:before {
  content: "\ea50";
}
.icon-volume-medium:before {
  content: "\ea51";
}
.icon-volume-low:before {
  content: "\ea52";
}
.icon-volume-mute:before {
  content: "\ea53";
}
.icon-volume-mute2:before {
  content: "\ea54";
}
.icon-volume-increase:before {
  content: "\ea55";
}
.icon-volume-decrease:before {
  content: "\ea56";
}
.icon-loop:before {
  content: "\ea57";
}
.icon-loop2:before {
  content: "\ea58";
}
.icon-infinite:before {
  content: "\ea59";
}
.icon-shuffle:before {
  content: "\ea5a";
}
.icon-arrow-up-left:before {
  content: "\ea5b";
}
.icon-arrow-up:before {
  content: "\ea5c";
}
.icon-arrow-up-right:before {
  content: "\ea5d";
}
.icon-arrow-right:before {
  content: "\ea5e";
}
.icon-arrow-down-right:before {
  content: "\ea5f";
}
.icon-arrow-down:before {
  content: "\ea60";
}
.icon-arrow-down-left:before {
  content: "\ea61";
}
.icon-arrow-left:before {
  content: "\ea62";
}
.icon-arrow-up-left2:before {
  content: "\ea63";
}
.icon-arrow-up2:before {
  content: "\ea64";
}
.icon-arrow-up-right2:before {
  content: "\ea65";
}
.icon-arrow-right2:before {
  content: "\ea66";
}
.icon-arrow-down-right2:before {
  content: "\ea67";
}
.icon-arrow-down2:before {
  content: "\ea68";
}
.icon-arrow-down-left2:before {
  content: "\ea69";
}
.icon-arrow-left2:before {
  content: "\ea6a";
}
.icon-circle-up:before {
  content: "\ea6b";
}
.icon-circle-right:before {
  content: "\ea6c";
}
.icon-circle-down:before {
  content: "\ea6d";
}
.icon-circle-left:before {
  content: "\ea6e";
}
.icon-tab:before {
  content: "\ea6f";
}
.icon-move-up:before {
  content: "\ea70";
}
.icon-move-down:before {
  content: "\ea71";
}
.icon-sort-alpha-asc:before {
  content: "\ea72";
}
.icon-sort-alpha-desc:before {
  content: "\ea73";
}
.icon-sort-numeric-asc:before {
  content: "\ea74";
}
.icon-sort-numberic-desc:before {
  content: "\ea75";
}
.icon-sort-amount-asc:before {
  content: "\ea76";
}
.icon-sort-amount-desc:before {
  content: "\ea77";
}
.icon-command:before {
  content: "\ea78";
}
.icon-shift:before {
  content: "\ea79";
}
.icon-ctrl:before {
  content: "\ea7a";
}
.icon-opt:before {
  content: "\ea7b";
}
.icon-checkbox-checked:before {
  content: "\ea7c";
}
.icon-checkbox-unchecked:before {
  content: "\ea7d";
}
.icon-radio-checked:before {
  content: "\ea7e";
}
.icon-radio-checked2:before {
  content: "\ea7f";
}
.icon-radio-unchecked:before {
  content: "\ea80";
}
.icon-crop:before {
  content: "\ea81";
}
.icon-make-group:before {
  content: "\ea82";
}
.icon-ungroup:before {
  content: "\ea83";
}
.icon-scissors:before {
  content: "\ea84";
}
.icon-filter:before {
  content: "\ea85";
}
.icon-font:before {
  content: "\ea86";
}
.icon-ligature:before {
  content: "\ea87";
}
.icon-ligature2:before {
  content: "\ea88";
}
.icon-text-height:before {
  content: "\ea89";
}
.icon-text-width:before {
  content: "\ea8a";
}
.icon-font-size:before {
  content: "\ea8b";
}
.icon-bold:before {
  content: "\ea8c";
}
.icon-underline:before {
  content: "\ea8d";
}
.icon-italic:before {
  content: "\ea8e";
}
.icon-strikethrough:before {
  content: "\ea8f";
}
.icon-omega:before {
  content: "\ea90";
}
.icon-sigma:before {
  content: "\ea91";
}
.icon-page-break:before {
  content: "\ea92";
}
.icon-superscript:before {
  content: "\ea93";
}
.icon-subscript:before {
  content: "\ea94";
}
.icon-superscript2:before {
  content: "\ea95";
}
.icon-subscript2:before {
  content: "\ea96";
}
.icon-text-color:before {
  content: "\ea97";
}
.icon-pagebreak:before {
  content: "\ea98";
}
.icon-clear-formatting:before {
  content: "\ea99";
}
.icon-table:before {
  content: "\ea9a";
}
.icon-table2:before {
  content: "\ea9b";
}
.icon-insert-template:before {
  content: "\ea9c";
}
.icon-pilcrow:before {
  content: "\ea9d";
}
.icon-ltr:before {
  content: "\ea9e";
}
.icon-rtl:before {
  content: "\ea9f";
}
.icon-paragraph-left:before {
  content: "\eaa1";
}
.icon-paragraph-center:before {
  content: "\eaa2";
}
.icon-paragraph-right:before {
  content: "\eaa3";
}
.icon-paragraph-justify:before {
  content: "\eaa4";
}
.icon-indent-increase:before {
  content: "\eaa5";
}
.icon-indent-decrease:before {
  content: "\eaa6";
}
.icon-share:before {
  content: "\eaa7";
}
.icon-new-tab:before {
  content: "\eaa8";
}
.icon-embed:before {
  content: "\eaa9";
}
.icon-embed2:before {
  content: "\eaaa";
}
.icon-share2:before {
  content: "\eaac";
}
.icon-mail:before {
  content: "\eaad";
}
.icon-mail2:before {
  content: "\eaae";
}
.icon-mail3:before {
  content: "\eaaf";
}
.icon-mail4:before {
  content: "\eab0";
}
.icon-google-drive:before {
  content: "\eab9";
}
.icon-facebook:before {
  content: "\eaba";
}
.icon-facebook2:before {
  content: "\eabb";
}
.icon-whatsapp:before {
  content: "\eabd";
}
.icon-twitter:before {
  content: "\eac0";
}
.icon-youtube:before {
  content: "\eac7";
}
.icon-flickr:before {
  content: "\eacd";
}
.icon-flickr2:before {
  content: "\eace";
}
.icon-android:before {
  content: "\eaea";
}
.icon-skype:before {
  content: "\eaef";
}
.icon-linkedin:before {
  content: "\eaf3";
}
.icon-linkedin2:before {
  content: "\eaf4";
}
.icon-safari:before {
  content: "\eb07";
}
.icon-file-pdf:before {
  content: "\eb09";
}
.icon-file-openoffice:before {
  content: "\eb0a";
}
.icon-file-word:before {
  content: "\eb0b";
}
.icon-file-excel:before {
  content: "\eb0c";
}
.icon-libreoffice:before {
  content: "\eb0d";
}
.icon-html-five:before {
  content: "\eb0e";
}
.icon-box3:before {
  content: "\eef4";
}
.icon-write:before {
  content: "\eef5";
}
.icon-clock5:before {
  content: "\eef6";
}
.icon-reply3:before {
  content: "\eef7";
}
.icon-reply-all:before {
  content: "\eef8";
}
.icon-forward6:before {
  content: "\eef9";
}
.icon-flag4:before {
  content: "\eefa";
}
.icon-search5:before {
  content: "\eefb";
}
.icon-trash3:before {
  content: "\eefc";
}
.icon-envelope4:before {
  content: "\eefd";
}
.icon-bubble3:before {
  content: "\eefe";
}
.icon-bubbles1:before {
  content: "\eeff";
}
.icon-user6:before {
  content: "\ef00";
}
.icon-users4:before {
  content: "\ef01";
}
.icon-cloud10:before {
  content: "\ef02";
}
.icon-download8:before {
  content: "\ef03";
}
.icon-upload6:before {
  content: "\ef04";
}
.icon-rain1:before {
  content: "\ef05";
}
.icon-sun6:before {
  content: "\ef06";
}
.icon-moon4:before {
  content: "\ef07";
}
.icon-bell2:before {
  content: "\ef08";
}
.icon-folder5:before {
  content: "\ef09";
}
.icon-pin4:before {
  content: "\ef0a";
}
.icon-sound2:before {
  content: "\ef0b";
}
.icon-microphone6:before {
  content: "\ef0c";
}
.icon-camera9:before {
  content: "\ef0d";
}
.icon-image4:before {
  content: "\ef0e";
}
.icon-cog4:before {
  content: "\ef0f";
}
.icon-calendar7:before {
  content: "\ef10";
}
.icon-book7:before {
  content: "\ef11";
}
.icon-map-marker:before {
  content: "\ef12";
}
.icon-store1:before {
  content: "\ef13";
}
.icon-support2:before {
  content: "\ef14";
}
.icon-tag5:before {
  content: "\ef15";
}
.icon-heart8:before {
  content: "\ef16";
}
.icon-video-camera1:before {
  content: "\ef17";
}
.icon-trophy1:before {
  content: "\ef18";
}
.icon-cart1:before {
  content: "\ef19";
}
.icon-eye4:before {
  content: "\ef1a";
}
.icon-cancel5:before {
  content: "\ef1b";
}
.icon-chart7:before {
  content: "\ef1c";
}
.icon-target2:before {
  content: "\ef1d";
}
.icon-printer4:before {
  content: "\ef1e";
}
.icon-location5:before {
  content: "\ef1f";
}
.icon-bookmark5:before {
  content: "\ef20";
}
.icon-monitor1:before {
  content: "\ef21";
}
.icon-cross3:before {
  content: "\ef22";
}
.icon-plus4:before {
  content: "\ef23";
}
.icon-left:before {
  content: "\ef24";
}
.icon-up:before {
  content: "\ef25";
}
.icon-browser2:before {
  content: "\ef26";
}
.icon-windows3:before {
  content: "\ef27";
}
.icon-switch4:before {
  content: "\ef28";
}
.icon-dashboard1:before {
  content: "\ef29";
}
.icon-play4:before {
  content: "\ef2a";
}
.icon-fast-forward:before {
  content: "\ef2b";
}
.icon-next1:before {
  content: "\ef2c";
}
.icon-refresh3:before {
  content: "\ef2d";
}
.icon-film5:before {
  content: "\ef2e";
}
.icon-home7:before {
  content: "\ef2f";
}

