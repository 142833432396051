body {
    background: #323a51;
    font-family: 'Poppins', sans-serif;
}

.login-wrpr {
    padding: 80px;
}

.css-i44wyl {
    width: 100% !important;
}

.login-wrpr .row {
    background: #f3f9fe;
    border-radius: 7px;
    overflow: hidden;
    /* min-height: 790px; */
}

.login-left {
    background: #f3f9fe;
    padding: 58px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

h2 {
    font-weight: lighter;
}

.login-left h2 {
    font-size: 20px;
    margin-top: 40px;
}

.login-left h2 span {
    font-weight: 500;
}

.login-left p {
    font-size: 11px;
    color: rgb(0 0 0 / 70%);
}

.has-float-label .form-control:placeholder-shown:not(:focus)+* {
    font-size: 13px;
    opacity: .5;
    top: 12px;
}


.login-form-wrpr {
    width: 100%;
    margin-bottom: 38px;
}

.custom-control {
    position: relative;
    display: block;
    padding-left: 1.5rem;
    cursor: pointer;
}

.custom-control-indicator {
    position: absolute;
    top: 0.0625rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 50% 50%;
    background-size: 50% 50%;
}

.custom-control-label {
    user-select: none;
}

.form-group.forgot-remember {
    display: flex;
    font-size: 11px;
    justify-content: space-between;
    align-items: center;
}

.form-group.forgot-remember label {
    line-height: 24px;
}

.form-group.forgot-remember a {
    line-height: 24px;
}

.login-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.login-buttons .btn {
    min-width: 110px;
    width: 100%;
    font-size: 11px;
    letter-spacing: .5px;
    min-height: 33px;
    height: 40px;
    font-weight: 500;
}

.login-buttons .btn-primary {
    background: #323a51;
    border-color: #323a51;
}

.login-buttons .btn-outline-primary {
    border-color: #323a51;
    color: #323a51;
}

.login-form-cntnr {
    margin-top: 70px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    height: 15px;
}

@media(max-width:800px) {
    .login-wrpr {
        padding: 30px;
    }

    .login-left {
        padding: 39px;
    }
}

.bg-login-right-1 {
    background-image: url("../images/login-bg-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-login-right-2 {
    background-image: url("../images/login-bg-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-login-right-4 {
    background-image: url("../images/login-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}