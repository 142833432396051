@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --primary-color: #323a51;
    --active-sidebar: #FC97A4
}

.btn-customized {
    background-color: var(--primary-color);
    color: white;
    width: 100%;
    border-radius: 6px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    height: 25px;
    align-items: center;
}

.btn-customized-large {
    background-color: var(--primary-color) !important;
    width: 100%;
    color: white;
    border-radius: 6px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
}

.btn-customized-colorless {
    width: 100%;
    color: white;
    border-radius: 6px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
}

.custom-color {
    background-color: var(--primary-color);
}

.custom-textcolor {
    color: var(--primary-color);
}

@font-face {
    font-family: 'icons-sm';
    src: url('fonts/icons-sm.eot?ms3c7d');
    src: url('fonts/icons-sm.eot?ms3c7d#iefix') format('embedded-opentype'),
        url('fonts/icons-sm.ttf?ms3c7d') format('truetype'),
        url('fonts/icons-sm.woff?ms3c7d') format('woff'),
        url('fonts/icons-sm.svg?ms3c7d#icons-sm') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons-sm' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
img,
.readmore,
.header-nav a,
.mega-sub-menu .mega-menu-link,
.book-an-appointment,
a,
a i {
    font-family: 'Poppins', sans-serif;
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

.subscribe-form i {
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
.navbar,
.menu-item a,
.menu-headermenu-container .submenu {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
}

h1 {
    font-size: 36px;
    font-weight: 400;
    text-align: center;
    line-height: 48px;
}

a:hover {
    text-decoration: none;
}

a.navbar-toggler {
    border: none;
}

a.navbar-toggler .icon-menu1 {
    font-size: 32px;
}


/* left and right pannels */


.right-side,
.left-side {
    min-height: 100%;
    display: block;
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

.left-side {
    background-color: var(--primary-color);
    position: fixed;
    width: 320px;
    top: 0;
    left: 0;
    z-index: 999;
}

.right-side {
    background: #ecebf4;
    margin-left: 320px;
    min-height: 100vh !important;
}

img {
    max-width: 100%;
}

.left-side .sidebar .logo {
    width: 171px;
}

.left-side .sidebar .logo-wrpr {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
}

.left-nav .navbar {
    display: flex;
}

.left-nav .navbar ul {
    width: 100%;
}

.left-side .navbar-nav {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.left-side nav.navbar,
.left-side nav.navbar ul {
    width: 100%;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.left-side .navbar-nav a {
    font-weight: 400;
    font-size: 14px;
}

.left-side .navbar-nav a i {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: #fff;
    margin-left: 10px;
}

.left-side .nav-item a i:after {
    content: "";
    position: absolute;
    width: 80px;
    height: 80px;
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    background: transparent;
    border-radius: 50%;
    z-index: -1;
}

.left-side .navbar-nav a i.fontSize18 {
    font-size: 18px;
}

a.nav-link {
    position: relative;
}

.sidebar .nav-link {
    display: flex;
    justify-content: space-between;
    color: #fff !important;
}

.sidebar .nav-item {
    width: 100%;
    line-height: 26px;
}

.sidebar .nav-link span {
    order: 1;
    display: block;
    width: 100%;
    padding-left: 25px;
    letter-spacing: .6px;
    white-space: nowrap;
}

.left-side .navbar-nav a.active span,
.left-side .navbar-nav a:hover span {
    color: var(--active-sidebar);
}


.nav-link.active i{
    color: var(--active-sidebar)!important;
} 
  
.nav-link.active{
    font-weight: 700!important;
}

.sidebar a:hover i {
    color: var(--active-sidebar)!important;
}

.sidebar-collapsed .left-side {
    width: 67px;
}

.sidebar-collapsed .left-side span {
    visibility: hidden;
}

.left-side span {
    white-space: nowrap;
}

.sidebar-collapsed .right-side {
    margin-left: 67px;
}

.navbar-toggler-wrpr {
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 125px;
    padding-top: 9px;
    margin-left: 15px;
}

.navbar-toggler-wrpr a i {
    font-size: 19px !important;
}

.navbar-toggler-wrpr a {
    cursor: pointer;
}

/* left and right pannels */



/*right pan header*/
.header-main {
    display: flex;
    height: 90px;
    justify-content: space-between;
    align-items: center;
    padding-left: 26px;
    padding-right: 26px;
}

.right-cntnr {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.right-cntnr .leader-board-icon {
    width: 36px;
    height: 36px;
    background: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #dfdae8;
    overflow: hidden;
    margin-right: 13px;
}

.search-wrpr {
    min-width: 395px;
    margin-right: 13px;
}

.search-wrpr input {
    width: 100%;
    border: 1px solid #dfdae8;
    min-height: 36px;
    border-radius: 47px;
    padding-left: 12px;
    font-size: 12px;
}

.notification-wrpr a i {
    font-size: 22px;
    color: #e7ae0b;
    font-size: 22px;
    color: #e7ae0b;
    position: relative;
    top: -1px;
}

.notification-wrpr a {
    display: inline-flex;
    background: #fff;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #dfdae8;
    margin-right: 13px;
}

.calendar-wrpr a i {
    font-size: 22px;
    color: #e7ae0b;
    font-size: 22px;
    color: #e7ae0b;
    position: relative;
    top: -1px;
}

.calendar-wrpr a {
    display: inline-flex;
    background: #fff;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #dfdae8;
    margin-right: 13px;
}

.myprofile-wrpr a i {
    font-size: 23px;
    color: #6a5c9f;
}

.myprofile-wrpr a {
    display: inline-flex;
    background: #fff;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #dfdae8;
}

.myprofile-wrpr.dropdown .dropdown-toggle::after {
    display: none;
}

.dropdown-menu a {
    display: flex;
    width: auto;
    border-radius: 0;
    border-color: white;
    justify-content: flex-start;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px;
}

.dropdown-menu a:hover {
    background: #ecebf4;
}

.dropdown-menu {
    box-shadow: 0 0 30px rgb(0 0 0 / 32%);
    border: none;
}

.notification-wrpr>a:after,
.notification-hdr span:after {
    content: attr(data-notifications);
    position: absolute;
    font-size: 11px;
    background: #ff81a3;
    color: #fff;
    min-width: 17px;
    height: 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 25px;
    border-radius: 50%;
}

.calendar-wrpr>a:after,
.calendar-hdr span:after {
    content: attr(data-notifications);
    position: absolute;
    font-size: 11px;
    color: #fff;
    min-width: 17px;
    height: 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 25px;
    border-radius: 50%;
}

.notification-hdr span:after {
    position: static;
}

.notification-menu {
    width: 308px;
    padding-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    margin: 20px;
    margin-right: 0px !important;
}

.notification-hdr {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.notification-hdr span {
    position: relative;
    letter-spacing: .5px;
}

.notification-hdr span:after {
    width: 20px;
    height: 20px;
    font-size: 12px;
}

.n-list {
    display: flex;
    background: #eaeff3;
    padding: 17px;
    font-size: 13px;
    color: #323a51;
}

.n-list>i {
    min-width: 40px;
    height: 40px;
    font-size: 22px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 30px;
    margin-right: 12px;
}

.n-list>i:before {
    position: relative;
    top: -1px;
}

.btn-acknowledge {
    background-color: var(--primary-color) !important;
    max-width: 116px;
    height: 27px !important;
    color: #fff;
    font-size: 11px !important;
    border-radius: 5px !important;
    display: flex;
    justify-content: space-between !important;
    padding-right: 4px !important;
    padding: 0px !important;
    padding-left: 10px !important;
    overflow: hidden;
}

.dropdown-menu .btn-acknowledge i {
    background: #6a5c9f;
    height: 25px;
    position: relative;
    top: 0px;
}

.icon-imageicon img {
    width: 15px;
    height: 15px;
}

.n-list+.n-list {
    border-top: 1px solid #b2b8c2;
}

.n-list:first-child {
    border-top: 1px solid #b2b8c2;
}

.nlist-content a {
    margin-top: 5px;
}

.nlist-content a:hover {
    color: #fff;
}

.myprofile-wrpr>a:hover,
.notification-wrpr>a:hover,
.leader-board-icon:hover,
.calendar-wrpr>a:hover {
    box-shadow: 0 0 0 2px #323a51;
}

.search-wrpr input {
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

.search-wrpr input:hover {
    box-shadow: 0 0 0 2px #323a51;
}

a.navbar-toggler.navbar-toggler-icon.active i:before {
    content: "\e5cd";
    color: #dc3c39;
}

aside.left-side.side-off-canvas.sidebar-active {
    width: 320px;
}

aside.left-side.side-off-canvas.sidebar-active span {
    visibility: visible;
}

/*right pan header end*/




/*content area */

.content-area {
    padding: 25px;
}

.prospect-before-section {
    min-height: 340px;
    background: linear-gradient(0deg, #ff81a3, #fba0a5);
    border-radius: 22px;
    padding: 15px;
}

.prospect-before-section h2 {
    font-size: 20px;
    color: #fff;
    display: inline-flex;
    flex-direction: column;
}

.prospect-before-section h2 span {
    display: block;
    font-size: 14px;
}

i.prospect-icon {
    width: 50px;
    display: inline-flex;
}

.prospect-icon {
    display: inline-flex;
    width: 52px;
    height: 52px;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 20px rgb(200 67 103 / 75%);
}

.prospect-hding-wrpr {
    display: flex;
    align-items: flex-start;
}

.prospect-hding-wrpr i {
    margin-right: 14px;
}

.prospect-tablist a {
    color: #fff;
    font-size: 14px;
}

.nav-tabs.prospect-tablist a {
    font-size: 14px;
    border-bottom: 2px solid #fdb0b7 !important;
}

.nav-tabs.prospect-tablist .nav-link:hover,
.nav-tabs.prospect-tablist .nav-link:focus {
    border: none;
}

.nav-tabs.prospect-tablist .nav-link {
    border: none;
}

.nav-tabs.prospect-tablist {
    border-bottom: 0px;
}

.nav-tabs.prospect-tablist a.active {
    background: transparent;
    border: none;
    border-bottom-color: #323a51 !important;
    position: relative;
}

.nav-tabs.prospect-tablist a.hot:before,
.nav-tabs.prospect-tablist a.warm:before,
.nav-tabs.prospect-tablist a.cold:before {
    content: attr(data-hotcount);
    position: absolute;
    font-size: 11px;
    background: #fff;
    color: #e43c38;
    min-width: 17px;
    height: 17px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: -1px;
    border-radius: 50%;
    font-weight: bold;
}

.nav-tabs.prospect-tablist a.warm:before {
    content: attr(data-warmcount);
}

.nav-tabs.prospect-tablist a.cold:before {
    content: attr(data-coldcount);
}


.nav-link.hot img,
.nav-link.warm img,
.nav-link.cold img {
    max-width: 11px;
    margin-right: 4px;
}

.nav-link.warm img {
    max-width: 17px;
}

.nav-link.cold img {
    max-width: 15px;
}

.table.prospect-table {
    font-size: 11px;
}

table.table.prospect-table td {
    border-top: 1px solid #d48093;
}

.tab-content.prospect-tabcontent .tab-pane {
    padding-left: 0px;
    padding-right: 0px;
}

.table.prospect-table tr:first-child td {
    border-top: none;
}

.nav-tabs.prospect-tablist .nav-item {
    margin-right: 10px;
}

.appointments-section h2 {
    font-size: 20px;
}

.appointments-section h2 span {
    display: block;
    font-size: 14px;
}

.appointments-hing-wrpr {
    display: flex;
}

.appointments-icon img {
    box-shadow: none !important;
    margin-right: 6px;
}

.appointments-section {
    margin-top: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #c7c8d3;
    padding-bottom: 29px;
}

.appoinment-with-wrpr {
    font-size: 12px;
}

.appoinment-with {
    font-weight: 600;
    font-size: 13px;
}

.appointment-info {
    opacity: .7;
}

.appointment-time {
    color: #96959b;
}

.appointment-time:before {
    content: "\ee35";
    font-family: 'icons-sm';
    color: #000;
    margin-right: 6px;
}

.appoinment-with {
    position: relative;
}

.appoinment-with:before {
    content: "\eba6";
    font-family: 'icons-sm';
    font-size: 20px;
    position: absolute;
    left: -27px;
    color: #ff9474;
    font-weight: 100;
    top: -3px;
}

.appointments-section .col-md-3 {
    border-left: 1px solid #d9d9e3;
    padding-left: 39px;
}

.appointments-section .col-md-3:first-child {
    padding-left: 15px;
    border-left: 0px;
}

.highlight-wrpr {
    background: #ffc75e;
    padding: 17px;
    border-radius: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 91px;
    align-items: center;
    margin-bottom: 24px;
}

.highlight-wrpr.yellow {
    background: #ffc75e;
}

.ilets {
    display: flex;
}

.left-group .ilets-title {
    font-size: 20px;
    color: #fff;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    line-height: 20px;
}

.ilets-icon {
    width: 52px;
    background: #fff;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 0 0 20px rgb(214 84 119 / 62%);
}

.ilets-count {
    font-size: 34px;
    color: #fff;
}

.ilets-dropdown {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 21px;
    margin-left: 5px;
}

.highlight-wrpr.magenta {
    background: #d85fc2;
}

.highlight-wrpr.orange {
    background: #ff9474;
}

.highlight-wrpr.purple {
    background: #7a5c9f;
}

.left-group .ilets-title span {
    display: block;
}

.left-group .ilets-title span {
    font-size: 14px;
}

.ilets-icon i.icon-imageicon img {
    width: 30px;
    height: 30px;
    position: relative;
    top: -3px;
}

#ielets,
#tot,
#followupstoday,
#longterm {
    width: 100%;
}

#ielets table,
#tot table,
#followupstoday table,
#longterm table {
    border: 1px solid rgb(0 0 0 / 9%);
    margin-top: 20px;
    margin-bottom: 0;
}

.highlight-wrpr table.table.prospect-table td {
    border-top: 1px solid rgb(0 0 0 / 17%);
}

.highlight-wrpr:hover .ilets-dropdown a {
    background: rgb(0 0 0 / 34%);
    border-radius: 50%;
    cursor: pointer;
}

.year-and-yeartodate-section h2 {
    font-size: 20px;
    color: #323a51;
    margin-bottom: 30px;
}

.year-and-yeartodate-section h2 i {
    font-size: 18px;
}

.ilets-tot-follow-longtem-section {
    border-bottom: 1px solid #c7c8d3;
    margin-bottom: 40px;
    padding-bottom: 10px;
}

.circle-default-style {
    margin-top: 15px;
}

#text-circle1,
#text-circle2 {
    font-size: 16px;
    fill: #323a51;
}

#text-circle1+#text-circle1,
#text-circle2+#text-circle2 {
    color: #323a51;
    font-size: 6px;
}

#circle1 svg,
#circle2 svg {
    width: 150px;
}

#circle1,
#circle2 {
    display: flex;
    justify-content: center;
}

.progress-bar.bg-pink {
    background: #ff82a3;
    border-radius: 4px;
}

.progress {
    background: #d4d3db;
    height: 3px;
}

.progress-chart-content {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}

.chart-total .count {
    color: #6a5c9f;
    font-size: 34px;
}

.chart-total {
    display: flex;
    align-items: center;
}

.chart-total span:first-child {
    max-width: 80px;
    text-align: right;
    margin-right: 10px;
    line-height: 14px;
    opacity: .7;
}

.chart-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.progress-bar.bg-orange {
    background: #ff9173;
}

.progress-bar.bg-magenta {
    background: #d85fc2;
}

.progress-charts+.progress-charts {
    margin-top: 16px;
}

.year-and-yeartodate-section {
    border-bottom: 1px solid #c7c8d3;
    padding-bottom: 60px;
    margin-bottom: 60px;
}

.year-and-yeartodate-section .col-md-6:first-child {
    border-right: 1px solid #c7c8d3;
}

.icon-and-title-count {
    background: #6a5c9f;
    color: #fff;
    padding: 15px;
    border-radius: 15px;
}

.icon-and-title i {
    width: 52px;
    background: #fff;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 0 0 20px rgb(214 84 119 / 62%);
}

.icon-and-title h2 {
    font-size: 18px;
    margin-bottom: 0;
}

.icon-and-title h2 span {
    font-size: 12px;
    display: block;
}

.icon-and-title {
    display: flex;
    align-items: center;
}

.icon-and-title-count.purple {
    background: #6a5c9f;
}

.icon-and-title-count.purple i {
    color: #6a5c9f;
}

.icon-and-title-count .count {
    text-align: right;
    font-size: 34px;
    font-family: 'Poppins';
    font-weight: 100;
}

.icon-and-title-count.yellow {
    background: #ffc75e;
}

.icon-and-title-count.yellow i {
    color: #ffc75e;
}

.icon-and-title-count.orange {
    background: #ff9474;
}

.icon-and-title-count.orange i {
    color: #ff9474;
    box-shadow: 0 0 20px rgb(171 98 26 / 62%);
}

.icon-and-title-count.magenta {
    background: #d85fc2;
}

.icon-and-title-count.magenta i {
    color: #d85fc2;
    box-shadow: 0 0 20px rgb(165 28 128 / 62%);
}

.icon-and-title-count.green {
    background: #8bc34a;
}

.icon-and-title-count.pink {
    background: #ff81a3;
}

.icon-and-title-count.pink i {
    color: #ff81a3;
    box-shadow: 0 0 20px rgb(178 58 90 / 62%);
}

.icon-and-title-count.green i {
    color: #8bc34a;
    box-shadow: 0 0 20px #4caf50;
}

.icon-and-title-count.red i {
    color: #f00;
    box-shadow: 0 0 20px #f00;
}

.icon-and-title-count.blue i {
    color: #0082ca;
    box-shadow: 0 0 20px #0082ca;
}

.swiper-button-next.carousel-next,
.swiper-button-prev.carousel-prev {
    background: #fff;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0px;
    top: 62px;
}

.swiper-button-prev.carousel-prev {
    right: unset;
    left: 0px;
}

.swiper-button-next.carousel-next:before {
    content: "\e409";
    font-family: 'icons-sm';
    font-size: 23px;
}

.swiper-button-prev.carousel-prev:before {
    content: "\e408";
    font-family: 'icons-sm';
    font-size: 23px;
}

.carousel-section {
    border-bottom: 1px solid #c7c8d3;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.table-section .table.prospect-table thead th {
    font-weight: 500;
}

.table-section .table.prospect-table thead {
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 11px !important;
    border-collapse: separate;
    font-weight: 400;
}

.action-links a {
    color: #323a51;
    font-size: 18px;
}

a.navbar-toggler.navbar-toggler-icon.phoneonly {
    display: none;
}

table.table.prospect-table td {
    border-top: 1px solid #d4d4d4;
}

.search-wrpr-mobile {
    display: none;
}

.leaderboard-menu {
    width: 390px;
    min-height: 300px;
    padding-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    margin: 20px;
    margin-right: 0px !important;
    padding: 10px;
}

.leaderboard-hdr {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    font-weight: 500;
    margin-bottom: 14px;
}

.threeLeader {
    display: flex;
    justify-content: space-between;
}

.leader.third,
.leader.second,
.leader.first {
    background-color: var(--primary-color);
    color: #fff;
    width: 33%;
    min-height: 125px;
    display: flex;
    padding: 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.leader.first {
    background: #ffc75e;
    margin-left: -11px;
    margin-right: -11px;
    width: 37%;
    margin-top: -13px;
    margin-bottom: -10px;
    position: relative;
}

.leader img {
    width: 38px;
    height: 38px;
    max-width: 38px;
    max-height: 38px;
    border-radius: 50%;
    margin-top: 23px;
    margin-bottom: 11px;
}

.leader.first img {
    width: 52px;
    height: 52px;
    max-width: 52px;
    max-height: 52px;
}

.leader.third {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.leader.second {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.leader.first {
    border-radius: 10px;
}

.leader-name {
    font-size: 12px;
}

.leader-points {
    font-size: 11px;
}

.leader.first .leader-name {
    font-size: 13px;
    color: #000;
    font-weight: 500 !important;
}

.leader.first .leader-points {
    color: #000
}

.leader-position {
    position: relative;
    bottom: -17px;
    box-shadow: 0 0 0 3px #323a51;
    border-radius: 50%;
}

.leader-position span {
    display: inline-flex;
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #000;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.leader.first .leader-position {
    box-shadow: 0 0 0 3px #ffc75e;
}

.leader.first .leader-position span {
    width: 36px;
    height: 36px;
    font-size: 18px;
}

.other-leaders-wrpr {
    margin-top: 50px;
}

.other-leader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.other-leaders-wrpr {
    margin-top: 50px;
}

.other-leader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.other-leader-leftpart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.other-leader-leftpart img {
    margin-right: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
}

.other-leader-leftpartt .leader-name {
    font-size: 12px !important;
}

.other-leader .leader-points {
    font-weight: 600;
}

.other-leader {
    padding-top: 10px;
    padding-bottom: 10px;
}

.other-leader+.other-leader {
    border-top: 1px solid #dadada;
}

.other-leader:first-child {
    border-top: 1px solid #dadada;
}

.search-wrpr input:focus-visible {
    outline: none;
}

.other-leader-img-wrpr {
    position: relative;
}

.other-leader-img-wrpr .badge {
    width: 15px;
    height: 15px;
    background: #7a5c9f;
    position: absolute;
    left: 33px;
    top: 0px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 11px;
    box-shadow: 0 0 0 2px #fff;
}

.left-side .navbar-nav a i {
    font-size: 15px;
}

/*content area end */








/* Add Clients page */


.add_clients {
    margin-top: 0px;
    margin-bottom: 30px;
    border-bottom: 1px solid #c7c8d3;
    padding-bottom: 8px;
    border-top: 1px solid #c7c8d3;
    padding-top: 19px;
}

.add_clients h2 {
    font-size: 20px;
    line-height: 17px;
    position: relative;
    top: -1px;
}

.add_clients h2 span {
    display: block;
    font-size: 14px;
}

.content-area .breadcrumb {
    font-size: 12px;
    background: transparent;
    margin-bottom: 0;
    padding-left: 0px;
}

.add_clients_accordion_form {
    margin-top: 10px;
}

.add_clients_accordion_form .card-link {
    color: #000;
    font-size: 14px;
}

.add_clients_accordion_form label {
    font-size: 12px;
    font-weight: 500;
}

label.dashboard {
    font-size: 12px;
    font-weight: 500;
}

.add_clients_accordion_form select,
.add_clients_accordion_form textarea,
.add_clients_accordion_form input {
    font-size: 13px;
    min-height: 44px;
    margin-top: 0px;
}

.add_clients_accordion_form .card-header {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: none;
}

.add_clients_accordion_form .card {
    border: none;
}

.add_clients_accordion_form .card {
    border: none;
    box-shadow: 0 0 10px rgb(0 0 0 / 5%);
    margin-bottom: 20px;
}

.add_clients_accordion_form .card-link {
    display: block;
}

.hotwarmcold {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    max-width: 70px;
    width: 55px;
}

.custom-checkbox.hotwarmcold .custom-control-label::before {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background: #ffc413;
}

.custom-checkbox.hotwarmcold .custom-control-label::after {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.hotwarmcold .custom-control-input:checked~.custom-control-label::before {
    background: #FF5722;
    border-color: #FF5722;
}

.hotwarmcold.warm .custom-control-input:checked~.custom-control-label::before {
    background: #ffc413;
    border-color: #ffc413;
}

.hotwarmcold.cold .custom-control-input:checked~.custom-control-label::before {
    background: #00bcd4;
    border-color: #00bcd4;
}

.hotwarmcold.registered .custom-control-input:checked~.custom-control-label::before {
    background: #4eff22;
    border-color: #4eff22;
}

.custom-checkbox.hotwarmcold.hot .custom-control-label::before {
    background: #ff5722;
}

.custom-checkbox.hotwarmcold.warm .custom-control-label::before {
    background: #ffc413;
}

.custom-checkbox.hotwarmcold.cold .custom-control-label::before {
    background: #00bcd4;
}

.custom-checkbox.hotwarmcold.registered .custom-control-label::before {
    background: #4eff22;
}

.checkbox-wrpr+span {
    position: relative;
    left: -22px;
    margin-top: 7px;
    font-size: 12px;
}

.appointments-hing-wrpr+.col-md-6 {
    display: flex;
    justify-content: flex-end;
    /*flex-direction: column;
    align-items: flex-end;*/
}

.progress-hr {
    margin-left: 5px;
    margin-right: 5px;
}

.hot span {
    left: -20px;
    top: 6px;
}

.warm span {
    left: -28px;
    top: 6px;
}

.cold span {
    left: -24px;
    top: 6px;
}

.registered span {
    left: -24px;
    top: 6px;
}

.pf-label {
    line-height: 41px;
    font-size: 12px;
    font-weight: 600;
    margin-right: 25px;
    letter-spacing: .4px;
    width: 136px;
    text-align: center;
    height: 33px;
}

.btn-nextrow {
    background-color: var(--primary-color);
    color: #fff;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    padding: 0px;
    font-size: 10px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
}

.btn-nextrow:hover {
    color: #ffe8a2;
}

.btn-removerow {
    background: #dc3545;
    border-color: #dc3545;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    padding: 0;
    font-size: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.radio-group {
    display: flex;
}

.radio-group .custom-radio {
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.radio-group .custom-control-label::before {
    width: 20px;
    height: 20px;
}

.radio-group .custom-control-label::after {
    width: 20px;
    height: 20px;
}

.radio-group .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--primary-color);
    border-color: #323a51;
}

.radio-group label.custom-control-label {
    height: 27px;
    display: flex;
    align-items: center;
    position: relative;
}

.add_clients .card-header:before {
    content: "";
    position: absolute;
    top: -2px;
    display: inline-flex;
    height: 3px;
    width: 100px;
    background-color: var(--primary-color);
}

.add_clients .card:hover .card-header:before {
    background: #e7ae0b;
}

.form-group.error input,
.form-group.error select,
.form-group.error textarea {
    box-shadow: 0 0 0 3px #ff5722;
}

.tab-info {
    position: absolute;
    right: 20px;
    background: #ff5722;
    top: -7px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 11px;
    color: #fff;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-weight: 400;
    letter-spacing: .4px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
}

.tab-info:before {
    content: "";
    display: inline-flex;
    border: 3px solid #872e12;
    border-top-color: transparent;
    border-left-color: transparent;
    top: 1px;
    left: -6px;
    position: absolute;
}

.mandatory {
    color: red;
    font-style: normal;
    font-size: 18px;
    position: absolute;
    top: -1px;
}

.form-group label {
    /* position: relative; */
}

.btn-save,
.btn-save-lock {
    background: #ff5722;
    color: #fff;
    margin: 30px 0;
}

.btn-back {
    background: blueviolet;
    color: #fff;
    margin: 30px 0;
}

.btn-save:hover,
.btn-save-lock:hover {
    color: #fff;
    box-shadow: 0 0 0 3px #d9d9d9;
}

.add_clients_accordion_form .nav-tabs .nav-link {
    font-size: 13px;
    font-weight: 500;
    color: rgb(0 0 0 / 48%);
}

.add_clients_accordion_form .nav-tabs .nav-link.active {
    color: #000;
}

.custom-switch .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--primary-color);
    border-color: #323a51;
    width: 40px;
    height: 20px;
    border-radius: 20px;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    -webkit-transform: translateX(0.75rem);
    transform: translateX(20px);
    height: 15px;
    width: 15px;
    top: 3px;
}

.custom-switch .custom-control-label::before {
    width: 40px;
    height: 20px;
    border-radius: 20px;
}

.custom-switch .custom-control-label::after {
    width: 15px;
    height: 15px;
    border-radius: 20px;
}

.custom-control.custom-switch input+label {
    padding-left: 10px;
    padding-top: 5px;
}

.actual-btn-label {
    background-color: #fff;
    color: #333;
    padding: 17px 31px;
    font-family: sans-serif;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 1rem;
    min-height: 65px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    border: 2px dotted #ccc;
}

.file-chosen {
    margin-left: 0.3rem;
    font-size: 12px;

}

.file-chosen-wrpr a {
    font-size: 19px;
    border: 1px solid #dadada;
    padding: 4px;
    border-radius: 4px;
    margin-right: 2px;
    margin-top: 9px;
    display: inline-flex;
    color: #000;
}

.actual-btn-label i {
    display: block;
    text-align: center;
    font-size: 21px;
    margin-bottom: 7px;
}

.upload-wrpr {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dadada;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.actual-btn-label:hover {
    border-color: #ff5722;
}

.myAutocomplete button.dropdown-item {

    font-size: 12px;
}

.myAutocomplete button.dropdown-item:hover {
    background: #f1f1f1;
    font-size: 12px;
}

.radio-group.priority-group label {
    width: 87px;
}

.table.collageprogram th {
    font-size: 12px;
    font-weight: 500;
}

.table.collageprogram td {
    font-size: 12px;
}

.table.collageprogram {
    border: 1px solid #dadada;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(180deg, #d2e1f8, #fff);
}

.nextui-c-grJsex-igxGpFn-css {
    max-width: 100%;
}

.table.collageprogram thead {
    background: var(--primary-color);
    color: #fff;
}

td.action-icons a,
td.action-icons button,
span.action-icons a,
span.action-icons button {
    background-color: var(--primary-color);
    color: #fff;
    padding: 5px;
    margin-bottom: 5px;
    display: inline-flex;
    border-radius: 3px;
}

td.action-icons button img {
    width: 12px;
    height: 12px;
}

td.action-icons button.follow {
    position: relative;
    top: -1px;
}

.error-msg {
    /* position: absolute; */
    right: 15px;
    top: 6px;
    font-size: 12px;
    color: #ff5722;
    margin-top: 10px;
    margin-bottom: 10px;
}

.login-error-msg {
    /* position: absolute; */
    right: 15px;
    top: 6px;
    font-weight: bold;
    font-size: 12px;
    color: #ff5722;
    margin-top: 10px;
    margin-bottom: 10px;
}

.agegroup {
    display: none;
}

.agegroup.showage.age1,
.agegroup.showage.age2,
.agegroup.showage.age3 {
    display: block;
}

.numbercol:after {
    content: "\e0c3";
    font-family: 'icons-sm';
    font-size: 17px;
    background: #e4e4e4;
    margin-left: 3px;
    border-radius: 3px;
}

.numbercol {
    cursor: move;
}

.tab-info.fields-completed,
.tab-info.fields-notcompleted {
    background: transparent;
    box-shadow: none;
    top: 0;
    /* color: #f6ff00; */
    color: #ffee07;
    /* color: #8bc34a; */
    font-size: 28px;
    padding-right: 0px;
}

.tab-info.fields-completed:before,
.tab-info.fields-notcompleted:before {
    content: none;
}

.tab-info.fields-notcompleted {
    color: #ff5722;
}

.tab-info.fields-notcompleted:after {
    /* content: attr(data-fieldspending); */
    border: none;
    position: absolute;
    background: #607d8b;
    z-index: 1;
    font-size: 11px;
    color: #fff;
    width: 16px;
    height: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: 8px;
    right: -10px;
    box-shadow: 0 0 0 2px #fff;
}


/*upload preview css*/
#image-preview {
    width: 80px;
    height: 80px;
    position: relative;
    overflow: hidden;
    background-color: #ffffff;
    color: #ecf0f1;
    border-radius: 30%;
}

#image-preview input {
    line-height: 120px;
    font-size: 120px;
    position: absolute;
    opacity: 0;
    z-index: 10;
}

#image-preview label {
    position: absolute;
    z-index: 5;
    cursor: pointer;
    background-color: #323a51db;
    width: 80px;
    height: 15px;
    font-size: 7px;
    letter-spacing: .7px;
    line-height: 15px;
    text-transform: uppercase;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    border-radius: 0;
}

/*upload preview css end*/


.profile-wrpr {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}

.username {
    margin-top: 20px;
    margin-bottom: 0;
}

.useremail {
    font-size: 12px;
}

.useremail a {
    color: #000;
}

.tab-info span i {
    font-size: 22px;
    margin-right: 5px;
}

.tab-info span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control.form-control-sm {
    min-height: 31px;
    width: auto;
}

.yearfield,
.score {
    max-width: 105px;
}

.ai-info-wrpr {
    border: 2px solid #323a51;
}

.ai-info-wrpr .table {
    margin-bottom: 0;
}

.btn-export {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-export i {
    margin-left: 10px;
}

.btn-save:after {
    content: "\e161";
    font-family: 'icons-sm';
    margin-left: 9px;
    position: relative;
    top: 0px;
    font-size: 15px;
}

.btn-save-lock:after {
    content: "\e897";
    font-family: 'icons-sm';
    margin-left: 9px;
    position: relative;
    top: 0px;
    font-size: 15px;
}

.btn-back:after {
    content: "\ecc5";
    font-family: 'icons-sm';
    margin-left: 9px;
    position: relative;
    top: 0px;
    font-size: 15px;
}

.english-test-wrpr {
    display: flex;
}

.english-test-wrpr em {
    position: relative;
    left: -38px;
    top: 2px;
    font-style: normal;
    font-size: 13px;
    margin-right: 8px;
    height: 23px;
    display: flex;
    align-items: center;
}

.english-test-cntnr {
    display: flex;
    align-items: center;
}



.english-test-cntnr span {
    margin-right: 30px;
}

.english-test-cntnr span+.form-group {
    margin-bottom: 0px;
}

.left-header {
    background: rgb(218 218 218 / 55%);
}

.englishtext-prgrm {
    display: none;
}

.englishtext-prgrm.active {
    display: block;
}

label.labelno {
    position: relative;
    left: -44px;
    top: 5px;
}

.english-ifyes,
.english-ifno {
    display: none;
}

.english-ifyes.active,
.english-ifno.active {
    display: flex;
}

.form-group.spouse-toggle-visible.active {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
}

.form-group.spouse-toggle-visible {
    opacity: .3;
    pointer-events: none;
}

.spouse-tab {
    display: none;
}

.spouse-tab.active {
    display: block;
}

.btn-save.btn-submit {
    background: #8bc34a;
    padding: 12px 39px;
}

.breadcrumb-and-otherbuttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.other-buttons a {
    font-size: 12px;
    letter-spacing: .4px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 5px;
}

.tabloaded {
    animation: tabloaded 3s infinite;
}

@keyframes tabloaded {
    0% {
        box-shadow: 0 0 0 3px rgb(255 87 34 / 0%);
    }

    40% {
        box-shadow: 0 0 0 3px rgb(255 87 34 / 100%);
    }

    100% {
        box-shadow: 0 0 0 3px rgb(255 87 34 / 0%);
    }
}


#appointments_wrpr {}

.btn-clear {
    color: #ff5722 !important;
}

.btn-goto_appointments {
    background: #da4453;
    border-color: #da4453;
}

.btn-goto_statusofprospects {
    background: #f6bb42;
    border-color: #f6bb42;
}

.btn-comments {
    background: #26de80;
    border-color: #26de80;
}

.commentedby {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
}

.commentedby img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.comment {
    font-size: 13px;
}

.date-time {
    font-size: 12px;
    margin-bottom: 5px;
}

.date-time i {
    color: #f6bb42;
    margin-right: 5px;
}

.date-time i:nth-child(2) {
    margin-left: 18px;
}

.doc-status-wrpr {
    font-size: 12px;
    margin-top: 10px;
}

.doc-status-wrpr {
    display: flex;
    justify-content: space-between;
}

#comments .modal-body .row+.row {
    border-top: 1px solid #dadada;
    padding-top: 20px;
}

#comments .modal-body .row {
    padding-bottom: 20px;
}

.doc-status-wrpr strong {
    font-weight: 500;
}

#comments .modal-content {
    border: none !important;
    border-radius: 9px;
    box-shadow: 0 9px 50px rgb(0 0 0 / 35%);
}

#comments .modal-header {
    background: #26de80;
    color: #fff;
}

#comments .modal-header h4 {
    font-size: 17px;
}

.btn-comments i {
    font-size: 19px;
    position: absolute;
    right: 8px;
}

.btn-comments {
    padding-right: 35px !important;
    position: relative;
}

.btn-comments {
    position: relative;
}

.btn-comments:after {
    content: attr(data-totalcomments);
    border: none;
    position: absolute;
    background: #607d8b;
    z-index: 1;
    font-size: 11px;
    color: #fff;
    width: 16px;
    height: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: -10px;
    right: -5px;
    box-shadow: 0 0 0 2px #fff;

}

.profile-status-btn-wrpr {
    display: flex;
    align-items: center;
}

.addClients-progressbar {
    counter-reset: step-count;
}


.progress-bar {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}

.progress-step {
    text-align: center;
    position: relative;
}

.progress-step:before,
.progress-step:after {
    background-color: #da4453;
    content: "";
    height: 2px;
    position: absolute;
    z-index: 0;
    top: 19px;
}

.progress-step:first-of-type:before {
    right: 50%;
    width: 50%;
}

.progress-step:last-of-type:after {
    left: 50%;
    width: 50%;
}

.addClients-progressbar {
    padding-left: 20px;
    padding-right: 20px;
}

.progress-step .step-count {
    background-color: #da4453;
    height: 40px;
    width: 40px;
    margin: 0 auto;
    border-radius: 50%;
    color: white;
    line-height: 30px;
    position: relative;
    z-index: 100;
    border: 7px solid #ecebf4;
    font-size: 0.8rem;
    font-weight: 500;
}

.progress-step .step-count:before {
    counter-increment: step-count;
    content: counter(step-count);
    font-size: 13px;
    position: relative;
    top: -2px;
    z-index: 100;
}

.step-description {
    color: #000;
    font-size: 11px;
}

.progress-step.is-active~.progress-step .step-count {
    background-color: #dad6ce;
}

.addClients-progressbar .progress-bar {
    background: none;
}

.progress-step:after {
    left: 50%;
    width: 100%;
}

.progress-step.is-active~.progress-step:after {
    background-color: #dad6ce;
}

.applicant-details-wrpr {
    display: inline-flex;
    font-size: 12px;
}

.applicant-details-wrpr em {
    font-style: normal;
}

.applicant-details-wrpr span,
.applicant-details-wrpr em {
    margin: 0 9px;
}

.applicant-details-wrpr i {
    color: #b08d6d;
}

.applicant-details-wrpr i.icon-envelope3 {
    position: relative;
    top: 1px;
}

.flag-progress {
    position: absolute;
    bottom: 49px;
}

.flag-progress:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 34px;
    background: #8c8c8c;
    bottom: -13px;
}

.countryname {
    font-size: 10px;
    position: absolute;
    bottom: 4px;
    left: 28px;
}

.flag-progress img {
    position: relative;
    z-index: 1;
}

/* Add Clients page end */

html {
    scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

/* listing page start */
.action-icons a.delete {
    background: orangered;
}

.action-icons button {
    border: none;
    /* margin-left: 5px; */
}

.action-icons button+button {
    /* margin-left: 5px; */
}

.action-icons button.delete {
    background: orangered;
    border: none;
}

.table-search-filter-wrpr {
    display: flex;
    justify-content: flex-end;
}

.table-search-wrpr .form-control {
    margin-right: 7px;
    margin-bottom: 17px;
}

.previous-list,
.next-list {
    font-family: 'icons-sm';
}

.table-pagination a {
    color: #333;
    display: inline-flex;
    min-width: 30px;
    text-align: center;
    align-items: center;
}

/* listing page  end */


/* My Clients starts*/

.btn-search {
    background: #da4453;
    border-color: #da4453;
    font-size: 14px;
    letter-spacing: .4px;
    line-height: 30px;
    min-width: 132px;
}

.btn-search i {
    font-size: 12px;
    margin-left: 12px;
}

.simple-search .form-control,
.advance-search .form-control {
    min-height: 40px;
}

.simple-search .btn-search {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 40px;
}

.advance-search-btn {
    position: absolute;
    left: 50%;
    margin-left: -65px;
    font-size: 12px;
    text-align: center;
    min-width: 130px;
    background: #fff;
    top: 6px;
    color: #000
}

.advance-search-btn.search {
    background: var(--primary-color);
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    top: -2px;
    border-radius: 10px;
    border: none;
    color: #fff;
}

.advance-search {
    margin-bottom: 30px;
}

.advance-search .custom-checkbox span {
    white-space: nowrap;
}

.btn-add-new-client i {
    font-size: 17px;
    position: absolute;
    left: 1px;
}

.btn-add-new-client {
    position: relative;
    padding-left: 30px !important;
    background-color: var(--primary-color);
    border-color: #323a51;
}

.as-wrapper {
    display: none;
}

.common-search-wrpr.as-active .simple-search {
    display: none;
}

.common-search-wrpr.as-active .as-wrapper {
    display: flex;
}

.common-search-wrpr.as-active .advance-search-btn-cntnr {
    order: 2;
}

.common-search-wrpr.as-active .as-wrapper {
    order: 1;
}

.common-search-wrpr .advance-search-btn span:before {
    content: "Advance Search";
    border: 1px solid #323a51;
    background-color: #323a51;
    color: white;
    padding: 8px;
    border-radius: 3px;
    cursor: pointer;
}

.common-search-wrpr.as-active .advance-search-btn span:before {
    content: "Simple Search";
}

.common-search-wrpr.as-active .advance-search-btn i:before {
    content: "\e316";
}

.advance-search {
    display: flex;
    flex-direction: column;
}

.search-results-count-wrpr {
    margin-bottom: 16px;
    font-size: 13px;
}

.alt-colors tbody tr:nth-child(odd) {
    background: #dde7fd;
}

.custom-pagination li {
    margin-left: 9px;
}

.custom-pagination li a {
    display: flex;
    justify-content: center;
    width: 38px;
    line-height: 27px !important;
    border-radius: 3px;
}

.custom-pagination li.active a {
    background: #fff !important;
    color: #000 !important;
    border-color: #da4453 !important;
}

/* My Clients end*/



/* @media (max-width:900px){
	.search-wrpr {
		display: none;
	}
	.table-section {
		overflow: hidden;
		overflow-x: auto;
	}


	.tab-content.prospect-tabcontent {
		overflow: hidden;
		overflow-x: auto;
	}
	.ilets-tot-follow-longtem-section .collapse {
		overflow: hidden;
		overflow-x: auto;
	}
} */


@media (max-width:550px) {
    .leaderboard-menu {
        width: 340px;
    }

    .search-active .search-wrpr {
        position: fixed;
        display: block;
        left: 22px;
        right: 10px;
        min-width: unset;
        z-index: 10;
        top: 105px;
    }

    a.navbar-toggler.navbar-toggler-icon.phoneonly {
        display: block;
    }

    .wrapper.sidebar-active aside.right-side {
        z-index: -1;
        position: relative;
    }

    a.navbar-toggler.navbar-toggler-icon.phoneonly {
        position: relative;
        top: 21px;
        left: -4px;
        padding: 0;
    }

    a.navbar-toggler.navbar-toggler-icon.phoneonly i {
        font-size: 24px;
    }

    .search-wrpr-mobile {
        width: 36px;
        height: 36px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #dfdae8;
        border-radius: 50%;
        margin-right: 12px;
    }

    .search-wrpr-mobile a {
        color: #6a5c9f;
    }

    .ilets-tot-follow-longtem-section .collapse {
        overflow: hidden;
        overflow-x: auto;
    }

    .appointments-hing-wrpr {
        margin-bottom: 15px;
        border-bottom: 1px solid #dedde6;
    }

    .appointments-section .col-md-3 {
        border-bottom: 1px solid #d9d9e3;
        border-left: none;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .appointments-section {
        border-bottom: none;
    }

    .year-and-yeartodate-section .col-md-6:last-child {
        margin-top: 50px;
    }

    .prospect-tablist {
        margin-top: 20px;
    }

    .sidebar-collapsed .left-side {
        width: 0;
        overflow: hidden;
    }

    .right-side {
        margin-left: 0px !important;
    }
}


/*popup*/
.assign-counsellor-popup .modal-header {
    background: #eb6338;
    color: #fff;



}

.assign-counsellor-popup .modal-header h4 {
    font-size: 20px;
    font-weight: 300;
    line-height: 14px;
}

.assign-counsellor-popup .modal-header h4 span {
    display: block;
    font-size: 13px;
    line-height: 17px;
}

.assign-counsellor-popup .search-wrpr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #fff;
    width: 240px !important;
    min-width: inherit;
    border-radius: 30px;
    margin-right: 0;
}

.assign-counsellor-popup .search-wrpr input {
    max-width: 230px;
    border: none;
}

.assign-counsellor-popup .search-wrpr input:hover {
    box-shadow: none;
}

.assign-counsellor-popup .search-wrpr button.icon-search {
    border: none;
    background: none;
    margin-right: 10px;
}

.assign-counsellor-popup .search-wrpr button.icon-search:focus {
    outline: none;
}

.assign-counsellor-popup .modal-content {
    border: none;
}

.assign-counsellor-popup .modal-hdr-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.counsellor-photo-wrpr {
    width: 46px;
    height: 46px;
    border-radius: 11px;
    overflow: hidden;
}

.counsellor-photo-wrpr {
    width: 46px;
    height: 46px;
    border-radius: 11px;
    overflow: hidden;
    border: 2px solid #fff;
}

.counsellor-card {
    background: #00aa90;
    width: 232px;
    padding: 11px;
    border-radius: 13px;
    color: #fff;
    display: flex;
    margin: 10px;
    position: relative;
}

.counsellor-name h4 {
    font-weight: 400;
    font-size: 19px;
    margin-bottom: 0;
    margin-left: 9px;
}

.managing-clients {
    font-size: 13px;
    margin-left: 10px;
}

.counsellor-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.counsellor-card.greencard {
    background: #00aa90;
}

.mc-count {
    background: #f8c207;
    display: inline-flex;
    width: 21px;
    height: 21px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    color: #000;
    font-size: 13px;
}

.counsellor-card-wrpr {
    display: flex;
    flex-wrap: wrap;
}

.counsellor-card.bluecard {
    background: #0082ca;
}


.assign-counsellor-popup .modal-content {
    border: none !important;
    border-radius: 9px;
    box-shadow: 0 9px 50px rgb(0 0 0 / 35%);
}

.assigned-indication {
    display: none;
}

.assigned .assigned-indication {
    position: absolute;
    right: 10px;
    font-size: 24px;
    display: block;
}

.counsellor-card:hover {
    box-shadow: 0 0 0 3px #002c7a2b;
}

.floating-messagebox {
    position: fixed;
    right: 10px;
    bottom: -300px;
    background: #ffc75e;
    border-radius: 11px;
    width: 320px;
    transition: all .3s linear;
    -o-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    z-index: 10000;
    box-shadow: 0 0 20px rgb(0 0 0 / 39%);
}

.floating-messagebox.active {
    right: 10px;
    bottom: 10px;

}

.floating-messagebox .fm-box {
    display: flex;
    flex-direction: row;
    padding: 9px;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 0 0 3px white;
    border-radius: 9px;
}

.notification-content {
    padding-left: 10px;
}

.notification-content h4 {
    font-weight: 500;
    font-size: 14px !important;
}

.notification-content p {
    font-size: 13px;
    margin-bottom: 5px;
}

.notification-actions button {
    min-width: 77px;
    font-size: 12px;
    background-color: var(--primary-color);
    border-color: #323a51;
}

.notification-actions button:hover {
    background: orangered;
    border-color: orangered;
}

.floating-messagebox .counsellor-photo-wrpr {
    width: 65px;
    min-width: 65px;
    height: 65px;
    border-radius: 11px;
    overflow: hidden;
    border: 2px solid #fff;
}

.notification-actions button:active {
    background: #e3170f !important;
    border-color: #e3170f !important;
}

.floating-messagebox.center {
    left: 50%;
    margin-left: -160px;
    bottom: -1000px;
    margin-bottom: -56px;

}

.floating-messagebox.center.active {
    left: 50%;
    margin-left: -160px;
    bottom: 50%;
    margin-bottom: -56px;

}

/* master menu */

.navbar.master-menu {
    position: absolute;
    top: 125px;
    transition: all .3s linear;
    -o-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
}

.navbar.main-menu {
    position: absolute;
    left: 0px;
    transition: all .3s linear;
    -o-transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
}


.backto-mainmenu {
    position: absolute;
    right: 84px;
    top: 13px;
    display: none;
}

.wrapper.mastermenu-active .backto-mainmenu {
    display: block;
}

.backto-mainmenu:after {
    content: "Main Menu";
    position: absolute;
    font-size: 10px;
    width: 71px;
    top: 3px;
    left: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.wrapper.mastermenu-active .main-menu {
    position: absolute;
    /* left: -500px; */
}

.wrapper.mastermenu-active .master-menu {
    left: 0;
}

.wrapper.mastermenu-active.sidebar-collapsed .backto-mainmenu:after {
    content: none;
}

.wrapper.mastermenu-active.sidebar-collapsed .backto-mainmenu {
    right: 21px;
    top: 41px;
}

.navbar {
    bottom: 0;
    top: 125px;
    display: flex;
    align-items: flex-start;
    overflow: hidden;
    overflow-y: auto;

}



.navbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
}

.navbar::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
}

.navbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #D62929;
}


/* master menu end */


/* My Clients */

.targets-wrpr {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.targets-wrpr>div {
    min-width: 230px;
    min-height: 137px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 48%;
}

.targets-wrpr>div+div {
    margin-left: 10px;
    margin-bottom: 10px;
}

.targets-wrpr>div .count {
    font-size: 24px;
}

.targets-wrpr>div .count {
    background: rgb(255 255 255);
    display: inline-flex;
    margin-left: auto;
    justify-content: flex-end;
    padding: 0 6px;
    padding-left: 8px;
    padding-right: 0px;
    border-radius: 8px;
    align-items: center;
    overflow: hidden;
    color: #6a5c9f;
}

.targets-wrpr>div .count span {
    background: black;
    padding: 6px;
    margin-left: 10px;
}

.targets-wrpr>div.purple .count {
    color: #6a5c9f;
}

.targets-wrpr>div.yellow .count {
    color: #ffc75e;
}

.targets-wrpr>div.green .count {
    color: #8bc34a;
}

.mytargets-label {
    justify-content: center !important;
    align-items: center;
    font-weight: 200;
    font-size: 16px;
    line-height: 19px;
    padding-top: 17px;
    padding-right: 10px;
    background: #ecebf4;
    border-radius: 15px;
    padding: 15px;
    height: 137px;
}

.mytargets-label span {
    font-weight: 400;
}

.section-filter h2 {
    display: inline-flex;
    flex-direction: revert;
}

.section-filter h2 i {
    margin-right: 9px;
}

.btn-add-new-client2 i {
    background: #ffc75e;
    display: inline-flex;
    width: 27px;
    height: 27px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-left: 4px;
    margin-right: 21px;
    position: absolute;
    top: 5px;
    color: #323a51;
}

.btn-add-new-client2 {
    min-width: 190px;
    border-radius: 9px;
    height: 39px;
    margin-bottom: 5px;
}

.mytargets-label i {
    font-size: 22px;
    margin-bottom: 6px;
}

/* My Clients */


.action-icons a+a,
.action-icons button+button,
.action-icons a+button,
.action-icons button+a {
    margin-left: 5px;
}



/*Program Coordinator */


.prg-coordinator-dash {
    min-height: 175px;
    border-radius: 11px;
    padding: 20px;
    background-image: linear-gradient(to right bottom, #9b59db, #8153c2, #694ca9, #55448f, #433b75, #3a3668, #32315a, #2b2c4d, #282b49, #252a45, #222841, #20273d);
}

.prg-co-filter form {
    display: flex;
    justify-content: flex-end;
}

.prg-co-filter select {
    background: #20273d;
    border-color: #20273d;
    color: #fff;
    font-size: 12px;
    border-radius: 21px;
    padding-right: 5px;
}

.prg-cord-status-wrpr {
    background: rgb(255 255 255 / 12%);
    min-height: 39px;
    border-radius: 4px;
    overflow: hidden;
}

.prg-cord-progress {
    background: #fd5c65;
    height: 39px;
    position: relative;
}

.prg-cord-progress:before {
    content: attr(data-prgcord);
    position: absolute;
    right: 6px;
    top: 9px;
    color: #323a51;
    font-weight: bold;
}

.prg-cord-status label {
    color: #fff;
    font-size: 12px;
}

.red .prg-cord-progress {
    background: #fd5c65;
}

.orange .prg-cord-progress {
    background: #fd9644;
}

.yellow .prg-cord-progress {
    background: #fed230;
}

.green .prg-cord-progress {
    background: #26de80;
}

.icon-person.view-counsellor {
    background: #fa6f44;
}

.action-icons.action-with-dropdown {
    display: flex;
}

.action-icons.action-with-dropdown .dropdown {
    margin: 0 4px;
}

.dropdown-menu.counsellor-menu {
    background: #df633d;
    flex-direction: column;
    border: 3px solid #fff;
}

.dropdown-menu.counsellor-menu a {
    background: transparent;
    border-bottom: 1px solid rgb(0 0 0 / 15%);
    width: 100%;
}

.dropdown-menu.counsellor-menu a:last-child {
    border-bottom: none;
}

.dropdown-menu.counsellor-menu a .counsellor-menu-wrpr {
    display: flex;
    flex-direction: column;
}

.dropdown-menu.counsellor-menu a .counsellor-menu-wrpr span:first-child {
    font-weight: 500;
    font-size: 14px;
}

.dropdown-menu.counsellor-menu a .counsellor-menu-wrpr span {
    margin-left: 30px;
}

.dropdown-menu.counsellor-menu a .counsellor-menu-wrpr:before {
    content: "\e7fd";
    position: absolute;
    font-family: 'icons-sm';
    width: 21px;
    text-align: center;
}

.table.dropdown-table thead th {
    background: #448ccb;
}

.dropdown-table-wrpr {
    padding: 0px !important;
}

.table.dropdown-table tr {
    background: #fff !important;
}

.dropdown-table-wrpr {
    padding: 10px !important;
    position: relative;
}

.dropdown-table-wrpr.active {
    display: table-cell;
}

.dropdown-table-wrpr.active:before {
    content: "";
    display: block;
    position: absolute;
    top: -7px;
    left: 61px;
    border: 10px solid transparent;
    border-bottom-color: #448ccb;
}

.dropdown-table-wrpr {
    display: none;
}

.search-wrpr {
    min-width: 265px;
}

.myprofile-name-desig {
    display: inline-flex;
    flex-direction: column;
    font-size: 11px;
    align-items: flex-end;
    justify-content: center;
    font-weight: 100;
    margin-right: 6px;
    margin-left: 11px;
}

.myprofile-name-desig .name {
    font-size: 14px;
    font-weight: 600;
    line-height: 9px;
    margin-top: 2px;
}

.myprofile-name-desig .desig {
    line-height: 16px;
}

.myprofile-wrpr.namd-desig-cntnr {
    display: flex;
    align-items: center;
}

table.table.dropdown-table {
    box-shadow: 0 0 50px rgb(0 0 0 / 25%);
}

table.table.report.collageprogram td {
    border: 1px solid rgb(0 0 0 / 22%);

}

table.table.report.collageprogram {
    box-shadow: inset 0 0 0 1px #abb4c2;
}

table.table.report.collageprogram th {
    border: 1px solid #454d62;

}


/*Program Coordinator end*/



.zui-table {
    border: none;
    border-right: solid 1px #DDEFEF;
    border-collapse: separate;
    border-spacing: 0;
    font: normal 13px Arial, sans-serif;
    border-radius: 0px !important;
  
}
.zui-table thead th {
    border: none;
    padding: 10px;
    text-align: left;
    white-space: nowrap;
}
.zui-table tbody td {
    border-bottom: solid 1px #DDEFEF;
    padding: 10px;
    white-space: nowrap;
}
.zui-wrapper {
    position: relative;
}
.zui-scroller {
    margin-left: 189px;
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
}
.zui-table .zui-sticky-col {
    border-left: solid 1px #DDEFEF;
    left: 0;
    position: absolute;
    top: auto;
    width: 120px;
}
.zui-table .zui-sticky-col2 {
    border-right: solid 1px #DDEFEF;
    left: 120px;
    position: absolute;
    top: auto;
    width: 70px;
}

th.zui-sticky-col{
    background-color:var(--primary-color);
}


th.zui-sticky-col2{
    background-color:var(--primary-color);
}

.zui-toalColumn{
    width: 189px;
}





/*admission staff start */

.link-td {
    word-break: break-all;
    max-width: 470px;
    display: inline-block;
}

.details-page-wrpr label {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
}

.details-page-wrpr span {
    font-size: 14px;
    margin-top: 0px;
    display: block;
    margin-bottom: 11px;
}

.big-fonts span {
    font-size: 23px;
    margin-bottom: 0px;
}

span.phonenumber {
    font-size: 16px;
    margin-bottom: 20px;
}

.details-page-wrpr {
    padding-left: 80px;
    padding-right: 80px;
}

.big-fonts .profile-wrpr {
    margin-bottom: 10px;
}

.profile-name-phone-wrpr {
    border-bottom: 1px solid #dadada;
    margin-bottom: 38px;
}

.edit-wrpr {
    border-top: 1px solid #dadada;
    margin-top: 38px;
    padding-top: 40px;
}

.btn-status {
    background: #da4453;
    border-color: #da4453;
    font-size: 14px;
    letter-spacing: .4px;
    line-height: 30px;
    min-width: 132px;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.application-status label {
    font-weight: 400;
    font-size: 13px;
}

.toast.fee-toast {
    position: absolute;
    z-index: 100;
    min-width: 550px;
    color: #000;
}

.addfeeBtn {
    line-height: 29px;
    font-size: 23px;
}

.btn-save {
    font-size: 16px;
}

.btn-save-lock {
    font-size: 14px;
}

.change-application-status-wrpr {
    display: block;
    box-shadow: 0 0 50px rgb(0 0 0 / 12%);
    padding: 29px 15px;
    border-radius: 6px;
}

.manage-fee-wrpr {
    display: none;
}

.manage-fee-wrpr.active {
    display: block;
}

.change-application-status-btn,
.btn-manage-fee-receive {
    font-size: 17px;
}

.btn-status i {
    font-size: 15px;
}

.application-status label.custom-file-label {
    height: 43px;
    line-height: 31px;
}

.application-status label.custom-file-label:after {
    height: 42px;
    line-height: 32px;
}

.application-staff-page .left-side {
    display: none;
}

.application-staff-page .right-side {
    margin-left: 0;
}

.textarea-wprp textarea {
    border: 1px solid #d0d0d0;
    padding-left: 10px;
    margin-top: 10px;
    min-height: 90px;
}

.btn-save-credentials {
    background: #17a2b8;
    color: #fff;
    min-width: 120px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: none;
}

.btn-save-credentials i {
    margin-left: 21px;
}

.textarea-wprp {
    position: relative;
}

.textarea-wprp:hover textarea {
    border: 1px solid #dadada;
}

.textarea-wprp.active .btn-save-credentials {
    display: block;
}

.textarea-wprp.active textarea {
    border-color: #dadada;
}

/*admission staff end */





/*Fee coordinator*/

#feemodal .modal-header {
    background: rgb(158 158 158 / 25%);
}

#feemodal .modal-header h4 {
    font-size: 17px;
}



#feemodal .modal-body h4 {
    font-size: 17px;
}

#feemodal .modal-body h4 span {
    display: block;
    font-size: 13px;
    margin-top: 5px;
}

#feemodal .modal-body h4 span:before {
    content: "\eeca";
    font-family: 'icons-sm';
    font-size: 12px;
    margin-right: 3px;
    color: #4caf50;
}

#feemodal .modal-body {
    font-size: 15px;
}

#feemodal .modal-body label {
    margin-top: 17px;
}

.btn-add-fees {
    background: #eb771a;
    border-color: #eb771a;
    font-size: 16px;
}

.btn-add-fees:hover {
    background: #eb771a;
    border-color: #eb771a;
    box-shadow: 0 0 0 3px rgb(128 128 128 / 38%);
}

#feemodal .modal-content {
    border: none;
    border-radius: 8px;
    min-width: 600px;
}

.fee-paid-and-pending .col-md-6:first-child {
    border-right: 1px solid #dadada;
}

.fee-paid-and-pending .col-md-6:last-child {
    text-align: right;
}

#feemodal .modal-body h4 strong {
    color: #da4453;
    font-weight: 500;
}

.fee-paid-and-pending .col-md-6:last-child h4 strong {
    color: #eb771a !important;
}

.action-icons a.btn-fee {
    height: 22px;
    width: 71px;
    min-width: 71px;
    font-size: 13px;
    letter-spacing: .4px;
    line-height: 30px;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.action-icons .btn-add-fees {
    font-size: 17px;
    padding: 2px 7px;
    line-height: 23px;
}

.row.addfeeform-wrpr .col-md-3 {
    padding-right: 0;
}

.row.addfeeform-wrpr .col-md-3 input,
.row.addfeeform-wrpr .col-md-3 select {
    font-size: 14px;
    height: 38px;
}


/*Fee coordinator end*/




/*visa staff*/

.action-icons a+a {
    margin-left: 5px;
}

.tab-content .details-page-wrpr {
    padding-left: 40px;
    padding-right: 40px;
}

.viewpdf {
    font-size: 23px;
    vertical-align: middle;
    color: #e31212;
    margin-right: 4px;
}

.viewpdf em {
    font-style: normal;
    font-family: 'Poppins';
    font-size: 14px;
    color: #000;
}

.viewpdf:before {
    position: relative;
    top: 6px;
}

.ai-card {
    border: 1px solid #dadada;
    padding: 30px 10px;
    padding-bottom: 0;
    border-radius: 7px;
    box-shadow: 0 0 12px rgb(0 0 0 / 18%);
    margin-bottom: 40px;
}

.action-icons a.btn-status {
    height: 22px;
    width: 138px;
    min-width: 71px;
    font-size: 13px;
}

#statuschange .modal-header h4 {
    font-size: 17px;
}

#statuschange .modal-body {
    font-size: 13px;
}

#statuschange .modal-body select,
#statuschange .modal-body textarea {
    font-size: 13px;
}

#statuschange .modal-header h4 {
    font-size: 17px;
}

#statuschange .modal-content {
    border: none;
    border-radius: 8px;
}

.btn-dark.btn-search.btn-changestatus {
    background: #4caf50;
    border-color: #4caf50;
}

#statuschange .modal-footer {
    border: none;
}

#statuschange .modal-header {
    background: rgb(158 158 158 / 25%);
}

.btn-visadata {
    height: 22px;
    width: 108px;
    min-width: 40px;
    font-size: 10px;
    letter-spacing: .4px;
    line-height: 30px;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-sts {
    height: 28px;
    width: 130px;
    min-width: 71px;
    font-size: 13px;
    letter-spacing: .4px;
    line-height: 30px;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-send {
    height: 35px;
    width: 150px;
    min-width: 71px;
    font-size: 13px;
    letter-spacing: .4px;
    line-height: 30px;
    color: #fff !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btn-update {
    height: 39px;
    width: 113px;
    min-width: 120px;
    font-size: 13px;
    letter-spacing: .4px;
    line-height: 30px;
    color: #000 !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.yesno-wrpr {
    display: flex;
}

.clr-Grey {
    background: orange;
}

.countt {
    position: relative;
    text-align: right;
    color: #323a51 !important;
    font-weight: bold;
    padding-right: 15px;
    top: -30px;

}

.custom-control.custom-radio+.custom-control.custom-radio {
    margin-left: 19px;
}

.geebee-radio .custom-control-label::before {
    width: 26px;
    height: 26px;
}

.geebee-radio .custom-control-label::after {
    top: 7px;
    left: -21px;
    width: 20px;
    height: 20px;
}

.geebee-radio .custom-control-label {
    position: relative;
    line-height: 35px;
    padding-left: 7px;
}

.geebee-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: var(--primary-color);
    border-color: #323a51;
}

#visadata .modal-header {
    background: rgb(158 158 158 / 25%);
}

#visadata .modal-header h4 {
    font-size: 17px;
}

#visadata .modal-body {
    font-size: 13px;
}

#visadata input[type=date] {
    font-size: 14px;
}

#visadata .btn-save {
    margin: 0;
}

/*visa staff end*/


/*manager */
.action-icons.action-buttons a,
.action-icons.action-buttons button {
    min-width: 160px;
}

.action-button-wrpr {
    display: flex;
}

.action-icons.action-buttons .btn-small {
    min-width: 127px;
    width: auto;
}

.action-icons.action-buttons .btn-small.btn-icon {
    min-width: 28px;
}

.geebee-modal .modal-header {
    background: rgb(158 158 158 / 25%);
}

.geebee-modal .modal-header h4 {
    font-size: 17px;
}

.geebee-modal input[type=date],
.geebee-modal input[type=text],
.geebee-modal select,
.geebee-modal input[type=email],
.geebee-modal textarea {
    font-size: 14px;
}

.geebee-modal .modal-body {
    font-size: 13px;
    padding: 5px;
}

.admission-visa-cntnr {
    background: #00aa90;
    width: 33%;
    padding: 11px;
    border-radius: 5px;
    color: #fff;
    display: flex;
    margin: 10px;
    position: relative;
}

.admission-visa-cntnr .profile-pic {
    width: 46px;
    min-width: 46px;
    height: 46px;
    border-radius: 11px;
    overflow: hidden;
    border: 2px solid #fff;
}

.admission-visa-cntnr .profile-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
}

.admission-visa-cntnr .profile-details h4 {
    font-weight: 400;
    font-size: 19px;
    margin-bottom: 0;
    margin-left: 9px;
}

.assigned-button-wrpr .custom-control-label::before {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    border: none;
}

.assigned-button-wrpr .custom-control-label {
    width: 26px;
    height: 26px;
    position: absolute;
    right: 0;
    top: 0;
}

.assigned-button-wrpr {
    position: absolute;
    right: 0;
    top: -1px;
}

.assigned-button-wrpr .custom-control.custom-radio {
    position: absolute;
}

.assigned-button-wrpr .custom-control-label::after {
    width: 26px;
    height: 26px;
}

.assigned-button-wrpr .custom-control-input:checked~.custom-control-label::before {
    background: #00aa90;
    border-color: #00aa90;
}

.assigned-button-wrpr .custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
    content: "\e5ca";
    font-family: 'icons-sm';
    font-size: 27px;
    line-height: 24px;
}

.assign-to-admission-visa-wrpr {
    display: flex;
}

.admission-visa-cntnr.greencard {
    background: #00aa90;
}

.admission-visa-cntnr.bluecard {
    background: #0082ca;
}

.admission-visa-cntnr.bluecard .assigned-button-wrpr .custom-control-input:checked~.custom-control-label::before {
    background: #0082ca;
    border-color: #0082ca;
}

.geebee-modal .modal-hdr-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.geebee-modal .search-wrpr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #fff;
    width: 240px !important;
    min-width: inherit;
    border-radius: 30px;
    margin-right: 0;
}

.geebee-modal .search-wrpr input {
    max-width: 230px;
    border: none;
}

.geebee-modal .search-wrpr button.icon-search {
    border: none;
    background: none;
    margin-right: 10px;
}

.geebee-modal .search-wrpr input:hover {
    box-shadow: 0 0 0 0px #323a51;
}

.geebee-modal .search-wrpr input {
    max-width: 230px;
    border: none;
}

.geebee-modal .btn-search i {
    font-size: 19px;
    margin-left: 12px;
    position: relative;
    top: 2px;
}

.geebee-modal .modal-header.orange {
    background: #eb6338;
    color: #fff;
}

.geebee-modal .modal-content {
    border: none !important;
    border-radius: 9px;
    box-shadow: 0 9px 50px rgb(0 0 0 / 35%);
}

/*manager end */


/* Counsilor dashboard - Aug 9 2023 */

.icon-and-title-count.longtem-client img {
    width: 32px !important;
    position: relative;
    left: 2px;
}

.on-hold.yellow img {
    position: relative;
    left: -2px;
}

.feepaid-column,
.visa-column {
    display: flex;
    justify-content: space-between;
    padding: 16px 15px;
    border: 3px solid #dadada;
    /*border-left: none;
    border-right: none;*/
    font-size: 13px;
    align-items: center;
    margin-bottom: 10px;
}

.feepaid-column .list-count span,
.visa-column .visa-count span {
    background: #ffc75e;
    display: inline-flex;
    min-width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    font-size: 18px;
    padding: 9px;
}

.feepaid-column.previous .list-count span,
.visa-column.previous .visa-count span {
    background: #6a5c9f;
    color: #fff;
}

.feepaid-column.current .list-count span,
.visa-column.current .visa-count span {
    background: #6a5c9f;
    color: #fff;
}

.feepaid-column.future .list-count span,
.visa-column.future .visa-count span {
    background: #ff9000;
}

.feepaid-column.previous,
.feepaid-column.current,
.feepaid-column.future,
.visa-column.previous,
.visa-column.current,
.visa-column.future {
    display: flex;
    align-items: center;
    margin: auto;
    box-sizing: border-box;
    color: #000;
    border: solid 0px transparent;
    border-radius: 7px;
    z-index: 1;
    position: relative;
    background: linear-gradient(to right, red, orange);
    margin-bottom: 11px;

}

.feepaid-column.previous:after,
.feepaid-column.current:after,
.feepaid-column.future:after,
.visa-column.previous:after,
.visa-column.current:after,
.visa-column.future:after {
    display: flex;
    align-items: center;
    width: 100%;
    margin: auto;
    max-width: 22em;
    position: absolute;
    padding: 10px;
    box-sizing: border-box;
    color: #FFF;
    background: #8bc34a;
    background: linear-gradient(to right, red, orange);
    background-clip: padding-box;
    border: solid 3px transparent;
    border-radius: 1em;
    z-index: 1;
}

.feepaid-column.previous:before,
.feepaid-column.current:before,
.feepaid-column.future:before,
.visa-column.previous:before,
.visa-column.current:before,
.visa-column.future:before {
    content: "";
    position: absolute;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    z-index: 1;
    margin: -7px;
    border-radius: 3px;
    background: #fff;
}

.feepaid-column.current,
.visa-column.current {
    background: linear-gradient(to left, #6a5c9f, #00c0ff);
}

.feepaid-column.future,
.visa-column.future {
    background: linear-gradient(to right, #2196f3, #ff9000);
}

.feepaid-column .list-item,
.visa-column .list-item {
    position: relative;
    z-index: 1;
}

.feepaid-column .list-count,
.visa-column .visa-count {
    position: relative;
    z-index: 1;
}

.visa-column.previous .visa-count span {
    background: #8bc34a;
}

.visa-column.previous {
    background: linear-gradient(to right, #009688, #cddc39);
}

.visa-column.current .visa-count span {
    background: #3f51b5;
}

.feepaid-column.current,
.visa-column.future {
    background: linear-gradient(to left, #9c27b0, #e91e63);
}

.visa-column.future .visa-count span {
    background: #9c27b0;
    color: #fff;
}

.taget-and-count {
    display: flex;
}

.visa-column .target-count {
    position: relative;
    z-index: 1;
}

.visa-column .target-count span {
    display: inline-flex;
    min-width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 21px;
    font-size: 18px;
    padding: 9px;
    background: #009688;
    margin-left: 9px;
    color: #fff;
    position: relative;
}

.visa-column .target-count span:before {
    content: "Target";
    position: absolute;
    color: #000;
    font-size: 9px;
    top: -12px;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-weight: 500;
}

.visa-column.current .target-count span {
    background: #2196f3;
}

.visa-column.future .target-count span {
    background: #673ab7;
}

.table-wrpr.tab-bg {
    background: #fff;
}

.table-wrpr.tab-bg .nav-link {
    font-size: 13px;
    font-weight: 500;
    color: rgb(0 0 0 / 48%);
}

.table-wrpr.tab-bg .nav-link.active {
    color: rgb(0 0 0 / 90%);
}

.tab-wrpr .nav-item img {
    width: 11px;
    margin-right: 1px;
    vertical-align: middle;
}

.table-wrpr.tab-bg .nav-link[href='#warm'] img {
    width: 16px;
    position: relative;
    top: -2px;
}

.table-wrpr.tab-bg .nav-link[href='#cold'] img {
    width: 12px;
    position: relative;
    top: -2px;
}

.table-wrpr.tab-bg .nav-link[href='#longterm'] img {
    width: 15px;
}

.status-modal .modal-header {
    background: rgb(158 158 158 / 39%);
}

.status-modal .modal-header h4 {
    font-size: 20px;
}

.status-modal .modal-body label {
    font-size: 14px;
}

.status-modal .modal-body textarea {
    min-height: 140px;
    border-color: rgb(153 153 153 / 16%);
}

.status-modal .modal-body {
    background: #f5f5f5;
}

.status-modal .modal-body select,
.status-modal .modal-body input[type=checkbox],
.status-modal .modal-body input[type=date],
.status-modal .modal-body input[type=time] {
    border-color: rgb(153 153 153 / 16%);
}


.status-modal .modal-body button.btn.btn-success {
    min-height: 55px;
    background-color: var(--primary-color);
    border-color: #323a51;
}

.btn-add-new-client.btn-add-new-client2 {
    border: 1px solid rgb(50 58 81 / 16%) !important;
}

.btn-checkbox-wrpr {
    display: flex;
    justify-content: space-between;
}

.custom-control-label::before {
    width: 22px;
    height: 22px;
    top: 0px;
    border: none;
}

.custom-control-label::after {
    width: 22px;
    height: 22px;
    top: 0;
    /* background-color: #323a51; */
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 22%);
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-color: #ff9800;
    box-shadow: 0 0 0 1px rgb(189 113 0 / 68%);
}

.custom-control-input {
    left: 0;
}

.btn-add-new-client2.btn-followcomment i {
    background: none;
    color: #ffeb3b;
    font-size: 27px;
    padding-right: 5px !important;
}

.btn-add-new-client2.btn-followcomment {
    font-size: 14px;
    min-width: 180px;
    border-radius: 5px;
}

.gb-modal .modal-header {
    background: rgb(158 158 158 / 39%);
}

.gb-modal .modal-header h4 {
    font-size: 20px;
}

.setfollowup input {
    font-size: 12px;
}

.setfollowup label {
    font-size: 13px;
}

.setfollowup .dropdown,
.setfollowup button {
    font-size: 12px;
}

.setfollowup .search input {
    border: 1px solid #dadada;
    border-radius: 3px;
    padding: 6px;
    width: 100%;
    margin-bottom: 4px;
}

.assigned-list {
    max-height: 100px;
    overflow: hidden;
    overflow-y: auto;
}

/* width */
.assigned-list::-webkit-scrollbar,
.pop-table tbody::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.assigned-list::-webkit-scrollbar-track,
.pop-table tbody::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.assigned-list::-webkit-scrollbar-thumb,
.pop-table tbody::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.assigned-list::-webkit-scrollbar-thumb:hover,
.pop-table tbody::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.setfollowup textarea {
    font-size: 12px;
}



.pop-table tbody {
    display: block;
    height: 250px;
    overflow: hidden;
    overflow-y: auto;
}

.pop-table thead,
.pop-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    /* even columns width , fix width of table too*/
}

.pop-table thead {
    width: calc(100% - 10px)
        /* scrollbar is average 1em/16px width, remove it from thead width */
}

.pop-table table {
    width: 400px;
}

.btn-darkblue {
    background-color: var(--primary-color);
    border-color: #323a51;
    height: 41px;
    font-size: 14px !important;
}

.btn-darkblue i {
    position: relative;
    top: 1px;
}

.setfollowup select {
    font-size: 12px;
}

.gb-modal .modal-footer .btn.btn-success,
.gb-modal .modal-footer .btn.btn-danger {
    line-height: 30px;
    font-size: 14px;
}

#statusprospectus .pop-table tbody {
    height: 288px;
}


.long-days,
.verylong-days,
span.registered {
    display: inline-flex;
    background: #b35639;
    color: #fff;
    padding: 1px 4px;
    border-radius: 3px;
}

span.registered {
    background: #50d78a;
    color: #000;
}

.verylong-days {
    background: #ff5722;
}

a[href="#registered"] .type-icon.registered {
    width: 14px;
}

.advanced-search-wrpr a {
    font-size: 12px;
    color: #000;
    border: 1px solid #dfdae8;
    background: #fff;
    display: inline-flex;
    min-height: 36px;
    position: relative;
    top: -1px;
    border-radius: 31px;
    margin-right: 13px;
    padding: 0 11px;
    align-items: center;
    z-index: 100;
}

.advanced-search-wrpr i {
    margin-left: 7px;
    color: #ed7b83;
}

.all-clients-search-wrpr {
    position: absolute;
    top: 44px;
    z-index: 1;
    background: #fff;
    right: 123px;
    min-height: 29px;
    min-width: 147px;
    border-radius: 21px;
    padding: 8px;
    border: 1px solid #dfdae8;
    font-size: 12px;
    opacity: 0;
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    box-shadow: 0 0px 0px rgb(0 0 0 / 11%);
    z-index: -1;
}

.all-clients-search-wrpr.active {
    position: absolute;
    top: 37px;
    z-index: 11;
    background: #fff;
    right: 118px;
    left: 80px;
    min-height: 400px;
    min-width: 800px;
    border-radius: 21px;
    padding: 13px;
    border: 1px solid #dfdae8;
    opacity: 1;
    box-shadow: 0 20px 30px rgb(0 0 0 / 11%);
}

.filter-wrpr .form-groups {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 9px;
}

.filter-wrpr .form-groups label {
    margin-bottom: 3px;
}

.filter-wrpr .form-groups input {
    border: none;
    border-bottom: 1px solid #dfdae8;
    padding-left: 0px;
    border-radius: 3px;
    font-size: 12px;
}

.search-wrpr {
    min-width: 145px;
    margin-right: 0;
}

.advanced-search-wrpr.active a {
    margin-right: 0;
}

.advanced-search-wrpr.active {
    min-width: 91px;
}

.followup-hding-section h2 {
    position: relative;
}

.followup-hding-section h2 span.Followup-count {
    position: absolute;
    top: 1px;
    left: 100px;
    background: #ffc75e;
    display: inline-flex;
    padding: 4px;
    border-radius: 14px;
    min-width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
}

.followup-hding-section h2.registered-followup span {
    left: 537px;
}

/* Counsilor dashboard - Aug 9 2023 */




/*dashboard v2*/

.table-wrpr {
    background: linear-gradient(180deg, #d2e1f8, #fff);
    padding: 17px;
    border-radius: 17px;
    margin-bottom: 30px;
    box-shadow: 0 10px 50px rgb(0 0 0 / 8%);
}

.table-wrpr table {
    overflow: hidden;
    border-radius: 10px;
}

.type-icon {
    width: 14px;
    margin-right: 3px;
    vertical-align: middle;
}

.type-icon.WARM {
    width: 21px;
}

.type-icon.COLD {
    width: 15px;
}

.type-icon.REGISTERED {
    width: 17px;
}

.type-icon.IELTS {
    width: 23px;
}

.type-icon.TOT {
    width: 22px;
}

.type-wrpr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.type-wrpr span {
    margin-left: 8px;
}

.followup-hding-section h2 {
    font-size: 20px;
    margin-bottom: 0;
}

.followup-hding-section h2 span {
    display: block;
    font-size: 14px;
}

.followup-wrpr {
    display: flex;
    align-items: center;
}

.followup-wrpr .followup-icon {
    margin-right: 9px;
}

.btn-addfollowup {
    background: #6a5c9f;
    border-radius: 3px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
}

.btn-addfollowup i {
    font-size: 17px;
    margin-left: 9px;
}

.followup-pending .icon-and-title-count.red {
    background: #e43c38;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.orange {
    background: #ff9000;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.blue {
    background: #52a0ef;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.purple {
    background: #6a5c9f;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.lightblue {
    background: #00c0ff;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.yellow {
    background: #ffc75e;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.gold {
    background: gold;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.tomato {
    background: #ff6347;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.green {
    background: #1bc36a;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.black {
    background: black;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.silver {
    background: silver;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.blueviolet {
    background: blueviolet;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.pink {
    background: magenta;
    display: flex;
    flex-direction: column;
}

.followup-pending .icon-and-title-count.lightblue img {
    width: 23px;
}

span.registered {
    background: #50d78a;
    color: #000;
}

.followup-pending .icon-and-title-count img {
    width: 13px;
}

.followup-pending .icon-and-title-count.orange img {
    width: 20px;
}

.followup-pending .icon-and-title-count.blue img {
    width: 17px;
}

.followup-pending .icon-and-title-count.purple img {
    width: 18px;
}

.followup-pending .icon-and-title-count.yellow img {
    width: 23px;
}

.followup-pending .icon-and-title-count.green img {
    width: 23px;
}

.followup-pending .icon-and-title-count .icon-customimage {
    width: 44px;
    height: 44px;
}

.followup-pending .icon-and-title-count h2 {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 12px;
}

.followup-pending .icon-and-title-count .icon-and-title {
    display: flex;
    justify-content: space-between;
}

.followup-pending .icon-and-title-count {
    min-height: 125px;
    display: flex;
    justify-content: space-between;
}

tfoot {
    border-top: 1px solid #dadada;
}

tfoot td {
    padding-bottom: 0 !important;
}

.link-more {
    color: #000;
    display: inline-flex;
    align-items: center;
}

.link-more i {
    font-size: 19px;
}

.performance-report-section h2 {
    font-size: 20px;
    color: #323a51;
    margin-bottom: 30px;
}

.performance-report-section h2 span {
    display: block;
    font-size: 14px;
    margin-left: 25px;
}

.pr-boxes-wrpr {
    background: #fff;
    border-radius: 10px;
    min-height: 190px;
    padding: 25px;
}

.pr-boxes {
    background: url(../images/grid.jpg);
    min-height: 172px;
    border-right: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}

.pr-boxes-wrpr h2 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 17px;
}

.cylinder-graph-wrpr {
    min-height: 100px;
    padding-top: 97px;
    position: relative;
}

.color-cylinder-wrpr.orange .color-cylinder,
.color-cylinder-wrpr.green .color-cylinder {
    background: linear-gradient(180deg, #3ee98e, #1dc56c);
    height: 27px;
    border-radius: 20px;
    position: relative;
}

.color-cylinder-wrpr.orange .color-cylinder {
    background: linear-gradient(180deg, #ffca86, #ff940a);
}

.gray-cylinder {
    background: #e5e5e5;
    height: 27px;
    border-radius: 22px;
    position: absolute;
    left: 0;
    right: 0;

}

.right-cntnr .add-client {
    width: 36px;
    height: 36px;
    padding: 7px;
    position: relative;
    left: 1px;
    top: -1px;
    background: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #dfdae8;
    overflow: hidden;
    margin-right: 13px;
}

.color-cylinder-wrpr.orange .color-cylinder:before,
.color-cylinder-wrpr.green .color-cylinder:before {
    position: absolute;
    content: "";
    width: 17px;
    height: 27px;
    background: #1dc56c;
    border-radius: 50%;
    right: 0;

}

.color-cylinder-wrpr.orange .color-cylinder:before {
    background: #ff9711;
}

.color-cylinder-wrpr {
    position: relative;
}

.color-cylinder-wrpr.orange:before,
.color-cylinder-wrpr.green:before {
    content: "Achieved Target";
    position: absolute;
    right: 0;
    top: -66px;
    font-size: 12px;
    max-width: 80px;
    text-align: right;
    line-height: 13px;
}

.color-cylinder-wrpr.orange:after,
.color-cylinder-wrpr.green:after {
    content: attr(data-archTrgt);
    position: absolute;
    right: 0;
    top: -36px;
    font-size: 12px;
    height: 34px;
    text-align: right;
    line-height: 13px;
    background: url(../images/green-bubble.png) no-repeat top center;
    display: inline-flex;
    padding: 6px;
    border-radius: 5px;
    color: #fff;
}

.color-cylinder-wrpr.orange:after {
    content: attr(data-visaTrgt);
    background: url(../images/orange-bubble.png) no-repeat top center;
}

.reg-target {
    width: 156px;
    height: 28px;
    position: absolute;
    left: 0px;
    top: 3px;
    font-size: 12px;
    max-width: 200px;
    text-align: left;
    line-height: 21px;
}

.reg-target:after {
    content: attr(data-regTrgt);
    position: absolute;
    right: 0;
    top: -3px;
    font-size: 12px;
    height: 25px;
    text-align: right;
    line-height: 13px;
    background: #6a5c9f;
    display: inline-flex;
    padding: 6px;
    border-radius: 7px;
    color: #fff;
}

.gray-cylinder:after {
    position: absolute;
    content: "";
    width: 17px;
    height: 27px;
    background: #d8d6d6;
    border-radius: 50%;
    right: 0;
}

.conv-ratio,
.pending-target {
    font-size: 12px;
}

.conv-ratio span.conv-ratio-count,
.pending-target span.pending-target-count {
    font-size: 18px;
    color: #ff9000;
}

.pending-target {
    position: absolute;
    right: 3px;
    top: 127px;
}

.conv-ratio {
    margin-top: 4px;
    padding-left: 4px;
}

.echart-wrpr {
    background: #fff;
    border-radius: 10px;
    min-height: 190px;
    padding: 25px;
}

#chart-container {
    position: relative;
    height: 300px;
    overflow: hidden;
}

#chart-container2,
#chart-container3 {
    position: relative;
    height: 300px;
    overflow: hidden;
}

.echart-hding h2 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
}

.echart-count {
    font-size: 28px;
    color: #6a5c9f;
}

.echart-hding .col-md-10 {
    display: flex;
    align-items: center;
}

/*dashboard v2 end */



/*Cylindar graph change - start*/

.myallocatedClients .color-cylinder-wrpr.orange {
    bottom: 27px;
    opacity: 1;
}

.myallocatedClients .color-cylinder-wrpr.orange:before,
.myallocatedClients .color-cylinder-wrpr.green:before {
    display: none;
}

.myallocatedClients .color-cylinder-wrpr.orange:after,
.myallocatedClients .color-cylinder-wrpr.green:after {
    display: none;
}

.color-cylinder-wrpr.blue .color-cylinder {
    background: linear-gradient(180deg, #3fb5a3, #03a9f4);
}

.color-cylinder-wrpr.blue .color-cylinder:before {
    position: absolute;
    content: "";
    width: 17px;
    height: 27px;
    background: #1dc56c;
    border-radius: 50%;
    right: 0;
}

.color-cylinder-wrpr.blue .color-cylinder:before {
    background: #0898ec;
}

.myallocatedClients .color-cylinder-wrpr.blue {
    bottom: 54px;
}

.color-cylinder-wrpr.blue .color-cylinder {
    background: linear-gradient(180deg, #3ee9dc, #1d89c5);
    height: 27px;
    border-radius: 20px;
    position: relative;
}

.color-cylinder-wrpr.blue .color-cylinder:before {
    opacity: .4;
}

.color-cylinder-wrpr.green .color-cylinder:before {
    opacity: .4;
}

.color-cylinder-wrpr.orange .color-cylinder:before {
    opacity: .4;
}

.chart_legents {
    font-size: 12px;
    position: relative;
    bottom: 24px;
}

.chart_legents .green,
.chart_legents .orange,
.chart_legents .blue {
    position: relative;
}

.chart_legents .green:before,
.chart_legents .orange:before,
.chart_legents .blue:before {
    content: "";
    background: #2bd47a;
    width: 11px;
    height: 11px;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    position: relative;
    top: 1px;
}

.chart_legents .orange:before {
    background: #ff9f24;
}

.chart_legents .blue:before {
    background: #27a7cc;
}

.chart_legents .green:after,
.chart_legents .orange:after,
.chart_legents .blue:after {
    content: attr(data-allocTarget);
    position: absolute;
    font-size: 16px;
    left: 15px;
    bottom: -25px;
}

.chart_legents .orange {
    left: 6px;
}

.chart_legents .blue {
    left: 11px;
}

.color-cylinder-wrpr {
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
    width: 4%;
}

/*Cylindar graph change - end*/



/* followup coment */
.modal-fullscreen-xl .modal-dialog {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    margin-top: 0px;
}

.modal-fullscreen-xl .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal.modal-fullscreen-xl {
    padding-right: 0px !important;
}

.modal-fullscreen-xl .modal-body {
    overflow-y: auto;
}

.modal-fullscreen-xl .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
}

.card.followup-card {
    border-color: #0085f2;
    /* min-height: 270px; */
    box-shadow: 0 17px 20px #0000001a;
}

.card.followup-card+.card.followup-card {
    margin-top: 30px;
}

.followup-labels label {
    font-size: 12px;
    display: block;
    margin-bottom: 0px;
    font-weight: 500;
    line-height: 11px;
}

.followup-labels label+span {
    font-size: 11px;
}

.comment-box-wrpr {
    position: relative;
    margin-top: 20px;
    height: 18px;
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

.comment-box-wrpr textarea {
    min-height: 83px;
    background: #dedede45;
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

.comment-box-wrpr button {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-left: 0px;
    padding-right: 13px;
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

.comment-box-wrpr textarea {
    min-height: 0;
    height: 0px;
    opacity: 0;
}

.comment-box-wrpr button,
.comment-box-wrpr button.save-btn {
    background: #da4453;
    border-color: #da4453;
    font-size: 11px;
    letter-spacing: .4px;
    line-height: 30px;
    min-width: 132px;
    top: -22px !important;
    transition: all .2s linear;
    -o-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
}

.comments-list-wrpr {
    border-radius: 8px;
    overflow: hidden;
}

.comments-list-wrpr table {
    margin-bottom: 0px;
    box-shadow: 0 0 20px black;
}

.comment-box-wrpr .btn-search i {
    font-size: 17px;
    position: relative;
    top: -1px;
    left: -4px;
}

.add-followdup-comment,
.comment-box-wrpr button.save-btn {
    font-size: 11px;
    height: 30px;
    display: flex;
    align-items: center;
}

.comment-box-wrpr button.save-btn {
    min-width: 71px;
    opacity: 0;
    z-index: -1;
}

.comment-box-wrpr.active textarea {
    opacity: 1;
    min-height: 62px;
    height: 62px;
    margin-bottom: 30px;
}

.comment-box-wrpr.active button.save-btn {
    opacity: 1;
    z-index: 10;
}

.comment-box-wrpr.active {
    height: 45px;
}

.comment-box-wrpr.active button.save-btn {
    top: 31px !important;
}

.add-general-comment-wrpr.card {
    border-color: #3983f0;
    box-shadow: 0 10px 30px rgb(0 0 0 / 22%);
}

.add-followup {
    font-size: 11px;
    letter-spacing: .4px;
    line-height: 21px;
}

.general-comment-label-and-button label,
.add-general-comment-wrpr.card label {
    font-size: 12px;
}

.add-general-comment-wrpr.card select,
.add-general-comment-wrpr.card input[type=date],
.add-general-comment-wrpr.card input[type=time],
.add-general-comment-wrpr.card textarea {
    font-size: 12px;
    margin-bottom: 10px;
}

.add-general-comment-wrpr.card textarea {
    min-height: 100px;
}

.btn-add-followup {
    background: #d24b55;
    border-color: #d24b55;
    font-size: 13px;
    margin-top: 20px;
}

.general-comment-label-and-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    flex-direction: row-reverse;
}

.btn-add-followup:hover {
    background: #a5383e;
    border-color: #a5383e;
}

.general-comment {
    background: rgb(220 53 69 / 10%);
}

/* followup coment */

/*custom  table*/

.table-head-wrpr {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border-left: 0 none !important;
    border-top: 0 none !important;
    border-right: 0 none !important;
}
.table-head-wrpr table{
	table-layout: fixed;
    margin: 0;
    outline-style: none;
    border-collapse: separate;
    border-spacing: 1px;
	
}
.table-head-wrpr .table{border:none;}
.table-head-wrpr .table.collageprogram th  {
    border-bottom: none;
}

.table-body-wrpr {
	position: relative;
    margin: 0;
    padding: 0;
    overflow: auto;
    text-align: left;
    z-index: 101;
}
.table-body-wrpr table {
	table-layout: fixed;
    margin: 0;
    outline-style: none;
    border-collapse: separate;
    border-spacing: 1px;
}
.table-body-wrpr table  td {
    border-bottom: none;
}
.table-head-wrpr .table{border:none;}
/*custom  table end*/




.prg-coordinator-dash.tgraph {
    display: flex;
    align-items: center;
}

.prg-coordinator-dash.tgraph .row {
    width: 100%;
}


@media (max-width:900px) {
    .search-wrpr {
        display: none;
    }

    .table-section {
        overflow: hidden;
        overflow-x: auto;
    }


    .tab-content.prospect-tabcontent {
        overflow: hidden;
        overflow-x: auto;
    }

    .ilets-tot-follow-longtem-section .collapse {
        overflow: hidden;
        overflow-x: auto;
    }
}


@media (max-width:768px) {
    .leaderboard-menu {
        width: 340px;
    }

    .search-active .search-wrpr {
        position: fixed;
        display: block;
        left: 22px;
        right: 10px;
        min-width: unset;
        z-index: 10;
        top: 105px;
    }

    a.navbar-toggler.navbar-toggler-icon.phoneonly {
        display: block;
    }

    .wrapper.sidebar-active aside.right-side {
        z-index: -1;
        position: relative;
    }

    a.navbar-toggler.navbar-toggler-icon.phoneonly {
        position: relative;
        top: 21px;
        left: -4px;
        padding: 0;
    }

    a.navbar-toggler.navbar-toggler-icon.phoneonly i {
        font-size: 24px;
    }

    .search-wrpr-mobile {
        width: 36px;
        height: 36px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #dfdae8;
        border-radius: 50%;
        margin-right: 12px;
    }

    .search-wrpr-mobile a {
        color: #6a5c9f;
    }

    .ilets-tot-follow-longtem-section .collapse {
        overflow: hidden;
        overflow-x: auto;
    }

    .appointments-hing-wrpr {
        margin-bottom: 15px;
        border-bottom: 1px solid #dedde6;
    }

    .appointments-section .col-md-3 {
        border-bottom: 1px solid #d9d9e3;
        border-left: none;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .appointments-section {
        border-bottom: none;
    }

    .year-and-yeartodate-section .col-md-6:last-child {
        margin-top: 50px;
    }

    .prospect-tablist {
        margin-top: 20px;
    }

    .sidebar-collapsed .left-side {
        width: 0;
        overflow: hidden;
    }

    .right-side {
        margin-left: 0px !important;
    }


}